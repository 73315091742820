<template>
  <b-container>
    <!-- <AllFeature class="mt-3"></AllFeature> -->
    <Feature class="my-4" />
    <div class="text-center mt-5 mb-3">
      <h1 class="font-bold pb-2">
        {{ $t('future_feature').header }}
      </h1>
    </div>
    <b-row class="w-10/12 mx-auto mb-5 gap-y-3">
      <b-col cols="12" sm="6" class="flex"
        ><Card filename="offline.svg" :label="$t('future_feature').offline"
      /></b-col>
      <b-col cols="12" sm="6" class="flex"
        ><Card filename="dashboard.svg" :label="$t('future_feature').dashboard"
      /></b-col>
      <b-col cols="12" sm="6" class="flex"
        ><Card filename="repair.svg" :label="$t('future_feature').repair"
      /></b-col>
      <b-col cols="12" sm="6" class="flex"
        ><Card filename="audit.svg" :label="$t('future_feature').audit"
      /></b-col>
    </b-row>

    <Table />
    <b-row class="mb-2">
      <b-col cols="12" class="d-flex justify-center" v-if="$i18n.locale === 'en'">
        <img
          class="free-trial-but cursor-pointer my-5"
          src="@/assets/features/en/9.png"
          alt="feature"
          @click="clickTrial"
        />
      </b-col>
      <b-col cols="12" class="d-flex justify-center my-5" v-else>
        <img
          @click="clickTrial"
          class="free-trial-but cursor-pointer"
          src="@/assets/features/th/9.png"
          alt="feature"
        />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import Feature from '../general/features.vue'
import Card from './feature_card.vue'
import Table from './price_table.vue'
export default {
  methods: {
    clickTrial() {
      this.$router.push('/packages')
      this.selectPackage({
        package_name: 'trial',
        package_assets: 0,
        lifetime_years: 1,
        package_addon: false,
      })
    },
  },
  components: {
    Feature,
    Table,
    Card,
  },
}
</script>

<style scoped>
h1 {
  color: #0158b6;
  font-size: 36px;
  line-height: inherit;
}
</style>
