<template>
  <div>
    <b-container fluid>
      <b-row>
        <b-col cols="12">
          <b-card class="paymentDetail px-3 py-3">
            <h1 class="pb-3">
              {{ $t('package_detail.package_detail') }} ({{ this.package.package_name }})
            </h1>
            <b-row class="mt-3" v-if="this.package.package_id != 1">
              <b-col cols="7" class="text-left px-0">
                <h2>{{ $t('package_detail.package_size') }}</h2>
              </b-col>

              <b-col cols="5" class="text-end px-0">
                <p>{{ assetsText }}</p>
              </b-col>
            </b-row>
            <b-row class="mt-3">
              <b-col cols="7" class="text-left px-0">
                <h2>{{ $t('package_detail.payment_plan') }}</h2>
              </b-col>

              <b-col cols="5" class="text-end px-0">
                <p>{{ paymentPlan }}</p>
              </b-col>
            </b-row>
            <b-row class="mt-3" v-if="this.package.package_id != 1">
              <b-col cols="7" class="text-left px-0">
                <h2>{{ $t('package_detail.monthly_price') }}</h2>
              </b-col>

              <b-col cols="5" class="text-end px-0">
                <p>{{ perMonth }}</p>
              </b-col>
            </b-row>
            <div
              v-if="
                this.package.package_id != 1 &&
                (getPackageDetail.addOn.preparation ||
                  getPackageDetail.addOn.installation ||
                  getPackageDetail.addOn.data_transfer ||
                  getPackageDetail.addOn.additional)
              "
            >
              <b-row class="mt-3">
                <hr class="w-full" />
              </b-row>
              <b-row class="mt-3" v-if="getPackageDetail.addOn">
                <b-col cols="12" class="text-left px-0">
                  <h2>{{ $t('packages.extra_service') }}</h2>
                  <ul class="list-disc optionText pl-8 pr-2">
                    <li v-if="getPackageDetail.addOn.preparation">
                      {{ $t('packages').preparation }}
                    </li>
                    <li v-if="getPackageDetail.addOn.installation">
                      {{ $t('packages').installation }}
                    </li>
                    <li v-if="getPackageDetail.addOn.data_transfer">
                      {{ $t('packages').data_transfer }}
                    </li>
                    <li v-if="getPackageDetail.addOn.additional">
                      {{ $t('packages').additional }}
                    </li>
                  </ul>
                </b-col>
              </b-row>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      // wht: Boolean,
    }
  },
  props: {
    package: Object,
  },
  methods: {
    changeValueWHT() {
      this.$store.commit('ISWTH', !this.getWHT)
    },
  },

  computed: {
    ...mapGetters(['getWHT', 'getPackageDetail']),
    packageText() {
      let packageText = null
      if (this.$i18n.locale === 'en') {
        packageText = `${this.package.package_assets.toLocaleString()} Assets (${
          this.package.package_lifetime / 365
        } Year)`
      } else {
        packageText = `${this.package.package_assets} ชิ้น (${
          this.package.package_lifetime / 365
        } ปี)`
      }

      return packageText
    },
    packagePrice() {
      let price = this.package.package_price
      return price
        .toFixed(2)
        .toLocaleString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    whtPrice() {
      if (this.getWHT) {
        return ((this.package.package_price / 1.07) * 0.03)
          .toFixed(2)
          .toLocaleString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }
      return '0'
    },
    totalPrice() {
      let total = this.package.package_price
      if (this.getWHT) {
        total = total * ((total / 1.07) * 0.03)
      }
      if (this.package.package_discount > 0) {
        total = total - this.package.package_discount
      }
      return total
        .toFixed(2)
        .toLocaleString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    assetsText() {
      if (this.$i18n.locale === 'en') {
        return `${this.package.package_assets.toLocaleString()} assets`
      }
      return `${this.package.package_assets.toLocaleString()} ชิ้น`
    },
    paymentPlan() {
      if (this.package.package_name.toLowerCase() == 'free trial') {
        if (this.$i18n.locale === 'en') {
          return '30 days'
        } else {
          return '30 วัน'
        }
      } else {
        if (this.$i18n.locale === 'en') {
          return `${this.package.package_lifetime / 365} years`
        } else {
          return `${this.package.package_lifetime / 365} ปี`
        }
      }
    },
    perMonth() {
      switch (this.package.package_name.toLowerCase()) {
        case 'trail':
          return 'FREE'
        default:
          return this.getPackageDetail.perMonth
      }
    },
  },
}
</script>

<style scoped>
/* .paymentDetail {
  background: #ffffff;
  border-radius: 10px;
}*/
.paymentDetail h1 {
  font-weight: 600;
  font-size: 18px;
  color: #014083;
}
.text-blue {
  color: #014083;
  font-weight: 600;
  font-size: 14px;
}
h2 {
  font-weight: 600;
  font-size: 14px !important;
  color: #014083;
}
.packageText {
  font-weight: 500;
  font-size: 14px;
  color: #014083;
}
p {
  font-size: 14px;
}
hr {
  /* border: 5px solid #014083; */
  background-color: #014083;
  height: 1px;
  border: none;
  color: #014083;
}
.optionText {
  color: #888888;
  font-size: 14px;
}
</style>
