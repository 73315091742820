<template>
  <div>
    <div id="fb-root"></div>

    <div id="fb-customer-chat" class="fb-customerchat"></div>
  </div>
</template>

<script>
window.fbAsyncInit = function () {
  window.FB.init({
    xfbml: true,
    version: 'v12.0',
  })
}

;(function () {
  const initFacebookSDK = function (d, s, id) {
    var js,
      fjs = d.getElementsByTagName(s)[0]
    if (d.getElementById(id)) return
    js = d.createElement(s)
    js.id = id
    js.src = 'https://connect.facebook.net/th_TH/sdk/xfbml.customerchat.js'
    fjs.parentNode.insertBefore(js, fjs)
  }

  if (window.__PRERENDER_INJECTED && window.__PRERENDER_INJECTED.isPrerendering) return

  initFacebookSDK(document, 'script', 'facebook-jssdk')
})()
export default {
  mounted() {
    const chatbox = document.getElementById('fb-customer-chat')
    chatbox.setAttribute('page_id', '444183729346153')
    chatbox.setAttribute('attribution', 'biz_inbox')
  },
}
</script>

<style>
.fb_dialog_content {
  display: none;
}
</style>
