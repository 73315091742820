<template>
  <b-container fluid class="px-0">
    <div class="flex py-2 items-start">
      <img
        :src="require(`@/assets/material/${filename}`)"
        class="object-scale-down px-2 mr-3"
        :class="this.tick ? 'w-12' : 'w-10'"
        alt="material"
      />
      <div class="text-start">
        <p>
          <!-- {{ text }} -->
          <span v-html="text" />
        </p>
      </div>
    </div>
  </b-container>
</template>

<script>
export default {
  props: {
    filename: String,
    tick: Boolean,
    text: String,
  },
};
</script>

<style  scoped>
/* img {
  height:min-content !important;
} */
.icon-size {
  padding: 0rem 3rem;
}
@media only screen and (min-width: 1440px) {
  p {
    font-size: 16px !important;
  }
  .icon-size {
    width: 63px;
    padding: 0rem 1rem;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  p {
    font-size: 16px !important;
  }
  .icon-size {
    width: 10px !important;
    height: auto;
    padding: 0rem 1rem;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  p {
    font-size: 14px !important;
    margin-left: 7px;
  }
  .icon-size {
    width: 73px;
    height: auto;
    padding: 0rem 1.3rem;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  p {
    font-size: 14px !important;
  }
  .icon-size {
    padding: 0rem 0.75rem;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  p {
    font-size: 12px !important;
  }
  .icon-size {
    padding: 0rem 1rem;
  }
}
@media only screen and (min-width: 425px) and (max-width: 575px) {
  p {
    font-size: 12px !important;
  }
  .icon-size {
    padding: 0rem 0.75rem;
  }
}
@media only screen and (min-width: 320px) and (max-width: 424px) {
  p {
    font-size: 12px !important;
  }
  .icon-size {
    padding: 0rem 0.5rem;
  }
}
</style>