<template>
  <div>
    <div class="packages">
      <div
        class="grid justify-items-center py-1 md:py-4 gap-y-4 container"
        ref="sliderDiv"
        id="sliderDiv"
      >
        <nav class="bg-grey-light rounded mt-2 mb-3 w-full">
          <ol class="list-reset flex text-grey-dark">
            <li>
              <a href="/home">Home</a>
            </li>
            <li><span class="mx-1">/</span></li>
            <li><a href="#" class="font-bold">Packages</a></li>
          </ol>
        </nav>
        <span class="flex md:gap-x-2 items-center">
          <img width="40" :src="require(`@/assets/material/pc.svg`)" alt="pc"/>
          <h1 :class="{ 'ml-1': $i18n.locale === 'en' }">
            {{ $t('packages').choose_amount }}
          </h1>
        </span>
        <h2 id="current-branch" v-if="branch" class="font-semibold">
          {{ branch.company_name }} ({{ branch.branch_name }})
        </h2>

        <div class="grid w-5/6 md:w-2/3 my-5">
          <vue-slider
            absorb
            :data="packages"
            v-model="prices"
            @change="onChange"
            class="pointer mb-2"
            tooltip="always"
            ref="slider"
            id="slider"
            hide-label
          />
          <div class="w-full flex justify-between">
            <p class="grey">{{ this.$route.name == 'Upgrade' ? '200' : 'Free' }}</p>
            <p class="grey">5,000+</p>
          </div>
        </div>
        <div class="w-full md:w-1/2 flex gap-x-1 items-center justify-center">
          <p class="w-1/3 md:w-1/2 mr-2 text-right">
            {{ $t('packages').package }}
          </p>
          <div class="w-2/3 md:w-1/2 flex">
            <div id="price-box" class="px-4 py-2 text-left" v-if="package_assets == '0'">
              {{ package_name }} ({{ $t('packages').free_day }})
            </div>
            <div id="price-box" class="px-4 py-2 text-left" v-else>
              {{ package_name }} ({{
                package_assets == '5500'
                  ? '5,000+'
                  : package_assets.toLocaleString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }}
              {{ $t('packages').assets }})
            </div>
          </div>
        </div>

        <div
          class="w-full md:w-1/2 flex gap-x-1 items-center justify-center"
          v-if="package_name != 'Free trial'"
        >
          <p class="w-1/3 md:w-1/2 mr-2 text-right">
            {{ $t('packages').choose_duration }}
          </p>
          <div class="flex gap-x-1 items-center w-2/3 md:w-1/2 h-full">
            <div class="h-full w-1/2">
              <button
                class="py-2 px-2 w-full h-full"
                :class="oneYear || (prices == 0 ? true : false) ? 'active' : 'grey-border'"
                @click="oneYear = true"
              >
                <nobr>
                  {{ $t('packages').one_year }}
                </nobr>
              </button>
            </div>

            <button
              class="py-2 h-full px-2 w-2/3"
              :class="oneYear && (prices != 0 ? true : false) ? 'grey-border' : 'active'"
              @click="oneYear = false"
            >
              <span class="h-full">
                {{ $t('packages').two_years }}
              </span>
            </button>
          </div>
        </div>
        <div
          class="flex gap-x-1 items-start justify-center w-full md:w-1/2"
          v-if="package_name != 'Free trial'"
        >
          <div class="mr-2 text-right w-1/3 md:w-1/2 grid">
            <p>
              {{ $t('packages').calculate_price }}
            </p>
            <p v-if="oneYear || prices == 0">
              {{ $t('packages').first }}
            </p>
            <p v-else>
              {{ $t('packages').second }}
            </p>
          </div>

          <div
            id="price-box"
            class="px-4 py-2 w-2/3 md:w-1/2"
            v-if="prices == 0 || prices == 'Custom'"
          >
            {{ prices }}
          </div>
          <div class="w-2/3 md:w-1/2" v-else>
            <div id="price-box" class="px-4 py-2">
              {{ pricePerMonth + ' ' + $t('packages').per_month }}
              <p style="font-size: 12px" class="grey" v-if="oneYear">
                {{
                  parseFloat(prices)
                    .toFixed(0)
                    .toLocaleString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }}
                {{ perYear }}
              </p>
              <p style="font-size: 12px" class="grey" v-else>
                {{
                  (Math.floor((parseFloat(prices) + 7980) * 0.0095) * 100)
                    .toFixed(0)
                    .toLocaleString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }}
                {{ perYear }}
              </p>
            </div>
            <p class="grey text-left my-2">
              {{
                oneYear
                  ? $t('packages').next_year
                  : $t('packages').next_year.replace('1 year', '2 years')
              }}
            </p>
            <p class="grey text-left">{{ $t('packages').average_pay }}</p>
          </div>
        </div>

        <div
          class="flex gap-x-1 w-full md:w-2/3 justify-center"
          v-if="package_name != 'Free trial'"
        >
          <div class="mr-2 w-1/3 md:w-1/2 text-right">
            <p>
              {{ $t('packages').extra_service }}
            </p>
            <p>
              {{ $t('packages').optional }}
            </p>
          </div>

          <div class="flex w-2/3 md:w-1/2">
            <b-form-checkbox v-model="preparation" class="mr-1 mt-1"> </b-form-checkbox>

            <p class="text-left pointer" @click="preparation = !preparation">
              {{ $t('packages').preparation }}
            </p>
          </div>
        </div>
        <div
          class="flex gap-x-1 w-full md:w-2/3 justify-center"
          v-if="package_name != 'Free trial'"
        >
          <p class="mr-2 w-1/3 md:w-1/2"></p>
          <div class="flex w-2/3 md:w-1/2">
            <b-form-checkbox v-model="installation" class="mr-1 mt-1"> </b-form-checkbox>
            <p class="text-left pointer" @click="installation = !installation">
              {{ $t('packages').installation }}
            </p>
          </div>
        </div>
        <div
          class="flex gap-x-1 w-full md:w-2/3 justify-center"
          v-if="package_name != 'Free trial'"
        >
          <p class="mr-2 w-1/3 md:w-1/2"></p>
          <div class="flex w-2/3 md:w-1/2">
            <b-form-checkbox v-model="data_transfer" class="mr-1 mt-1"> </b-form-checkbox>

            <p class="text-left pointer" @click="data_transfer = !data_transfer">
              {{ $t('packages').data_transfer }}
            </p>
          </div>
        </div>
        <div
          class="flex gap-x-1 w-full md:w-2/3 justify-center"
          v-if="package_name != 'Free trial'"
        >
          <p class="mr-2 w-1/3 md:w-1/2"></p>
          <div class="flex w-2/3 md:w-1/2">
            <b-form-checkbox v-model="additional" class="mr-1 mt-1"> </b-form-checkbox>
            <p class="text-left pointer" @click="additional = !additional">
              {{ $t('packages').additional }}
            </p>
          </div>
        </div>

        <div class="text-center w-full">
          <div class="grid justify-center py-2 my-3">
            <p class="text-lg font-bold">{{ $t('packages').get }}</p>
            <Seperator blue />
          </div>

          <div class="grid justify-center gap-y-3 md:flex h-23">
            <div
              class="grey-box p-3 flex items-center w-full md:w-1/2 max-w-xs mr-3"
              v-if="package_assets != '0'"
            >
              <img class="w-10" src="@/assets/material/get_qr.svg" alt="pc"/>
              <div class="grid ml-3 text-start">
                <h2 class="font-semibold" v-if="$i18n.locale === 'en'">
                  <span style="color: #007afe">
                    {{
                      package_assets == '5500'
                        ? '5,000+'
                        : package_assets.toLocaleString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    }}
                    {{ $t('packages').get_assets }}
                  </span>
                  {{ $t('packages').get_amount }}
                </h2>

                <h2 class="font-semibold" v-else>
                  {{ $t('packages').get_amount }}
                  <span style="color: #007afe">
                    {{
                      package_assets == '5500'
                        ? '5,000+'
                        : package_assets.toLocaleString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    }}
                    {{ $t('packages').get_assets }}
                  </span>
                </h2>

                <p>
                  <nobr>{{ $t('packages').get_collab }}</nobr>
                </p>
              </div>
            </div>

            <div class="grey-box p-3 flex w-full md:w-1/2 max-w-xs h-23">
              <img class="w-10" src="@/assets/material/get-duration.svg" alt="pc"/>
              <div class="grid ml-3 text-start">
                <h2 class="font-semibold" v-if="$i18n.locale === 'en'">
                  <span style="color: #007afe" v-if="package_name != 'Free trial'">
                    {{ oneYear ? $t('packages').one_year : '2 Years' }}
                  </span>
                  <span style="color: #007afe" v-else>
                    {{ $t('packages').free_day }}
                  </span>
                  {{ $t('packages').get_duration }}
                </h2>
                <h2 class="font-semibold" v-else>
                  {{ $t('packages').get_duration }}
                  <span style="color: #007afe" v-if="package_name != 'Free trial'">
                    {{ oneYear ? $t('packages').one_year : '2 ปี' }}
                  </span>
                  <span style="color: #007afe" v-else>
                    {{ $t('packages').free_day }}
                  </span>
                </h2>
                <p>{{ $t('packages').get_feature }}</p>
              </div>
            </div>
          </div>
        </div>

        <div class="flex flex-wrap w-full justify-center">
          <button
            class="pointer mt-3 px-4 py-2 w-48 mx-1"
            id="quotation-btn"
            @click="showQuotationModal"
            v-if="package_name != 'Free trial' && package_name !== 'Custom'"
          >
            {{ $t('packages').get_quotation }}
          </button>
          <button
            class="text-white pointer mt-3 px-4 py-2 w-48 mx-1"
            id="checkout"
            @click="checkout"
            v-if="package_name != 'Free trial'"
          >
            {{ prices == 'Custom' ? $t('packages').contact_now : $t('packages').checkout }}
          </button>
        </div>
        <img
          v-if="$i18n.locale === 'en' && package_name == 'Free trial'"
          class="free-trial-but cursor-pointer"
          src="@/assets/features/en/9.png"
          @click="trial"
          alt="free-trial"
        />
        <img
          v-if="$i18n.locale === 'th' && package_name == 'Free trial'"
          class="free-trial-but cursor-pointer"
          src="@/assets/features/th/9.png"
          @click="trial"
          alt="free-trial"
        />
        <span class="flex md:gap-x-2 items-center mt-5">
          <img width="35" :src="require(`@/assets/material/box.svg`)" alt="box"/>
          <h1>{{ $t('feature').features }}</h1>
        </span>
        <Features class="md:px-4 px-2 py-4" packages />
      </div>

      <h1 class="font-bold" v-if="!packages">
        {{ $t('feature').features }}
      </h1>

      <Footer />

      <!-- Start of Contact Modal -->

      <b-modal
        class="font"
        v-model="contact"
        hide-footer
        hide-header
        centered
        :no-close-on-backdrop="loading"
      >
        <div class="w-full grid justify-items-center" v-if="!loading && !success">
          <img
            src="@/assets/material/cross.svg"
            class="justify-self-end pointer"
            alt="cross"
            @click="contact = !contact"
          />
          <h1 class="modal_header">
            {{ $t('packages').contact_us }}
          </h1>
          <img class="my-3" src="@/assets/material/contact_us.png" alt="contact"/>
          <h1 class="modal_header mt-4" v-if="!this.user">
            {{ $t('packages').contact_info }}
          </h1>
          <form
            class="flex w-2/3 my-4 gap-y-2 grid justify-items-center"
            v-on:submit.prevent="contactUs"
          >
            <Input required :label="$t('packages').name" class="w-full" v-model="name" />
            <Input
              required
              :label="$t('register').email"
              class="w-full"
              type="email"
              v-model="email"
            />
            <Input required :label="$t('register').tel" class="w-full" type="tel" v-model="tel" />
            <Input
              required
              :label="$t('register').company_name"
              class="w-full"
              v-model="companyName"
            />

            <h1 class="modal_header mt-4 mb-2 text-center">
              {{ $t('packages').package_info }}
            </h1>
            <div class="w-full">
              <label class="text-gray-50 font-bold mb-1 pr-4">
                {{ $t('packages').package }}
              </label>
              <p style="color: #888888">{{ package_name }}</p>
            </div>
            <div class="w-full">
              <label class="flex text-gray-50 font-bold mb-1 pr-4">
                {{ $t('packages').assets_amount }}
              </label>
              <p style="color: #888888">{{ package_assets.toLocaleString() }}</p>
            </div>
            <div
              class="grid w-full mb-1"
              v-if="
                (installation || preparation || data_transfer || additional) &&
                package_name != 'Free trial'
              "
            >
              <label class="text-left font-bold mb-1 pr-4 content-start">
                {{ $t('packages').extra_service }}
              </label>
              <ul class="list-disc pr-4 ml-4 md:mx-4">
                <li v-if="preparation">{{ $t('packages').preparation }}</li>
                <li v-if="installation">{{ $t('packages').installation }}</li>
                <li v-if="data_transfer">{{ $t('packages').data_transfer }}</li>
                <li v-if="additional">{{ $t('packages').additional }}</li>
              </ul>
            </div>

            <div class="w-full">
              <label class="flex text-gray-50 font-bold mb-1 pr-4">
                {{ $t('packages').message }}
              </label>
              <textarea
                class="py-1 flex w-full px-2 text-dark"
                rows="4"
                cols="50"
                v-model="message"
              />
            </div>
            <Button
              :text="$t('packages').send"
              btnColor="gradient-btn"
              class="w-1/2 mt-4 self-center"
              type="submit"
            />
          </form>
        </div>
        <div v-else-if="!loading && success" class="w-full grid justify-items-center">
          <img
            src="@/assets/material/cross.svg"
            class="justify-self-end pointer"
            alt="cross"
            @click="contact = !contact"
          />
          <h1 class="modal_header">
            {{ $t('packages').already_sent }}
          </h1>
          <img class="my-3" src="@/assets/material/contact_us.png" alt="contact"/>
          <h1 class="modal_header">
            {{ $t('packages').please_check }}
          </h1>
          <Button
            @click.native="contact = !contact"
            :text="this.user ? this.$router.push('/companyselect') : 'Close'"
            btnColor="gradient-btn"
            class="my-2"
          />
        </div>
        <div v-else class="grid p-3 justify-center">
          <Loading />
        </div>
      </b-modal>

      <!-- End of Contact Modal -->

      <!-- Start of Quotation Modal -->

      <b-modal
        v-model="quotationModal"
        hide-footer
        hide-header
        centered
        :no-close-on-backdrop="loading"
        id="contact-us"
      >
        <div class="w-full grid justify-items-center" v-if="!loading">
          <img
            src="@/assets/material/cross.svg"
            class="justify-self-end pointer"
            @click="quotationModal = !quotationModal"
            alt="cross"
          />
          <h1 class="modal_header" style="color: #015fc3; font-size: 19px">
            {{ $t('packages').quotation_modal.modal_text }}
          </h1>
          <form
            class="flex w-full px-3 my-4 gap-y-2 grid justify-items-center"
            v-on:submit.prevent="requestQuotation"
          >
            <Input
              required
              :disabled="!!getCurrent_branch"
              :label="$t('packages').quotation_modal.company_name"
              class="w-full"
              v-model="quotation.company_name"
              v-if="!!getCurrent_branch || !user"
            />
            <div v-if="!!user & !getCurrent_branch" class="w-full">
              <label class="justify-self-start font-bold"
                >{{ $t('packages').quotation_modal.company_name }}
              </label>
              <select class="w-full py-1 px-1" v-model="selected_branch">
                <option v-for="(branch, index) in branches" :key="index" :value="branch">
                  {{ branch.option }}
                </option>
              </select>
            </div>
            <p
              class="text-left justify-self-start"
              style="font-weight: bold; color: #015fc3; font-size: 16px"
            >
              {{ $t('packages').quotation_modal.branch }}
            </p>
            <div class="flex justify-self-start">
              <b-form-group
                class="mr-2"
                v-for="(item, index) in $t('packages').quotation_modal.branch_item"
                :key="index"
              >
                <b-form-radio :value="item.value" v-model="quotation.branch" :disabled="!!user">
                  <p class="text-blue pl-1">{{ item.text }}</p>
                </b-form-radio>
              </b-form-group>
            </div>
            <Input
              v-if="quotation.branch !== 'headOffice'"
              required
              :disabled="!!user"
              :label="$t('packages').quotation_modal.branch_number"
              class="w-full"
              type="number"
              v-model="quotation.branch_number"
            />
            <Input
              v-if="quotation.branch !== 'headOffice'"
              required
              :disabled="!!user"
              :label="$t('packages').quotation_modal.branch_name"
              class="w-full"
              type="text"
              v-model="quotation.branch_name"
            />
            <Input
              required
              :label="$t('packages').quotation_modal.company_address"
              class="w-full"
              type="text"
              v-model="quotation.company_address"
            />
            <Input
              :label="$t('packages').quotation_modal.company_address2"
              class="w-full"
              type="text"
              v-model="quotation.company_address2"
            />
            <Input
              required
              :label="$t('packages').quotation_modal.company_city"
              class="w-full"
              type="text"
              v-model="quotation.company_city"
            />
            <Input
              required
              :label="$t('packages').quotation_modal.company_country"
              class="w-full"
              type="text"
              v-model="quotation.company_country"
            />
            <Input
              required
              :label="$t('packages').quotation_modal.company_postcode"
              class="w-full"
              type="tel"
              maxlength="5"
              v-model="quotation.company_postcode"
            />
            <Input
              required
              :label="$t('packages').quotation_modal.company_taxid"
              class="w-full"
              type="tel"
              maxlength="13"
              v-model="quotation.company_taxid"
            />
            <Input
              required
              :label="$t('packages').quotation_modal.company_tel"
              class="w-full"
              type="tel"
              v-model="quotation.company_tel"
            />
            <Input
              required
              :label="$t('packages').quotation_modal.company_email"
              class="w-full"
              type="email"
              placeholder="example@mail.com"
              v-model="quotation.company_email"
            />
            <hr class="my-3 w-full" />
            <Input
              :required="!quotation.same_email"
              :disabled="quotation.same_email"
              :label="$t('packages').quotation_modal.company_receive"
              class="w-full"
              type="email"
              placeholder="example@mail.com"
              v-model="quotation.company_receive"
            >
              <b-form-checkbox v-model="quotation.same_email">
                <p class="text-blue pl-1">{{ $t('packages').quotation_modal.same_email }}</p>
              </b-form-checkbox>
            </Input>
            <Button
              :text="$t('packages').quotation_modal.send"
              btnColor="gradient-btn"
              class="w-1/2 mt-4 self-center"
              type="submit"
            />
          </form>
        </div>

        <div v-else class="grid p-3 justify-center">
          <Loading />
        </div>
      </b-modal>

      <!-- End of Quotation Modal -->
    </div>
  </div>
</template>

<script>
import Features from '../components/general/features.vue'
import Button from '../components/general/general_button.vue'
import Input from '../components/general/modal_input.vue'
import Seperator from '../components/general/seperate_line.vue'
import { mapGetters, mapMutations } from 'vuex'
import { baseUrl } from '../utils/backend'
import { sortBy, uniqBy, filter } from 'lodash'
import Loading from '../components/general/loading.vue'
import Footer from '../components/footer.vue'
import axios from 'axios'
import moment from 'moment'
import Swal from 'sweetalert2'

export default {
  data() {
    return {
      quotationModal: false,
      quotation: {
        company_name: '',
        branch: 'headOffice',
        branch_number: '',
        branch_name: '',
        company_address: '',
        company_address2: '',
        company_city: '',
        company_country: '',
        company_postcode: '',
        company_taxid: '',
        company_tel: '',
        company_email: '',
        same_email: false,
        company_receive: '',
      },
      selected_branch: {},
      success: false,
      loading: false,
      oneYear: true, // if false = 2 years
      prices: 0,
      package_name: 'S',
      package_assets: 40,
      package_addon: false,
      contact: false,
      email: this.user ? this.user.email : '',
      tel: '',
      name: '',
      companyName: '',
      message: '',
      preparation: false,
      installation: false,
      data_transfer: false,
      additional: false,
      packages:
        this.$route.name == 'Upgrade'
          ? {
              8980: '200 Assets',
              11480: '700 Assets',
              13980: '1,200 Assets',
              16480: '1,700 Assets',
              17880: '2,200 Assets',
              20130: '2,700 Assets',
              22380: '3,200 Assets',
              22780: '3,700 Assets',
              24780: '4,200 Assets',
              26780: '4,700 Assets',
              27980: '5,000 Assets',
              Custom: '5,000+ Assets',
            }
          : {
              0: 'Free trial',
              8980: '200 Assets',
              11480: '700 Assets',
              13980: '1,200 Assets',
              16480: '1,700 Assets',
              17880: '2,200 Assets',
              20130: '2,700 Assets',
              22380: '3,200 Assets',
              22780: '3,700 Assets',
              24780: '4,200 Assets',
              26780: '4,700 Assets',
              27980: '5,000 Assets',
              Custom: '5,000+ Assets',
            },
    }
  },
  name: 'Packages',
  components: {
    Features,
    Input,
    Button,
    Loading,
    Footer,
    Seperator,
  },
  computed: {
    pricePerMonth() {
      return (
        this.oneYear
          ? parseFloat(this.prices) / 12
          : (Math.floor((parseFloat(this.prices) + 7980) * 0.0095) * 100) / 24
      )
        .toFixed(0)
        .toLocaleString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    ...mapGetters({
      store_packages: 'getPackages',
      user: 'getUser',
      getCurrent_branch: 'getCurrent_branch',
      currentPackage: 'getSelected_package',
      packageId: 'getPackage_id',
      branch: 'getCurrent_branch',
      packageDetail: 'getPackageDetail',
      companies: 'getCompany',
      getPackagesInformation: 'getPackagesInformation',
    }),
    perYear() {
      let text = null
      if (this.$i18n.locale === 'th') {
        this.oneYear ? (text = ' บาท/ปี') : (text = ' บาท/2 ปี')
      } else {
        this.oneYear ? (text = ' THB/Year') : (text = ' THB/2 Years')
      }
      return text
    },
    branches: function () {
      let branches = []
      this.companies.map((company) => {
        company.branches.map((branch) => {
          const checkPackage = () => {
            if (branch.orders.length != 0) {
              if (branch.orders[branch.orders.length - 1].package_id == 1) {
                return true
              } else {
                return false
              }
            } else {
              return true
            }
          }
          if (checkPackage()) {
            branches.push({
              option: company.name + ' (' + branch.branch_name + ')',
              companyName: company.name,
              branchName: branch.branch_name,
              branchNumber: branch.branch_no,
              branch_id: branch.branch_id,
              company_id: company.company_id,
            })
          }
        })
      })
      let filterBranches = uniqBy(branches, function (e) {
        return e.option
      })

      return sortBy(filterBranches, ['company_id', 'branch_id'])
    },
  },
  watch: {
    packageId() {
      console.log(this.packageId)
    },
    'quotation.same_email'() {
      if (this.quotation.same_email) {
        this.quotation.company_receive = this.quotation.company_email
      } else {
        this.quotation.company_receive = ''
      }
    },
    'quotation.company_email'() {
      if (this.quotation.same_email) {
        this.quotation.company_receive = this.quotation.company_email
      }
    },
    selected_branch: function () {
      this.quotation.branch_name = this.selected_branch.branchName
      this.quotation.companyName = this.selected_branch.companyName
      this.quotation.branch_number = this.selected_branch.branchNumber
      this.selected_branch.branchNumber == 0
        ? (this.quotation.branch = 'headOffice')
        : (this.quotation.branch = 'branch')
      if (this.getPackagesInformation) {
        this.quotation.branch_number = this.selected_branch.branchNumber
        this.quotation.company_taxid = this.getPackagesInformation.taxNumber
        this.quotation.company_address = this.getPackagesInformation.address1
        this.quotation.company_address2 = this.getPackagesInformation.address2
        this.quotation.company_city = this.getPackagesInformation.city
        this.quotation.company_country = this.getPackagesInformation.country
        this.quotation.company_postcode = this.getPackagesInformation.postal
        this.quotation.company_tel = this.user.contact_no
        this.quotation.company_email = this.user.email
      } else {
        console.log('companies -->>', this.companies)
        console.log('branchname', this.selected_branch.companyName)
        let selectedBranch = filter(this.companies, { name: this.selected_branch.companyName })
        let selectBranchQuotation = selectedBranch[0].branches[0]
        console.log('selectedBranch', selectBranchQuotation)
        this.quotation.branch_number = selectBranchQuotation.branch_no
        this.quotation.company_taxid = selectBranchQuotation.TAX_ID
        this.quotation.company_address = selectBranchQuotation.billing_address_1
        this.quotation.company_address2 = selectBranchQuotation.billing_address_2
        this.quotation.company_city = selectBranchQuotation.city
        this.quotation.company_country = selectBranchQuotation.country
        this.quotation.company_postcode = selectBranchQuotation.zipcode
        this.quotation.company_tel = this.user.contact_no
        this.quotation.company_email = this.user.email
      }
    },
  },
  methods: {
    async checkout() {
      if (this.package_name == 'Custom' && !this.user) {
        this.openModal('register')
      } else {
        console.log('this.package_name -->', this.package_name)
        await axios
          .post(`${baseUrl()}find_package_id`, {
            package_name: this.package_name,
            package_assets: this.package_assets,
            lifetime_years: this.oneYear ? 1 : 2,
            package_addon:
              this.installation || this.preparation || this.data_transfer || this.additional,
          })
          .then((response) => {
            if (response.status == 200) {
              let price = parseFloat(this.prices)
              if (this.oneYear) {
                price /= 12
              } else {
                price = (Math.floor((parseFloat(price) + 7980) * 0.0095) * 100) / 24
              }
              price = price
                .toFixed(2)
                .toLocaleString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')

              let payload = {
                package_name: this.package_name,
                package_assets: this.package_assets,
                oneYear: this.oneYear,
                perMonth: price,
                addOn: {
                  installation: this.installation,
                  preparation: this.preparation,
                  data_transfer: this.data_transfer,
                  additional: this.additional,
                },
                prices: this.prices,
              }
              this.$store.commit('PACKAGE_DETAIL_MUTATION', payload)
              this.setPackageId(response.data.message)
              if (!this.user) {
                this.$router.push('/register/payment')
                // this.openModal('register')
              } else {
                if (this.package_name == 'Custom') {
                  this.openModal('contact')
                } else {
                  if (this.branch) {
                    this.$router.push('/payment')
                  } else {
                    this.$router.push('/companyselect')
                  }
                }
              }
            } else if (response.status == 400) {
              alert(response.data.message)
            }
          })
      }
    },
    async showQuotationModal() {
      // console.log('sss', this.quotation, this.getPackagesInformation)
      if (this.getPackagesInformation) {
        this.quotation.branch_number = this.selected_branch.branchNumber
        this.quotation.company_taxid = this.getPackagesInformation.taxNumber
        this.quotation.company_address = this.getPackagesInformation.address1
        this.quotation.company_address2 = this.getPackagesInformation.address2
        this.quotation.company_city = this.getPackagesInformation.city
        this.quotation.company_country = this.getPackagesInformation.country
        this.quotation.company_postcode = this.getPackagesInformation.postal
        this.quotation.company_tel = this.user.contact_no
        this.quotation.company_email = this.user.email
      }
      // if (this.user.user_id && !this.getCurrent_branch) {
      //   this.$router.push('/companyselect')
      // }
      this.quotationModal = true
    },
    async requestQuotation() {
      this.loading = true
      const packageRes = await axios.post(`${baseUrl()}find_package_id`, {
        package_name: this.package_name,
        package_assets: this.package_assets,
        lifetime_years: this.oneYear ? 1 : 2,
        package_addon:
          this.installation || this.preparation || this.data_transfer || this.additional,
      })
      const res = await axios.post(`${baseUrl()}quotation`, {
        package_id: packageRes.data.message,
        branch_id: this.branch ? this.branch.branch_id : null,
        company_name: this.quotation.company_name,
        branch_no: this.quotation.branch_number || 0,
        branch_name:
          this.quotation.branch === 'headOffice' ? 'Head Office' : this.quotation.branch_name,
        TAX_ID: this.quotation.company_taxid,
        billing_email: this.quotation.company_receive,
        billing_address_1: this.quotation.company_address,
        billing_address_2: this.quotation.company_address2,
        city: this.quotation.company_city,
        country: this.quotation.company_country,
        zipcode: this.quotation.company_postcode,
        email: this.quotation.company_email,
        price: +this.prices,
        vat: +(+this.prices * 0.03).toFixed(2),
        total: +(+this.prices - +this.prices * 0.03).toFixed(2),
      })
      let productItems = []
      productItems.push({
        items: [
          {
            desc: `VERSCAN (${this.package_name})`,
            quantity: 1,
            unit: this.$t('packages').product_unit.package,
            price: this.oneYear
              ? +parseFloat(this.prices).toFixed(0)
              : +(Math.floor((parseFloat(this.prices) + 7980) * 0.0095) * 100).toFixed(0),
            amount: this.oneYear
              ? +parseFloat(this.prices).toFixed(0)
              : +(Math.floor((parseFloat(this.prices) + 7980) * 0.0095) * 100).toFixed(0),
          },
          {
            desc: 'QR Code',
            quantity: this.package_assets,
            unit: this.$t('packages').product_unit.qr,
            price: 0.0,
            amount: 0.0,
          },
          {
            desc: 'System Period',
            quantity: this.oneYear ? 1 : 2,
            unit: this.$t('packages').product_unit.year,
            price: 0.0,
            amount: 0.0,
          },
        ],
      })
      let pdfData = {
        pdfName: 'ใบเสนอราคา / Quotation',
        type: 'original',
        doc_code: res.data.data.code,
        doc_date: moment().format('DD/MM/YYYY'),
        doc_due: moment().add(1, 'M').format('DD/MM/YYYY'),
        company_name: this.$t('packages.quotation_company'),
        company_address: this.$t('packages.quotation_company_address'),
        company_taxid: '0105561064958',
        company_tel: '02 096 2959',
        company_email: 'service@verscan.com',
        customer_name: `${this.quotation.company_name} ${
          this.quotation.branch === 'headOffice'
            ? `(${this.$t('packages').quotation_modal.branch_display})`
            : `(${this.quotation.branch_name})`
        }`,
        customer_address: `${this.quotation.company_address} ${this.quotation.company_address2} ${this.quotation.company_city} ${this.quotation.company_postcode} ${this.quotation.company_country}`,
        customer_taxid: this.quotation.company_taxid,
        customer_tel: this.quotation.company_tel,
        customer_email: this.quotation.company_email,
        products: productItems,
        remark: this.$t('packages').quotation_remark,
        withold_tax: 3,
        bank_acc: [
          {
            bank: 'กสิกรไทย',
            acc_type: 'ออมทรัพย์',
            acc_name: 'บจก. เฮาส์ออฟเดฟ เทคโนโลยี',
            acc_number: '040-1-81306-3',
          },
        ],
        auth_date: moment().format('DD/MM/YYYY'),
      }
      try {
        const printRes = await axios({
          url: 'https://api.verscan.com/pdf/pdfmake/generateQuotation',
          // url: 'http://localhost:3000/pdfmake/generateQuotation',
          method: 'post',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          data: pdfData,
          responseType: 'blob',
        })
        let emailBody = `<p><b
            style="color: rgb(0, 0, 0); font-family: Calibri, Arial, Helvetica, sans-serif; font-size: 16px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; font-weight: normal;"></b>
    </p>
    <p dir="ltr" style="margin-top: 0pt; margin-bottom: 0pt; line-height: 1.38;"><b id="isPasted"
            style="color: rgb(0, 0, 0); font-family: Calibri, Arial, Helvetica, sans-serif; font-size: 16px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; font-weight: normal;"><span
                style="margin: 0px; padding: 0px; border: 0px; font-style: inherit; font-variant: inherit; font-weight: 700; font-stretch: inherit; font-size: 11pt; line-height: inherit; font-family: Arial; vertical-align: baseline; color: rgb(0, 0, 0);">เรียน
                ${this.quotation.company_name}</span></b></p><b id="isPasted"
        style="color: rgb(0, 0, 0); font-family: Calibri, Arial, Helvetica, sans-serif; font-size: 16px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; font-weight: normal;"><br
            aria-hidden="true">
        <p dir="ltr" style="margin-top: 0pt; margin-bottom: 0pt; line-height: 1.38;"><span
                style="margin: 0px; padding: 0px; border: 0px; font-style: inherit; font-variant: inherit; font-weight: 400; font-stretch: inherit; font-size: 11pt; line-height: inherit; font-family: Arial; vertical-align: baseline; color: rgb(0, 0, 0);">ขอบคุณที่ท่านให้ความสนใจ
                VERSCAN ระบบตรวจนับสินทรัพย์ถาวรภายในองค์กร&nbsp;</span></p><br aria-hidden="true">
        <p dir="ltr" style="margin-top: 0pt; margin-bottom: 0pt; line-height: 1.38;"><span
                style="margin: 0px; padding: 0px; border: 0px; font-style: inherit; font-variant: inherit; font-weight: 400; font-stretch: inherit; font-size: 11pt; line-height: inherit; font-family: Arial; vertical-align: baseline; color: rgb(0, 0, 0);">เราได้รับคำขอใบเสนอราคาจากท่าน</span><span
                style="margin: 0px; padding: 0px; border: 0px; font-style: inherit; font-variant: inherit; font-weight: 400; font-stretch: inherit; font-size: 11.5pt; line-height: inherit; font-family: Arial; vertical-align: baseline; color: rgb(0, 0, 0);">เมื่อ&nbsp;</span><span
                style="margin: 0px; padding: 0px; border: 0px; font-style: inherit; font-variant: inherit; font-weight: 700; font-stretch: inherit; font-size: 11.5pt; line-height: inherit; font-family: Arial; vertical-align: baseline; color: rgb(0, 0, 0);">${moment()
                  .locale('th')
                  .format('วันddddที่ DD MMMM YYYY เวลา HH:mm น.')}&nbsp;</span><span
                style="margin: 0px; padding: 0px; border: 0px; font-style: inherit; font-variant: inherit; font-weight: 400; font-stretch: inherit; font-size: 11.5pt; line-height: inherit; font-family: Arial; vertical-align: baseline; color: rgb(0, 0, 0);">โดยรายละเอียดของแพ็กเกจที่ท่านเลือกมีดังนี้</span>
        </p>
        <br />
        <p dir="ltr" style="margin-top: 0pt; margin-bottom: 0pt; line-height: 1.38;"><span
                style="margin: 0px; padding: 0px; border: 0px; font-style: inherit; font-variant: inherit; font-weight: 400; font-stretch: inherit; font-size: 11pt; line-height: inherit; font-family: Arial; vertical-align: baseline; color: rgb(0, 0, 0);">ขนาดแพ็กเกจ
                / จำนวนสติ๊กเกอร์ QR Code :&nbsp;</span><span
                style="margin: 0px; padding: 0px; border: 0px; font-style: inherit; font-variant: inherit; font-weight: 700; font-stretch: inherit; font-size: 11pt; line-height: inherit; font-family: Arial; vertical-align: baseline; color: rgb(0, 0, 0);">${
                  this.package_name
                }
                (${this.package_assets} ชิ้น)</span></p>
        <p dir="ltr" style="margin-top: 0pt; margin-bottom: 0pt; line-height: 1.38;"><span
                style="margin: 0px; padding: 0px; border: 0px; font-style: inherit; font-variant: inherit; font-weight: 400; font-stretch: inherit; font-size: 11pt; line-height: inherit; font-family: Arial; vertical-align: baseline; color: rgb(0, 0, 0);">ระยะเวลาแพ็กเกจ:&nbsp;</span><span
                style="margin: 0px; padding: 0px; border: 0px; font-style: inherit; font-variant: inherit; font-weight: 700; font-stretch: inherit; font-size: 11pt; line-height: inherit; font-family: Arial; vertical-align: baseline; color: rgb(0, 0, 0);">${
                  this.oneYear ? 1 : 2
                }
                ปี</span></p>
        <p dir="ltr" style="margin-top: 0pt; margin-bottom: 0pt; line-height: 1.38;"><span
                style="margin: 0px; padding: 0px; border: 0px; font-style: inherit; font-variant: inherit; font-weight: 400; font-stretch: inherit; font-size: 11pt; line-height: inherit; font-family: Arial; vertical-align: baseline; color: rgb(0, 0, 0);">ราคาเฉลี่ยต่อเดือน:</span><span
                style="margin: 0px; padding: 0px; border: 0px; font-style: inherit; font-variant: inherit; font-weight: 700; font-stretch: inherit; font-size: 11pt; line-height: inherit; font-family: Arial; vertical-align: baseline; color: rgb(0, 0, 0);">&nbsp;${
                  this.pricePerMonth
                }
                บาท/เดือน</span></p>
                <br />
        <p dir="ltr" style="margin-top: 0pt; margin-bottom: 0pt; line-height: 1.38;"><span
                style="margin: 0px; padding: 0px; border: 0px; font-style: inherit; font-variant: inherit; font-weight: 700; font-stretch: inherit; font-size: 12pt; line-height: inherit; font-family: Arial; vertical-align: baseline; color: rgb(0, 0, 0);">ราคารวม:
                ${
                  this.oneYear
                    ? (+this.prices)
                        .toFixed(2)
                        .toLocaleString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    : (Math.floor((parseFloat(this.prices) + 7980) * 0.0095) * 100)
                        .toFixed(2)
                        .toLocaleString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                } บาท/ปี</span></p><br aria-hidden="true">
        <p dir="ltr" style="margin-top: 0pt; margin-bottom: 0pt; line-height: 1.38;"><span
                style="margin: 0px; padding: 0px; border: 0px; font-style: inherit; font-variant: inherit; font-weight: 400; font-stretch: inherit; font-size: 11pt; line-height: inherit; font-family: Arial; vertical-align: baseline; color: rgb(0, 0, 0);">โดยท่านสามารถดาวน์โหลด&nbsp;</span><span
                style="margin: 0px; padding: 0px; border: 0px; font-style: inherit; font-variant: inherit; font-weight: 700; font-stretch: inherit; font-size: 11pt; line-height: inherit; font-family: Arial; vertical-align: baseline; color: rgb(0, 0, 0);">&quot;ใบเสนอราคา
                เลขที่ ${res.data.data.code}&quot;</span><span
                style="margin: 0px; padding: 0px; border: 0px; font-style: inherit; font-variant: inherit; font-weight: 400; font-stretch: inherit; font-size: 11pt; line-height: inherit; font-family: Arial; vertical-align: baseline; color: rgb(0, 0, 0);">&nbsp;ได้ตามไฟล์แนบที่อีเมลฉบับนี้</span>
        </p><br aria-hidden="true">
        <p dir="ltr" style="margin-top: 0pt; margin-bottom: 0pt; line-height: 1.38;"><span
                style="margin: 0px; padding: 0px; border: 0px; font-style: inherit; font-variant: inherit; font-weight: 400; font-stretch: inherit; font-size: 11pt; line-height: inherit; font-family: Arial; vertical-align: baseline; color: rgb(0, 0, 0);">ขอแสดงความนับถือ</span>
        </p><br aria-hidden="true">
        <p dir="ltr" style="margin-top: 0pt; margin-bottom: 0pt; line-height: 1.38;"><span
                style="margin: 0px; padding: 0px; border: 0px; font-style: inherit; font-variant: inherit; font-weight: 700; font-stretch: inherit; font-size: 11pt; line-height: inherit; font-family: Arial; vertical-align: baseline; color: rgb(0, 0, 0);">ฝ่ายบริการลูกค้า
                VERSCAN</span></p>
        <p dir="ltr" style="margin-top: 0pt; margin-bottom: 0pt; line-height: 1.38;"><span
                style="margin: 0px; padding: 0px; border: 0px; font-style: inherit; font-variant: inherit; font-weight: 400; font-stretch: inherit; font-size: 11pt; line-height: inherit; font-family: Arial; vertical-align: baseline; color: rgb(0, 0, 0);">บริษัท
                เฮาส์ออฟเดฟ เทคโนโลยี จำกัด</span></p>
        <p dir="ltr" style="margin-top: 0pt; margin-bottom: 0pt; line-height: 1.38;"><span
                style="margin: 0px; padding: 0px; border: 0px; font-style: inherit; font-variant: inherit; font-weight: 400; font-stretch: inherit; font-size: 11pt; line-height: inherit; font-family: Arial; vertical-align: baseline; color: rgb(0, 0, 0);">อีเมล:&nbsp;</span><a
                data-auth="NotApplicable" data-linkindex="0" href="mailto:service@verscan.com" rel="noopener noreferrer"
                style="margin: 0px; padding: 0px; border: 0px; font: inherit; vertical-align: baseline;"
                target="_blank"><span
                    style="margin: 0px; padding: 0px; border: 0px; font-style: inherit; font-variant: inherit; font-weight: 400; font-stretch: inherit; font-size: 11pt; line-height: inherit; font-family: Arial; vertical-align: baseline; color: rgb(17, 85, 204); text-decoration: underline;">service@verscan.com</span><span
                    style="margin: 0px; padding: 0px; border: 0px; font-style: inherit; font-variant: inherit; font-weight: 400; font-stretch: inherit; font-size: 11pt; line-height: inherit; font-family: Arial; vertical-align: baseline; color: rgb(0, 0, 0);"><br
                        aria-hidden="true"></span></a><span
                style="margin: 0px; padding: 0px; border: 0px; font-style: inherit; font-variant: inherit; font-weight: 400; font-stretch: inherit; font-size: 11pt; line-height: inherit; font-family: Arial; vertical-align: baseline; color: rgb(0, 0, 0);">Line:
                @verscan</span></p>
        <p dir="ltr" style="margin-top: 0pt; margin-bottom: 0pt; line-height: 1.38;"><span
                style="margin: 0px; padding: 0px; border: 0px; font-style: inherit; font-variant: inherit; font-weight: 400; font-stretch: inherit; font-size: 11pt; line-height: inherit; font-family: Arial; vertical-align: baseline; color: rgb(0, 0, 0);">โทร:
                02 096 2959</span></p>
        <p aria-hidden="true" dir="ltr"
            style="margin-top: 0pt; margin-bottom: 0pt; line-height: 1.38; border-bottom: 1pt solid rgb(0, 0, 0); padding: 0pt 0pt 2pt;">
            &nbsp;</p>
        <br>
        <p dir="ltr" style="margin-top: 0pt; margin-bottom: 0pt; line-height: 1.38;"><span
                style="margin: 0px; padding: 0px; border: 0px; font-style: inherit; font-variant: inherit; font-weight: 700; font-stretch: inherit; font-size: 11pt; line-height: inherit; font-family: Arial; vertical-align: baseline; color: rgb(0, 0, 0);">Dear
                ${this.quotation.company_name},</span></p><br aria-hidden="true">
        <p dir="ltr" style="margin-top: 0pt; margin-bottom: 0pt; line-height: 1.38;"><span
                style="margin: 0px; padding: 0px; border: 0px; font-style: inherit; font-variant: inherit; font-weight: 400; font-stretch: inherit; font-size: 11pt; line-height: inherit; font-family: Arial; vertical-align: baseline; color: rgb(0, 0, 0);">Thank
                you for your interest in VERSCAN, a fixed asset verification system in the organization.</span></p><br
            aria-hidden="true">
        <p dir="ltr" style="margin-top: 0pt; margin-bottom: 0pt; line-height: 1.38;"><span
                style="margin: 0px; padding: 0px; border: 0px; font-style: inherit; font-variant: inherit; font-weight: 400; font-stretch: inherit; font-size: 11pt; line-height: inherit; font-family: Arial; vertical-align: baseline; color: rgb(0, 0, 0);">We
                have received your request for a quotation on&nbsp;</span><span
                style="margin: 0px; padding: 0px; border: 0px; font-style: inherit; font-variant: inherit; font-weight: 700; font-stretch: inherit; font-size: 11pt; line-height: inherit; font-family: Arial; vertical-align: baseline; color: rgb(0, 0, 0);">${moment().format(
                  'dddd, MMMM DD, YYYY [at] HH:mm.'
                )}</span><span
                style="margin: 0px; padding: 0px; border: 0px; font-style: inherit; font-variant: inherit; font-weight: 400; font-stretch: inherit; font-size: 11pt; line-height: inherit; font-family: Arial; vertical-align: baseline; color: rgb(0, 0, 0);">.
                The details of the package you selected are as follows.</span></p>
                <br />
        <p dir="ltr" style="margin-top: 0pt; margin-bottom: 0pt; line-height: 1.38;"><span
                style="margin: 0px; padding: 0px; border: 0px; font-style: inherit; font-variant: inherit; font-weight: 400; font-stretch: inherit; font-size: 11pt; line-height: inherit; font-family: Arial; vertical-align: baseline; color: rgb(0, 0, 0);">Package
                size / Number of QR Code stickers:&nbsp;</span><span
                style="margin: 0px; padding: 0px; border: 0px; font-style: inherit; font-variant: inherit; font-weight: 700; font-stretch: inherit; font-size: 11pt; line-height: inherit; font-family: Arial; vertical-align: baseline; color: rgb(0, 0, 0);">${
                  this.package_name
                }
                (${this.package_assets} pieces)</span></p>
        <p dir="ltr" style="margin-top: 0pt; margin-bottom: 0pt; line-height: 1.38;"><span
                style="margin: 0px; padding: 0px; border: 0px; font-style: inherit; font-variant: inherit; font-weight: 400; font-stretch: inherit; font-size: 11pt; line-height: inherit; font-family: Arial; vertical-align: baseline; color: rgb(0, 0, 0);">Package
                duration:</span><span
                style="margin: 0px; padding: 0px; border: 0px; font-style: inherit; font-variant: inherit; font-weight: 700; font-stretch: inherit; font-size: 11pt; line-height: inherit; font-family: Arial; vertical-align: baseline; color: rgb(0, 0, 0);">&nbsp;${
                  this.oneYear ? 1 : 2
                }
                year</span></p>
        <p dir="ltr" style="margin-top: 0pt; margin-bottom: 0pt; line-height: 1.38;"><span
                style="margin: 0px; padding: 0px; border: 0px; font-style: inherit; font-variant: inherit; font-weight: 400; font-stretch: inherit; font-size: 11pt; line-height: inherit; font-family: Arial; vertical-align: baseline; color: rgb(0, 0, 0);">Average
                price per month:</span><span
                style="margin: 0px; padding: 0px; border: 0px; font-style: inherit; font-variant: inherit; font-weight: 700; font-stretch: inherit; font-size: 11pt; line-height: inherit; font-family: Arial; vertical-align: baseline; color: rgb(0, 0, 0);">&nbsp;${
                  this.pricePerMonth
                }
                THB/month</span></p>
                <br />
        <p dir="ltr" style="margin-top: 0pt; margin-bottom: 0pt; line-height: 1.38;"><span
                style="margin: 0px; padding: 0px; border: 0px; font-style: inherit; font-variant: inherit; font-weight: 700; font-stretch: inherit; font-size: 12pt; line-height: inherit; font-family: Arial; vertical-align: baseline; color: rgb(0, 0, 0);">Total
                price: ${
                  this.oneYear
                    ? (+this.prices)
                        .toFixed(2)
                        .toLocaleString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    : (Math.floor((parseFloat(this.prices) + 7980) * 0.0095) * 100)
                        .toFixed(2)
                        .toLocaleString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }&nbsp;</span><span
                style="margin: 0px; padding: 0px; border: 0px; font-style: inherit; font-variant: inherit; font-weight: 700; font-stretch: inherit; font-size: 11pt; line-height: inherit; font-family: Arial; vertical-align: baseline; color: rgb(0, 0, 0);">THB/year</span>
        </p><br aria-hidden="true">
        <p dir="ltr" style="margin-top: 0pt; margin-bottom: 0pt; line-height: 1.38;"><span
                style="margin: 0px; padding: 0px; border: 0px; font-style: inherit; font-variant: inherit; font-weight: 400; font-stretch: inherit; font-size: 11pt; line-height: inherit; font-family: Arial; vertical-align: baseline; color: rgb(0, 0, 0);">The&nbsp;</span><span
                style="margin: 0px; padding: 0px; border: 0px; font-style: inherit; font-variant: inherit; font-weight: 700; font-stretch: inherit; font-size: 11pt; line-height: inherit; font-family: Arial; vertical-align: baseline; color: rgb(0, 0, 0);">&quot;Quotation
                No. ${res.data.data.code}&quot;</span><span
                style="margin: 0px; padding: 0px; border: 0px; font-style: inherit; font-variant: inherit; font-weight: 400; font-stretch: inherit; font-size: 11pt; line-height: inherit; font-family: Arial; vertical-align: baseline; color: rgb(0, 0, 0);">&nbsp;can
                be downloaded at the attachment of this email.</span></p><br aria-hidden="true">
        <p dir="ltr" style="margin-top: 0pt; margin-bottom: 0pt; line-height: 1.38;"><span
                style="margin: 0px; padding: 0px; border: 0px; font-style: inherit; font-variant: inherit; font-weight: 400; font-stretch: inherit; font-size: 11pt; line-height: inherit; font-family: Arial; vertical-align: baseline; color: rgb(0, 0, 0);">Sincerely,</span>
        </p><br aria-hidden="true">
        <p dir="ltr" style="margin-top: 0pt; margin-bottom: 0pt; line-height: 1.38;"><span
                style="margin: 0px; padding: 0px; border: 0px; font-style: inherit; font-variant: inherit; font-weight: 700; font-stretch: inherit; font-size: 11pt; line-height: inherit; font-family: Arial; vertical-align: baseline; color: rgb(0, 0, 0);">VERSCAN
                Customer Service</span></p>
        <p dir="ltr" style="margin-top: 0pt; margin-bottom: 0pt; line-height: 1.38;"><span
                style="margin: 0px; padding: 0px; border: 0px; font-style: inherit; font-variant: inherit; font-weight: 400; font-stretch: inherit; font-size: 11pt; line-height: inherit; font-family: Arial; vertical-align: baseline; color: rgb(0, 0, 0);">House
                of Dev Technology Co., Ltd.&nbsp;</span></p>
        <p dir="ltr" style="margin-top: 0pt; margin-bottom: 0pt; line-height: 1.38;"><span
                style="margin: 0px; padding: 0px; border: 0px; font-style: inherit; font-variant: inherit; font-weight: 400; font-stretch: inherit; font-size: 11pt; line-height: inherit; font-family: Arial; vertical-align: baseline; color: rgb(0, 0, 0);">Email:&nbsp;</span><a
                data-auth="NotApplicable" data-linkindex="1" href="mailto:service@verscan.com" rel="noopener noreferrer"
                style="margin: 0px; padding: 0px; border: 0px; font: inherit; vertical-align: baseline;"
                target="_blank"><span
                    style="margin: 0px; padding: 0px; border: 0px; font-style: inherit; font-variant: inherit; font-weight: 400; font-stretch: inherit; font-size: 11pt; line-height: inherit; font-family: Arial; vertical-align: baseline; color: rgb(17, 85, 204); text-decoration: underline;">service@verscan.com</span></a>
        </p>
        <p dir="ltr" style="margin-top: 0pt; margin-bottom: 0pt; line-height: 1.38;"><span
                style="margin: 0px; padding: 0px; border: 0px; font-style: inherit; font-variant: inherit; font-weight: 400; font-stretch: inherit; font-size: 11pt; line-height: inherit; font-family: Arial; vertical-align: baseline; color: rgb(0, 0, 0);">Line:
                @verscan</span></p>
        <p dir="ltr" style="margin-top: 0pt; margin-bottom: 0pt; line-height: 1.38;"><span
                style="margin: 0px; padding: 0px; border: 0px; font-style: inherit; font-variant: inherit; font-weight: 400; font-stretch: inherit; font-size: 11pt; line-height: inherit; font-family: Arial; vertical-align: baseline; color: rgb(0, 0, 0);">Tel:
                02 096 2959</span></p>
    </b>
    <p></p>`
        let pdfFile = new File([printRes.data], `${res.data.data.code}.pdf`)
        console.log(pdfFile)
        let formData = new FormData()
        formData.append(
          'subject',
          `[VERSCAN] ใบเสนอราคา เลขที่ ${res.data.data.code} / Quotation No. ${res.data.data.code}`
        )
        formData.append('body', emailBody)
        formData.append('sender_email', 'bot@houseofdev.tech')
        formData.append('sender_name', 'VERSCAN')
        formData.append('recipient_email', this.quotation.company_receive)
        formData.append('recipient_email_cc', 'finance@houseofdev.tech')
        formData.append('number_of_file', 1)
        formData.append('attachment_1', pdfFile)
        const emailRes = await axios.post(
          `https://dev.houseofdev.tech/hod-mailer/verscan_mailer.php`,
          formData
        )
        console.log('emailres', emailRes)
        await Swal.fire({
          icon: 'success',
          title: this.$t('packages').quotation_email,
          text: this.$t('packages').quotation_download,
          confirmButtonText: this.$t('packages').quotation_confirm,
          confirmButtonColor: '#007AFE',
        })
        this.quotationModal = false
      } catch (err) {
        if (err.response) {
          alert(err.response.data.message || 'error')
        } else {
          alert(err)
        }
      }
      this.loading = false
    },

    ...mapMutations({
      setPackageId: 'package_idMutations',
    }),
    async login(e) {
      e.preventDefault()
      this.$store
        .dispatch('login', {
          email: this.email,
          password: this.password,
        })
        .then(() => {
          this.$router.push('/payment')
        })
    },
    async register(e) {
      e.preventDefault()
      await this.$store.dispatch('register', {
        email: this.email,
        password: this.password,
        contact_no: this.tel,
        company_name: this.companyName,
      })
    },
    async openModal(option) {
      if (option == 'register') {
        this.step = 0
        this.$root.$emit('bv::show::modal', 'register')
      } else if (option == 'login') {
        this.step = 1
        this.$root.$emit('bv::show::modal', 'register')
      } else {
        this.contact = true
        this.success = false
      }
    },
    closeModal() {
      this.$root.$emit('bv::hide::modal', 'register')
    },
    onChange(value) {
      switch (value) {
        case '0':
          this.package_assets = 0
          this.package_name = 'Free trial'
          break
        case '8980':
          this.package_assets = 200
          this.package_name = 'S'
          break
        case '11480':
          this.package_assets = 700
          this.package_name = 'S'
          break
        case '13980':
          this.package_assets = 1200
          this.package_name = 'S'
          break
        case '16480':
          this.package_assets = 1700
          this.package_name = 'S'
          break
        case '17880':
          this.package_assets = 2200
          this.package_name = 'M'
          break
        case '20130':
          this.package_assets = 2700
          this.package_name = 'M'
          break
        case '22380':
          this.package_assets = 3200
          this.package_name = 'M'
          break
        case '22780':
          this.package_assets = 3700
          this.package_name = 'L'
          break
        case '24780':
          this.package_assets = 4200
          this.package_name = 'L'
          break
        case '26780':
          this.package_assets = 4700
          this.package_name = 'L'
          break
        case '27980':
          this.package_assets = 5000
          this.package_name = 'L'
          break
        case 'Custom':
          this.package_assets = 5500
          this.package_name = 'Custom'
          break
      }
    },
    async trial() {
      this.setPackageId(1)
      if (!this.user) {
        this.$router.push('/register/payment')
      } else {
        if (this.branch) {
          this.$router.push('/payment')
        } else {
          this.$router.push('/companyselect')
        }
      }
    },
    async contactUs() {
      this.loading = true
      await axios
        .post(
          `${baseUrl()}contact_us?user_id=${this.user.user_id}&company_id=${
            this.branch.company_id
          }&branch_id=${this.branch.branch_id}`,
          {
            user_name: this.name,
            user_email: this.email,
            company_name: this.companyName,
            brach_name: this.branch.branch_name,
            contact_no: this.tel,
            package_id: this.packageId,
            addon_detail:
              (this.preparation
                ? 'บริการจัดทำ QR-code บนวัสดุชนิดต่างๆ เช่น กระดาษกันน้ำ, การเคลือบพลาสติก, แผ่นโลหะ, อื่นๆ, '
                : '') +
              (this.installation ? 'บริการทีมงานติดตั้ง QR-Code เพื่อระบุสินทรัพย์, ' : '') +
              (this.data_transfer ? 'บริการโอนย้ายข้อมูลจากระบบ ERP เดิมขององค์กร, ' : '') +
              (this.additional
                ? 'บริการเพิ่มฟีเจอร์อื่นๆ ที่เกี่ยวข้อง เพื่อให้ตอบโจทย์กับองค์กรของคุณ '
                : ''),
            message: this.message,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            },
          }
        )
        .then((response) => {
          if (response.status == 200) {
            this.loading = false
            this.success = true
            this.email = ''
            this.tel = ''
            this.name = ''
            this.company_name = ''
            this.$router.push('/paymentsuccess')
          } else if (response.status == 400) {
            alert(response.data.message)
          }
        })
        .catch((err) => {
          this.loading = false
          alert(err.response.data.message.toString())
        })
    },
    setQuotationInfo() {
      if (this.user.user_id && this.getCurrent_branch) {
        const company = this.getCurrent_branch
        this.quotation = {
          company_name: company.company_name,
          branch: company.branch_no === 0 ? 'headOffice' : 'branch',
          branch_number: company.branch_no === 0 ? '' : company.branch_no,
          branch_name: company.branch_no === 0 ? '' : company.branch_name,
          company_address: '',
          company_address2: '',
          company_city: '',
          company_country: '',
          company_postcode: '',
          company_taxid: '',
          company_tel: this.user.contact_no,
          company_email: this.user.email,
          same_email: false,
          company_receive: '',
        }
      }
    },
  },
  async mounted() {
    this.$store.dispatch('getPackages')
    if (this.packageDetail) {
      this.$refs.slider.setValue(this.packageDetail.prices.toString())
      this.prices = this.packageDetail.prices.toString()
      this.oneYear = this.packageDetail.oneYear
      this.package_name = this.packageDetail.package_name
      this.package_assets = this.packageDetail.package_assets
      this.preparation = this.packageDetail?.addOn?.preparation
      this.installation = this.packageDetail?.addOn?.installation
      this.data_transfer = this.packageDetail?.addOn?.data_transfer
      this.additional = this.packageDetail?.addOn?.additional
    } else {
      if (this.currentPackage) {
        this.$refs.slider.setValue(this.currentPackage.toString())
        this.prices = this.selected_package.toString()
      } else {
        if (this.$route.name == 'Upgrade') {
          this.$refs.slider.setValue('8980')
          this.prices = 8980
        } else {
          this.$refs.slider.setValue('0')
          this.prices = 0
        }
      }
    }

    this.email = this.user.email
    this.companyName = this.branch.company_name
    this.setQuotationInfo()
  },
  metaInfo() {
    return {
      title: 'Packages',
      meta: [
        {
          name: 'description',
          content: '',
        },
      ],
    }
  },
}
</script>

<style scoped>
.grey-border {
  border: solid #d2d2d2 1px;
}
.loading {
  font-size: 18px;
}
.packages {
  background-color: white;
}

h1 {
  color: black;
  font-size: 20px;
}
p {
  color: black;
}
button {
  background: #f2f2f2;
  border-radius: 10px;
  font-size: 14px;
  color: black;
}
select {
  background: #ffffff;
  border: 1px solid #015fc3;
  box-sizing: border-box;
  border-radius: 8px;
  color: #015fc3;
}
li {
  color: #888888;
}
textarea {
  background: #ffffff;
  border: 1px solid #015fc3;
  box-sizing: border-box;
  border-radius: 15px;
  color: #777;
}
label {
  font-weight: bold;
  color: #015fc3;
  font-size: 16px;
}
.active {
  background: #0b6bd2;
  color: white;
}

#price-box {
  border: 2px solid #007afe;
  color: #007afe;
  box-sizing: border-box;
  border-radius: 10px;
}

#checkout {
  color: white;
  font-size: 20px;
  background: linear-gradient(90deg, #045fbf 0%, #1d89ff 100%);
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}

#quotation-btn {
  color: #007afe;
  font-size: 20px;
  background-color: #fff;
  /* background: linear-gradient(90deg, #045fbf 0%, #1d89ff 100%); */
  /* box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.25); */
  border: 1px solid #007afe;
  border-radius: 10px;
}

#promotion {
  color: #ff5e5e;
}

.grey {
  color: #777;
  font-size: 12px;
}

.blue-checkbox {
  border: 2px solid #007afe;
  box-sizing: border-box;
  border-radius: 3px;
}

.modal_header {
  color: #015fc3;
  text-shadow: none;
}

.grey-box {
  background: #f4f4f4;
  border-radius: 10px;
}

.grey-box h2 {
  color: #000;
  font-size: 14px !important;
}

.grey-box p {
  color: #7c7c7c;
  font-size: 12px !important;
}

#current-branch {
  font-size: 15px !important;
  color: #015fc3;
}
h2 {
  line-height: 1.2 !important;
}
</style>
