<template>
  <div>
    <div v-if="!loading">
      <form v-on:submit.prevent="createBill">
        <b-row>
          <b-col cols="12" class="text-center">
            <h1 class="text-2xl font-bold mt-4">
              {{ $t('payment_confirm') }}
            </h1>
            <Seperator class="my-4 w-100" />
          </b-col>
        </b-row>
        <b-row>
          <b-col xl="4" xs="12" class="text-center">
            <h2 style="font-weight: 700; font-size: 16px !important">
              {{ $t('payment').chosen }}
            </h2>
            <PaymentDetail
              class="mb-5 mt-2 justify-self-center"
              :package="plan"
              :page="'PaymentConfirm'"
            />
          </b-col>
          <b-col xl="7" xs="12" class="d-block">
            <!-- <h2 class="my-4 lg:hidden">{{ $t("payment").billing }}</h2> -->
            <b-container fluid>
              <b-card class="xl:p-3 xl:mt-7">
                <b-row>
                  <b-col md="6" sm="12" xs="12" align-self="start" class="text-left pb-2">
                    <h1 class="my-2">{{ $t('payment').companyName }}</h1>
                    <p>{{ this.branch.company_name }}</p>
                  </b-col>

                  <b-col md="6" sm="12" xs="12" align-self="start" class="text-left pb-2">
                    <h1 class="my-2">{{ $t('payment').department }}</h1>
                    <p v-if="branch.branch_no == 0">
                      {{ $t('payment').head_office }}
                    </p>
                    <p v-else>{{ $t('payment').branch }}</p>
                  </b-col>
                  <b-col
                    md="6"
                    sm="12"
                    xs="12"
                    align-self="start"
                    class="text-left pb-2"
                    v-if="branch.branch_no != 0"
                  >
                    <h1 class="my-2">{{ $t('payment').branchNumber }}</h1>
                    <p>{{ branch.branch_no }}</p>
                  </b-col>
                  <b-col
                    md="6"
                    sm="12"
                    xs="12"
                    align-self="start"
                    class="text-left pb-2"
                    v-if="branch.branch_no != 0"
                  >
                    <h1 class="my-2">{{ $t('payment').branchName }}</h1>

                    <p>{{ getCurrent_branch.branch_name }}</p>
                  </b-col>
                  <b-col md="6" sm="12" xs="12" align-self="start" class="text-left pb-2">
                    <h1 class="my-2">{{ $t('payment').taxNumber }}</h1>
                    <p>{{ getPackagesInformation.taxNumber }}</p>
                  </b-col>
                  <b-col md="6" sm="12" xs="12" align-self="start" class="text-left pb-2">
                    <h1 class="my-2">{{ $t('payment').email }}</h1>
                    <p>{{ getPackagesInformation.email }}</p>
                  </b-col>
                  <b-col md="6" sm="12" xs="12" align-self="start" class="text-left pb-2">
                    <h1 class="my-2">{{ $t('payment').address }}</h1>
                    <p>{{ getPackagesInformation.address1 }}</p>
                  </b-col>
                  <b-col md="6" sm="12" xs="12" align-self="start" class="text-left pb-2">
                    <h1 class="my-2">{{ $t('payment').address2 }}</h1>
                    <p>{{ getPackagesInformation.address2 }}</p>
                  </b-col>

                  <b-col md="6" sm="12" xs="12" align-self="start" class="text-left pb-2">
                    <h1 class="my-2">{{ $t('payment').city }}</h1>
                    <p>{{ getPackagesInformation.city }}</p>
                  </b-col>
                  <b-col md="6" sm="12" xs="12" align-self="start" class="text-left pb-2">
                    <h1 class="my-2">{{ $t('payment').country }}</h1>
                    <p>{{ getPackagesInformation.country }}</p>
                  </b-col>

                  <b-col md="6" sm="12" xs="12" align-self="start" class="text-left pb-2">
                    <h1 class="my-2">{{ $t('payment').post }}</h1>
                    <p>{{ getPackagesInformation.postal }}</p>
                  </b-col>
                </b-row>
              </b-card>

              <b-col xl="6" cols="12" class="text-center d-xl-flex mb-3">
                <b-col cols="12" xl="6" class="text-xl-start mt-3">
                  <Button
                    :text="$t('payment').back"
                    btnColor="white-btn"
                    class="xl:w-4/5 w-full"
                    @click.native="back()"
                  />
                </b-col>
                <b-col cols="12" xl="6" class="text-xl-start my-3">
                  <Button
                    :text="$t('payment').accept"
                    btnColor="blue-btn"
                    class="xl:w-4/5 w-full"
                    type="submit"
                  />
                </b-col>
              </b-col>
            </b-container>
          </b-col>
        </b-row>
      </form>
      <Footer />
    </div>

    <div class="my-5" v-else>
      <Loading white />
    </div>
  </div>
</template>

<script>
import Seperator from '../../components/general/seperate_line.vue'
import PaymentDetail from '../../components/general/payment_detail.vue'
import Button from '../../components/general/general_button.vue'
import Loading from '../../components/general/loading.vue'
import Footer from '../../components/footer.vue'

import { mapGetters } from 'vuex'
import { find } from 'lodash'

export default {
  name: 'payment',
  components: {
    Seperator,
    PaymentDetail,
    Button,
    Footer,
    Loading,
  },
  computed: {
    ...mapGetters({
      packages: 'getPackages',
      currentPackageId: 'getPackage_id',
      branch: 'getCurrent_branch',
      user: 'getUser',
      loading: 'getLoading',
      getPackagesInformation: 'getPackagesInformation',
      getCurrent_branch: 'getCurrent_branch',
    }),
    plan: function () {
      return find(this.packages, { package_id: this.currentPackageId })
    },
  },
  data() {
    return {
      taxNumber: '',
      email: this.user.email,
      address1: '',
      address2: '',
      city: '',
      postal: '',
      country: '',
    }
  },
  async mounted() {
    if (this.currentPackageId) {
      if (this.$route.name != 'RegisterPaymentConfirm') {
        window.scrollTo(0, 0)
        const id = await localStorage.getItem('userId')
        if (!this.user && id) {
          this.$router.push('/company_select')
        } else if (!this.user && !id) {
          this.$router.push('/home')
        }
      }
    } else {
      this.$router.push('/companyselect')
    }
  },
  methods: {
    async createBill(e) {
      e.preventDefault()
      this.loading = true
      if (this.currentPackageId == 1) {
        this.$store.dispatch('trial', this.getPackagesInformation).then(() => {
          this.$router.push('/paymentsuccess/trial')
        })
      } else {
        this.$store.dispatch('createBill', this.getPackagesInformation).then(() => {
          this.$router.push('/paymentmethod')
        })
      }
    },
    back() {
      this.$router.push('/payment')
    },
  },
}
</script>

<style scoped>
.card-body h1 {
  font-size: 18px;
  color: #014083;
  font-weight: 600;
}
h2 {
  font-size: 32px;
}
p {
  font-weight: 500;
  font-size: 16px;
  color: #333333;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.white-btn {
  color: #015fc3;
  background-color: #ffffff;
}

.blue-btn {
  color: #fff;
  background-color: #007afe;
}
@media only screen and (min-width: 320px) and (max-width: 576px) {
  p {
    font-weight: 500;
    font-size: 14px;
    color: #333333;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
}
</style>