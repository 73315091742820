<template>
  <div class="headerstyle">
    <b-navbar
      :toggleable="windowWidth <= 1300"
      type="dark"
      variant="info"
      style="background-color: none !important"
      class="nav-bg px-md-5"
    >
      <b-navbar-brand href="/home"
        ><img src="../assets/material/header_logo.png" width="65" height="65" class="mx-3" alt=""
      /></b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
          <b-nav-item href="/home" class="mx-2">
            <span class="saaa" :class="{ 'span-active': isHome || isPromotion }">
              {{ $t('header.navbar.home') }}</span
            ></b-nav-item
          >
          <!-- <b-nav-item href="/home/package" class="mx-2">
            <span class="saaa"> {{ $t('header.navbar.package') }}</span>
          </b-nav-item> -->
          <b-nav-item @click="scrollPackage" class="mx-2">
            <span class="saaa"> {{ $t('header.navbar.package') }}</span>
          </b-nav-item>
          <b-nav-item href="/packages" class="mx-2">
            <span class="saaa" :class="{ 'span-active': isPackages }">
              {{ $t('header.navbar.select') }}
            </span>
          </b-nav-item>
          <b-nav-item @click="scrollTo()" class="mx-2">
            <span class="saaa">
              {{ $t('header.navbar.contact') }}
            </span>
          </b-nav-item>
        </b-navbar-nav>

        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <b-navbar-nav class="mr-3 mt-md-1">
            <b-nav-item>
              <span
                :class="{ 'text-active': !isEN }"
                class="text-unactive"
                @click="changeLocale('th')"
                >TH</span
              >
              <span class="text-white"> | </span>

              <span
                :class="{ 'text-active': isEN }"
                class="text-unactive"
                @click="changeLocale('en')"
                >EN</span
              ></b-nav-item
            >
          </b-navbar-nav>
          <b-nav-item-dropdown right v-if="user" class="white-header-user mx-3">
            <!-- Using 'button-content' slot -->
            <template #button-content>
              <span class="header-user">
                {{ user.email }}
              </span>
            </template>
            <b-dropdown-item @click="toCompanyselect" style="color: #7c7c7c">
              <span class="d-flex">
                <img
                  class="mr-3"
                  width="20"
                  height="20"
                  src="@/assets/material/all_company.svg"
                  alt="all-company"
                />

                {{ $t('header.navbar.company') }}
              </span>
            </b-dropdown-item>
            <b-dropdown-item @click="logout" style="color: #7c7c7c">
              <span class="d-flex">
                <img
                  class="mr-3"
                  width="20"
                  height="20"
                  src="@/assets/material/logout.svg"
                  alt="logout"
                />

                {{ $t('header.logout') }}
              </span>
            </b-dropdown-item>
          </b-nav-item-dropdown>

          <b-navbar-nav class="flex gap-x-2 content-center md:w-72 w-full mx-auto px-2" v-else>
            <div
              v-if="this.$route.name != 'RegisterPayment'"
              @click="openModal(false)"
              class="border border-white rounded-md mx-auto py-1 px-0.5 pointer flex my-2 w-44 h-9 lg:w-full items-center justify-content-center"
            >
              <span class="mx-2">{{ $t('header').login }}</span>
              <!-- <span @click="openModal(true)" class="ml-2 blue-box py-1 px-2">{{
                $t('header').register
              }}</span> -->
            </div>
            <div
              v-if="this.$route.name != 'RegisterPayment'"
              @click="contact = true"
              class="red-box rounded-md mx-auto py-1 px-0.5 pointer flex my-2 w-44 h-9 lg:w-full items-center justify-content-center"
            >
              <span class="mx-2 text-sm">{{ $t('header').get_demo }}</span>
            </div>
          </b-navbar-nav>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>

    <div
      class="grid place-items-center w-full md:flex justify-center items-center mt-3 px-3"
      v-if="isHome || isPromotion"
    >
      <div
        class="place-items-center grid gap-y-2 justify-center mx-3 md:w-1/3 md:place-items-start"
      >
        <h2 class="leading-10 md:text-left mb-3 lg:mb-5">
          THE <span class="font-bold">NEW WAY</span> TO VERIFY THE FIXED ASSETS
        </h2>
        <div
          class="demo_button pointer px-4 py-2 text-lg font-semibold w-64"
          :class="{ 'px-1': $i18n.locale === 'en' }"
          @click="contact = true"
          v-if="isHome || isPromotion"
        >
          {{ $t('header.get_demo') }}
        </div>
        <div
          class="started_button pointer px-4 py-2 text-lg font-semibold w-64"
          :class="{ 'px-1': $i18n.locale === 'en' }"
          @click="toSelect()"
          v-if="isHome || isPromotion"
        >
          {{ $t('header.get_started') }}
        </div>
        <!-- <a href="/home/package">
          <div
            class="package_button pointer px-4 py-2 w-64 text-lg"
            :class="{ 'px-1': $i18n.locale === 'en' }"
            v-if="isHome || isPromotion"
          >
            {{ $t('header.navbar.package') }}
          </div>
        </a> -->
      </div>
      <img
        class="object-scale-down w-full md:w-1/2 lg:w-1/2 mx-2 mt-5 verscanpic h-100"
        src="@/assets/material/header_image.png"
        alt="banner"
      />
    </div>

    <!-- Start of Register Modal -->

    <b-modal id="register" hide-header hide-footer centered no-close-on-backdrop modal-header-close>
      <div class="d-flex justify-content-end">
        <b-icon icon="x-circle" class="cursor-pointer" @click="closeModal"></b-icon>
      </div>
      <div>
        <b-tabs
          v-if="!loading"
          content-class="mt-3"
          active-nav-item-class="font-weight-bold  text-primary"
          justified
          class="my-4"
          v-model="step"
        >
          <b-tab :title="$t('register.register')">
            <form v-if="!this.loading" class="mt-4 px-2" v-on:submit.prevent="register">
              <Input
                :label="$t('register').email"
                type="email"
                v-model="email"
                required
                class="my-3"
                :autoComplete="'username'"
              />
              <Input
                :label="$t('register').tel"
                :type="'tel'"
                v-model="tel"
                :minlength="'9'"
                required
                class="my-3"
              />
              <Input
                :label="$t('register').company_name"
                v-model="companyName"
                required
                class="my-3"
                v-if="this.$route.name != 'RegisterPayment'"
              />
              <Input
                :label="$t('register').password"
                class="mt-3 mb-1"
                :type="showRegisPassword ? 'text' : 'password'"
                :pattern="'^(?=.*?[A-Za-z])(?=.*?[0-9]).{8,16}$'"
                required
                v-model="password"
                :autoComplete="'new-password'"
              >
                <template v-slot:icon>
                  <img
                    v-if="showRegisPassword"
                    @click="showRegisPassword = !showRegisPassword"
                    src="../assets/material/showpassword.png"
                    alt="showpassword"
                    class="password-toggler"
                  />
                  <img
                    v-else
                    @click="showRegisPassword = !showRegisPassword"
                    src="../assets/material/hidepassword.png"
                    alt="showpassword"
                    class="password-toggler"
                  />
                </template>
              </Input>
              <p
                class="warning"
                v-if="
                  !RegExp('^(?=.*?[A-Za-z])(?=.*?[0-9]).{8,16}').test(password) && this.password
                "
              >
                {{ $t('header').pass_condition }}
              </p>
              <Input
                :label="$t('register').confirm"
                class="mt-3 mb-1"
                :type="showConfirmPassword ? 'text' : 'password'"
                required
                v-model="confirm"
                :pattern="'^(?=.*?[A-Za-z])(?=.*?[0-9]).{8,16}$'"
              >
                <template v-slot:icon>
                  <img
                    v-if="showConfirmPassword"
                    @click="showConfirmPassword = !showConfirmPassword"
                    src="../assets/material/showpassword.png"
                    alt="showpassword"
                    class="password-toggler"
                  />
                  <img
                    v-else
                    @click="showConfirmPassword = !showConfirmPassword"
                    src="../assets/material/hidepassword.png"
                    alt="showpassword"
                    class="password-toggler"
                  /> </template
              ></Input>
              <p class="warning" v-if="this.confirm && !(this.password == this.confirm)">
                {{ $t('header').pass_confirm }}
              </p>
              <div class="d-flex align-items-center my-2">
                <input
                  class="mr-3"
                  type="checkbox"
                  id="accept"
                  name="accept"
                  v-model="acceptTerms"
                />
                <p v-if="$i18n.locale === 'th'">
                  ฉันยอมรับ
                  <u style="color: #015fc3; cursor: pointer" @click="goToPolicy"
                    >นโยบายความเป็นส่วนตัว</u
                  >
                  และ
                  <u style="color: #015fc3; cursor: pointer" @click="goToDataDeletion"
                    >การลบข้อมูล</u
                  >
                </p>
                <p v-else>
                  I agree to VERSCAN's
                  <u style="color: #015fc3; cursor: pointer" @click="goToPolicy">Privacy Policy</u>
                  and
                  <u style="color: #015fc3; cursor: pointer" @click="goToDataDeletion"
                    >Data Deletion.</u
                  >
                </p>
              </div>
              <b-col cols="12" class="text-center mt-3">
                <Button
                  btnColor="gradient-btn"
                  :text="$t('register').register"
                  :disabled="!acceptTerms || onSummit"
                  class="pointer w-100 mt-3"
                  type="submit"
                />
              </b-col>
            </form>
          </b-tab>
          <b-tab :title="$t('login.login')">
            <form v-if="!this.loading" class="mt-4 px-2" @submit.prevent="login">
              <Input
                :label="$t('login').email"
                type="email"
                v-model="email"
                class="lg:px-5 my-3"
                :autoComplete="'username'"
              />

              <Input
                :label="$t('login').password"
                :type="showPassword ? 'text' : 'password'"
                v-model="password"
                class="lg:px-5 my-3"
                :autoComplete="'password'"
              >
                <template v-slot:icon>
                  <img
                    v-if="showPassword"
                    @click="showPassword = !showPassword"
                    src="../assets/material/showpassword.png"
                    alt="showpassword"
                    class="password-toggler"
                  />
                  <img
                    v-else
                    @click="showPassword = !showPassword"
                    src="../assets/material/hidepassword.png"
                    alt="showpassword"
                    class="password-toggler"
                  />
                </template>
              </Input>
              <b-col cols="12" class="text-center lg:px-5">
                <Button
                  btnColor="gradient-btn"
                  :text="$t('login').login"
                  type="submit"
                  class="mt-3 w-100"
                />
              </b-col>
            </form>
            <b-button variant="link" class="mt-3 w-full" @click="toForget()">
              {{ $t('login').forget }}
            </b-button>
          </b-tab>
        </b-tabs>
        <div class="loading flex" v-else>
          <Loading class="m-auto" />
        </div>
      </div>
    </b-modal>

    <!-- End of Register Modal -->

    <!-- Start of Contact us Modal -->
    <b-modal
      v-model="contact"
      hide-footer
      hide-header
      centered
      :no-close-on-backdrop="loading"
      id="contact-us"
    >
      <div class="w-full grid justify-items-center" v-if="!loading">
        <img
          src="@/assets/material/cross.svg"
          class="justify-self-end pointer"
          @click="contact = !contact"
          alt="cross"
        />
        <h1 class="modal_header" style="color: #015fc3; font-size: 19px">
          {{ $t('header.contact_demo') }}
        </h1>
        <img class="my-4" src="@/assets/material/contact_us.png" alt="" />

        <form
          class="w-full px-3 my-4 gap-y-2 grid justify-items-center"
          v-on:submit.prevent="contactUs"
        >
          <Input required :label="$t('packages').name" class="w-full" v-model="contactForm.name" />
          <Input
            required
            :label="$t('register').email"
            class="w-full"
            type="email"
            placeholder="example@mail.com"
            v-model="contactForm.email"
          />
          <Input
            required
            :label="$t('register').tel"
            class="w-full"
            type="tel"
            v-model="contactForm.phone"
          />
          <Input
            required
            :label="$t('register').company_name"
            class="w-full"
            v-model="contactForm.company_name"
          />
          <div class="w-full">
            <p
              class="text-left justify-self-start"
              style="font-weight: bold; color: #015fc3; font-size: 16px"
            >
              {{ $t('header').date_demo }}<span style="color: red">*</span>
            </p>
            <DatePicker
              class="w-full"
              type="dateTime"
              :min_date="moment().add(7, 'd').format()"
              :req="true"
              :validHours="{ min: 10, max: 16 }"
              :disabledDates="{ weekdays: [1, 7] }"
              :minuteIncrement="30"
              @changeDate="($event) => (contactForm.demo_date = $event)"
            />
            <input
              type="datetime"
              id="demo_date"
              required
              v-model="contactForm.demo_date"
              style="display: none"
            />
          </div>
          <hr class="my-3 w-full" />
          <Input
            :label="$t('header').bussiness"
            v-model="contactForm.type_business"
            class="w-full"
            :placeholder="$t('header').bussiness_eg"
          />
          <p class="text-left justify-self-start">
            <span style="font-weight: bold; color: #015fc3; font-size: 16px">
              {{ $t('header').asset }}</span
            >
            {{ $t('header').more_than_one }}
          </p>

          <b-form-checkbox-group class="justify-self-start" v-model="contactForm.type_asset">
            <div
              class="justify-self-start"
              v-for="(header, index) in $t('header').checkbox"
              :key="index"
            >
              <b-form-checkbox class="justify-self-start" :value="header">
                <p class="text-blue pl-1">{{ header }}</p>
              </b-form-checkbox>
            </div>
          </b-form-checkbox-group>

          <p
            class="text-left justify-self-start"
            style="font-weight: bold; color: #015fc3; font-size: 16px"
          >
            {{ $t('header').amount }}
          </p>
          <b-form-radio-group class="justify-self-start" v-model="contactForm.amount_asset">
            <div v-for="(header, index) in $t('header').radio" :key="index">
              <b-form-radio class="justify-self-start" :value="header">
                <p class="text-blue pl-1">{{ header }}</p>
              </b-form-radio>
            </div>
          </b-form-radio-group>

          <p
            class="text-left justify-self-start"
            style="font-weight: bold; color: #015fc3; font-size: 16px"
          >
            {{ $t('header').date }}
          </p>

          <DatePicker
            :min_date="new Date()"
            class="w-full"
            @changeDate="($event) => (contactForm.start = $event)"
          />
          <div class="w-full">
            <label class="flex text-gray-50 font-bold mb-1 pr-4">
              {{ $t('packages').message }}
            </label>
            <textarea
              class="py-1 flex w-full px-2 text-dark"
              rows="4"
              cols="50"
              v-model="contactForm.message"
              :placeholder="$t('header').message_eg"
            />
          </div>
          <Button
            :text="$t('packages').send"
            btnColor="gradient-btn"
            class="w-1/2 mt-4 self-center"
            type="submit"
          />
        </form>
      </div>

      <div v-else class="grid p-3 justify-center">
        <Loading />
      </div>
    </b-modal>
    <!-- End of Contact us Modal -->
  </div>
</template>

<script>
import moment from 'moment'
import { mapGetters } from 'vuex'
import i18n from '@/plugins/i18n'
import { mapMutations } from 'vuex'
import Input from './general/modal_input.vue'
import Button from '../components/general/general_button.vue'
import DatePicker from './general/datepicker.vue'
import Loading from './general/loading.vue'
import { sameAs } from 'vuelidate/lib/validators'
import Swal from 'sweetalert2'

export default {
  data() {
    return {
      onSummit: false,
      windowWidth: null,
      showPassword: false,
      showRegisPassword: false,
      showConfirmPassword: false,
      email: '',
      password: '',
      confirm: '',
      acceptTerms: false,
      tel: '',
      bussiness: '',
      companyName: '',
      step: 0,
      isMobile: false,
      contact: false,
      contactForm: {
        name: '',
        email: '',
        phone: '',
        company_name: '',
        type_business: '',
        type_asset: [],
        amount_asset: '',
        start: '',
        message: '',
        demo_date: '',
      },
    }
  },

  computed: {
    ...mapGetters({
      user: 'getUser',
      loading: 'getLoading',
      selected_package: 'getSelected_package',
      company: 'getCompany',
      getToken: 'getToken',
      locale: 'locale',
      altBill: 'getAltBill',
    }),
    LogoutText() {
      if (this.$i18n.locale === 'en') {
        return 'Logout'
      }
      return 'ออกจากระบบ'
    },
    isHome() {
      return this.$route.name === 'Home' && !this.isPromotion
    },
    isPromotion() {
      return this.$route.path === '/home/package'
    },
    isPackages() {
      return this.$route.path === '/packages'
    },
    isEN() {
      return this.$i18n.locale === 'en'
    },
  },
  mounted() {
    this.isMobile = window.screen.width <= 430
    i18n.locale = this.locale
    this.onResize()
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },

  methods: {
    moment,
    ...mapMutations({
      selectPackage: 'selected_packageMutations',
    }),
    goToPolicy() {
      const route = this.$router.resolve({ name: 'Policy' })
      window.open(route.href, '_blank')
    },
    goToDataDeletion() {
      const route = this.$router.resolve({ name: 'DataDelete' })
      window.open(route.href, '_blank')
    },
    onResize() {
      this.windowWidth = window.innerWidth
    },
    changeLocale(locale) {
      i18n.locale = locale
      this.$store.commit('changeLocaleMutation', locale)
    },

    toPromotion() {
      this.$router.push('/')
    },
    scrollPackage() {
      if (window.location.pathname === '/') {
        const element = document.getElementById('header')
        window.scrollTo(0, element.offsetTop, { behavior: 'smooth' })
      } else {
        this.$router.push({ path: '/' }).then(async () => {
          const imagesArray = Array.from(document.querySelectorAll('img'))

          await Promise.all(
            imagesArray.map((img) => {
              if (img.complete) {
                return Promise.resolve(img)
              }
              return new Promise((resolve, reject) => {
                img.addEventListener('load', resolve)
                img.addEventListener('error', reject)
              })
            })
          )

          const element = document.getElementById('header')
          window.scrollTo(0, element.offsetTop, { behavior: 'smooth' })

          // const checkAllImagesLoaded = setInterval(() => {
          //   let allImagesLoaded = true
          //   //If the current image is not fully loaded (false), then allImagesLoaded is set to false.
          //   imagesArray.forEach((img) => {
          //     if (!img.complete) {
          //       allImagesLoaded = false
          //     }
          //   })
          //   //If allImagesLoaded is true, then the interval is cleared with clearInterval(checkIfAllImagesLoaded) and the scrolling is performed.
          //   if (allImagesLoaded) {
          //     clearInterval(checkAllImagesLoaded)
          //     this.$nextTick(() => {
          //       const packageSection = document.getElementById('header')
          //       packageSection.scrollIntoView({ behavior: 'smooth' })
          //     })
          //   }
          // }, 100)
        })
        // this.$router.push('/').then(() => {
        //   const element = document.getElementById('header')
        //   element.scrollIntoView({ behavior: 'smooth' });
        // })
      }
    },
    toSelect() {
      this.selectPackage(0)
      this.$router.push('/packages')
    },
    async login(e) {
      e.preventDefault()
      if (this.$route.name == 'RegisterPayment') {
        await this.$store
          .dispatch('altLogin', {
            email: this.email,
            password: this.password,
            contact_no: this.tel,
            company_name: this.altBill.company_name,
            bill_detail: {
              address1: this.altBill.bill_detail.address1,
              address2: this.altBill.bill_detail.address2,
              city: this.altBill.bill_detail.city,
              country: this.altBill.bill_detail.country,
              email: this.email,
              postal: this.altBill.bill_detail.postal,
              taxNumber: this.altBill.bill_detail.taxNumber,
            },
          })
          .then(() => {
            this.$router.push('/paymentConfirm')
          })
      } else {
        await this.$store.dispatch('login', {
          email: this.email.toLowerCase(),
          password: this.password,
          isCompanySelect: true,
        })
        if (this.getToken !== '') {
          this.$router.push('/companyselect')
        }
      }
    },
    async register(e) {
      e.preventDefault()
      let email_lower = this.email.toLowerCase()
      this.onSummit = true
      if (this.acceptTerms && !email_lower.includes('@jagota')) {
        if (this.$route.name == 'RegisterPayment') {
          this.$store
            .dispatch('altRegister', {
              email: this.email,
              password: this.password,
              contact_no: this.tel,
              company_name: this.altBill.company_name,
              bill_detail: {
                address1: this.altBill.bill_detail.address1,
                address2: this.altBill.bill_detail.address2,
                city: this.altBill.bill_detail.city,
                country: this.altBill.bill_detail.country,
                email: this.email,
                postal: this.altBill.bill_detail.postal,
                taxNumber: this.altBill.bill_detail.taxNumber,
              },
            })
            .then((res) => {
              if (res) {
                this.$router.push('/paymentConfirm')
              }
            })
            .catch((err) => {
              this.onSummit = false
              console.log('err->', err)
            })
        } else {
          await this.$store.dispatch('register', {
            email: this.email.toLowerCase(),
            password: this.password,
            contact_no: this.tel,
            company_name: this.companyName,
          })
        }
      } else {
        this.onSummit = false
      }
      if (email_lower.includes('@jagota')) {
        await Swal.fire({
          icon: 'warning',
          title: this.$t('register').error_jagota.header,
          text: this.$t('register').error_jagota.detail,
          confirmButtonText: this.$t('packages').quotation_confirm,
          confirmButtonColor: '#007AFE',
        })
      }
      this.onSummit = false
    },
    async contactUs() {
      this.contactForm.type_asset = this.contactForm.type_asset.join(', ')
      this.$store.dispatch('contactUs', this.contactForm).then((res) => {
        if (res) {
          this.contactForm = {
            name: '',
            email: '',
            phone: '',
            company_name: '',
            type_business: '',
            type_asset: [],
            amount_asset: '',
            start: '',
            message: '',
          }
          this.contact = false
        } else {
          this.contactForm.type_asset = []
        }
      })
    },
    scrollTo() {
      window.scrollTo({
        left: 0,
        top: document.body.scrollHeight,
        behavior: 'smooth',
      })
    },
    logout() {
      this.$store.dispatch('logout')
    },
    toHome() {
      this.$store.commit('package_idMutations', false)
      this.$router.push('/home')
    },
    toCompanyselect() {
      this.$store.commit('package_idMutations', false)
      this.$router.push('/companyselect')
    },
    openModal(register) {
      if (this.getToken === '') {
        this.$store.commit('loadingMutations', false)
      }
      if (register) {
        this.step = 0
      } else {
        this.step = 1
      }
      this.$root.$emit('bv::show::modal', 'register')
    },
    closeModal() {
      this.$root.$emit('bv::hide::modal', 'register')
    },
    toForget() {
      this.closeModal()
      this.$router.push('/forgetpassword')
    },
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },

  components: {
    Input,
    Button,
    Loading,
    DatePicker,
  },
  validations: {
    confirm: {
      sameAsPassword: sameAs('password'),
    },
  },
  watch: {
    user(newValue) {
      if (newValue) {
        this.closeModal()
        this.email = ''
        this.password = ''
        this.confirm = ''
        this.tel = ''
        this.companyName = ''
        this.step = 0
      }
    },
    password() {
      if (this.password) {
        this.password = this.password.trimRight()
        this.password = this.password.trimLeft()
      }
    },
    confirm() {
      if (this.confirm) {
        this.confirm = this.confirm.trimRight()
        this.confirm = this.confirm.trimLeft()
      }
    },
  },
}
</script>

<style scoped>
textarea {
  background: #ffffff;
  border: 1px solid #015fc3;
  box-sizing: border-box;
  border-radius: 15px;
  color: #777;
}
label {
  color: #015fc3;
}
.verscanpic {
  transform: translateX(3%);
}
.started_button {
  background-color: none;
  border-radius: 15px;
  border-color: #ffffff;
  border-width: 2px;
  color: white;
}
.demo_button {
  background: linear-gradient(269.79deg, #ff9797 0.23%, #ff7b7b 99.89%) padding-box,
    linear-gradient(269.79deg, #ff7b7b, #ff9797) border-box;
  border: 2px solid transparent;
  border-radius: 15px;
  color: white;
}
.package_button {
  background-color: none;
  border-radius: 15px;
  border-color: #ffffff;
  border-width: 2px;
  color: white;
}

.headerstyle {
  color: white;

  background-image: url('../assets/material/header.png');
}

.white-box {
  background: #ffffff;
  border-radius: 5px;
  color: #007afe;
}

.red-box {
  background: linear-gradient(269.79deg, #ff9797 0.23%, #ff7b7b 99.89%) padding-box,
    linear-gradient(269.79deg, #ff7b7b, #ff9797) border-box;
  border: 1px solid transparent;
  border-radius: 5px;
  color: #ffffff;
}

.language {
  color: #7c7c7c;
  background: #ffffff;
  border-radius: 20px;
}

.blue-box {
  background: #007afe;
  border-radius: 7px;
  color: white;
}
.warning {
  color: red;
  font-weight: 300;
  font-size: 11px;
}

.name-toolong-one-line {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 300px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .started_button {
    background-color: none;
    border-radius: 15px;
    border-color: #ffffff;
    border-width: 2px;
    color: white;
    bottom: 7%;
  }
}

.password-toggler {
  position: absolute;
  width: 15px;
  height: auto;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}

@media only screen and (min-width: 431px) and (max-width: 494px) {
  .name-toolong-one-line {
    overflow: hidden;
    text-overflow: ellipsis;
    width: 200px;
  }
}
@media only screen and (min-width: 401px) and (max-width: 430px) {
  .name-toolong-one-line {
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100px;
  }
  .started_button {
    background-color: none;
    border-radius: 15px;
    border-color: #ffffff;
    border-width: 2px;
    color: white;
    z-index: 100;

    bottom: 34.5% !important;
  }
  .headerstyle_2 {
    height: 90px !important;
    background-color: #f7ffff;
    color: #165e99;
  }
}
@media only screen and (min-width: 375px) and (max-width: 400px) {
  .name-toolong-one-line {
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100px;
  }
  .headerstyle_2 {
    height: 90px !important;
    background-color: #f7ffff;
    color: #165e99;
  }
  .started_button {
    background-color: none;
    border-radius: 15px;
    border-color: #ffffff;
    border-width: 2px;
    color: white;
    z-index: 100;

    bottom: 33% !important;
  }
}
@media only screen and (min-width: 350px) and (max-width: 374px) {
  .name-toolong-one-line {
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100px;
  }
  .started_button {
    background-color: none;
    border-radius: 15px;
    border-color: #ffffff;
    border-width: 2px;
    color: white;
    z-index: 100;

    bottom: 32% !important;
  }
}

@media only screen and (min-width: 320px) and (max-width: 349px) {
  .name-toolong-one-line {
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100px;
  }
  .started_button {
    background-color: none;
    border-radius: 15px;
    border-color: #ffffff;
    border-width: 2px;
    color: white;
    bottom: 29% !important;
  }
}
</style>
<style>
.nav-link {
  display: block;
  padding: 0.5rem 1rem;
  color: black !important;
  font-size: 19px;
  /* text-decoration: none; */
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}
.saaa {
  color: white !important;
}
.saaa:hover {
  text-decoration: underline;
}
.nav-link.active {
  color: #007afe !important;
}
.bg-info {
  --bs-bg-opacity: 0 !important;
}
.nav-bg {
  /* background: none; */
  background-image: url('../assets/material/header.png');
}
.text-unactive {
  color: #c6c6c6;
  text-decoration: none;
}
.text-active {
  color: white;
  text-decoration: underline;
}
.white-header-user {
  background: white;
  border-radius: 5px;
}
.header-user {
  color: #007afe !important;
}
.navbar-expand-lg .navbar-nav .dropdown-menu {
  right: 0px;
}
.nav-link .nav-item a span:hover {
  text-decoration: underline;
}
.nav-link .span-active {
  text-decoration: underline;
}

.navbar-toggler {
  margin-right: 1rem;
}
</style>
