<template>
  <div class="data-delete">
    <div class="container">
      <div class="info">
        <h1 class="text-center py-4 font-bold">{{ $t('data_deletion.header') }}</h1>
        <b>{{ $t('data_deletion.sub_header') }}</b>
        <ul>
          <li
            v-html="detail"
            v-for="(detail, index) in $t('data_deletion').detail"
            :key="index"
          ></li>
        </ul>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Footer from '../../components/footer.vue'
export default {
  components: {
    Footer,
  },
}
</script>

<style scoped>
h1 {
  font-size: 28px;
  color: #000;
}

b {
  display: block;
  margin-bottom: 1rem;
  color: #000;
}

p {
  margin-bottom: 1rem;
  font-weight: 300;
  color: #000;
}

* >>> a {
  color: #007bff;
}

ul {
  list-style-type: disc;
  margin-bottom: 1rem;
  margin-left: 1rem;
  color: #000;
  font-weight: 300;
}

li {
  margin-left: 1rem;
}

.data-delete {
  text-align: left;
  background-color: white;
}

.info {
  margin: 0 40px 100px 40px;
  min-height: 70vh;
}
</style>
