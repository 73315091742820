/* eslint-disable no-dupe-keys */
import Vue from 'vue'
import VueI18n from 'vue-i18n'
Vue.use(VueI18n, { seo: true })
let messages = {
  en: {
    home: {
      or: 'or',
      turtorial: {
        steps: 'Application process for VERSCAN system',
        one: {
          1: 'Register',
          2: 'to access the system',
        },
        two: {
          1: 'Choose a package',
          2: 'according to the amount of ',
          3: 'assets in your organization',
          4: '**or try the free trial for 30 days',
        },
        three: {
          1: 'Start the system',
          2: 'We will send you a QR Code',
          3: 'within 2 days',
        },
      },
      section2: {
        1: 'An application for counting assets conveniently, quickly, and easily by scanning QR codes.',
        2: 'Helps your organization reducing costs and managing the fixed assets easier. Reduces physical counting errors and be able to track the results effectively. ',
        3: 'Reduces physical counting errors and be able to track the results effectively. ',
      },
      section3: {
        header: 'How will VERSCAN solve these problems?',
        solutions: [
          'Instantly reduce the cost and resources required for physical counting.',
          'Never fail to count 100% of all assets ',
          'Able to count assets and view the counting results in real-time, by just connecting your smartphone to the internet',
          'The application can be used offline even without internet connection.',
          'Able to save data from various programs into the application. It is safe to use by restricting person access. Therefore, general staffs or third parties will not have access to important company information.',
          'Counting report format is easy to understand',
          'Our services are linked to QR codes, which we can produce QR codes on any materials to suit all usage conditions.',
        ],
      },

      section4: {
        header: 'With many interesting features',
        features: [
          'Identifying the current conditions of the fixed assets.',
          'Saving the image of the fixed assets.',
          'Reporting lost asset or asset repair.',
          'Locating the location of the asset according to the building plan.',
          'Authorized persons can certify the counting report through the application.',
          'Alerts on next fixed asset counting date and has an AI system that helps estimating the time which required for real-time counting',
        ],
        bubble: 'bubble_en.svg',
      },
      section5: {
        header:
          'In each verify fixed assets, Has your organization ever experienced these problems?',
        problems: [
          'Takes a lot of cost, people, time and paper.',
          'Difficult to keep and collect information because it is written by hand.',
          'Missed in physical counting or asset was losts.',
          'Takes a long time to find assets and identify them.',
          'The asset registration form is difficult to understand which makes it difficult to count.',
          'Leaked counting data because the system is not secure enough',
          'The paper or material which used to identify the asset is not durable, causes an error in identifying the asset.',
        ],
      },
      section6: {
        header: 'Why VERSCAN?',
        efficient:
          'VERSCAN breaks traditional asset counting rules. Creates a new experience which is convenient, fast, safe and has a systematic workflow which helps reduce costs and errors from manual counting.',
        privacy:
          'We have a system to restrict access to information between general staffs, executives or persons responsible for financial information, such as the head of the accounting department, etc.',
        storage:
          'VERSCAN links data in digital form. The problem of saving data in paper form will be gone.You can choose to store the data on your organization’s server. ',
        easy: 'VERSCAN can be used to count assets within 3 steps in a short time and has various features which are easy to use and clear.',
      },
      customer: 'Our Customer',
      section7: {
        header: 'Custom Services for VERSCAN',
        services: [
          'A <span style="color:#0158b6;">QR code</span> preparation service on all materials that fits your assets, which is ready to be delivered to your hand. In order to be last longer, whether it is waterproof paper, plastic coating, steel sheet, etc.',
          'Teaching service on basics using both <span style="color:#0158b6;">on-site</span> and <span style="color:#0158b6;">online</span>.',
          'A service team <span style="color:#0158b6;">installing QR code</span> to identify assets.',
          'A <span style="color:#0158b6;">support team</span> which you can contact to all the time when you have questions or problems in using the application',
          'Being able to <span style="color:#0158b6;">connect to the information</span> system with your current ERP system',
          'Being able to add <span style="color:#0158b6;">other related features</span> by the software developer team to support your business purpose',
        ],
      },
      section8: {
        contacts: [
          'verscan@houseofdev.tech | www.houseofdev.tech ',
          'Tel : 02-0777833',
          'A service team installing QR code to identify assets.',
          'House of Dev Technology Co., Ltd. 15 Rajanakarn Building, 12B Floor, Soi Pradipat 17,Pradipat Road, Phayathai, Phayathai, Bangkok, 10400',
        ],
      },
    },
    future_feature: {
      header: 'Upcoming features',
      offline: 'Support offline mode',
      dashboard: 'Consolidated dashboard of a parent and its subsidiaries for executives',
      repair: 'Maintainance form system for maintenance officer',
      audit: 'Auditing function',
    },
    header: {
      register: 'Sign up',
      login: 'Login',
      get_demo: 'Get Demo',
      contact_demo: 'Get Demo',
      date_demo: 'Demo Date & Time',
      logout: 'Logout',
      th: 'TH',
      en: 'EN',
      pass_condition: 'Password must be 8-16 characters and contain both numbers and letters',
      pass_confirm: 'Confirm password not match',
      get_started: 'Free Trial',
      navbar: {
        home: 'Home',
        package: 'Packages & Promotion',
        select: 'Pricing',
        company: 'Company',
        contact: 'Contact Us',
      },
      bussiness: 'Type of Business',
      asset: 'Type of Assets',
      more_than_one: '(more than 1 option can be selected)',
      checkbox: [
        'Machine',
        'Office equipment',
        'Furnitures',
        'Vehicles',
        'Utility systems',
        'Spare parts',
        'Others',
      ],
      amount: 'Amount of Assets',
      radio: [
        'Less than 200 pieces',
        '200 - 1,700 pieces',
        '1,701 - 3,200 pieces',
        '3,201 - 5,000 pieces',
        'More than 5,000 pieces',
      ],
      date: 'Estimated Start Date ',
      bussiness_eg: 'e.g. Corporation, State Enterprise',
      message_eg: 'e.g. I want a QR Code that has heat resistant more than 60 °C.',
      received: 'We have received your information',
    },
    login: {
      forget: 'Forget password',
      email: 'Email',
      password: 'Password',
      login: 'Login',
      register: 'Register',
    },
    register: {
      register: 'Register',
      next: 'Next',
      email: 'Email',
      password: 'Password',
      confirm: 'Confirm password',
      tel: 'Tel.',
      login: 'Login',
      company_name: 'Company Name',
      success: 'Signed up successfully',
      error_jagota: {
        header: 'Cannot register!',
        detail: 'Please contact the participator to get the invitation.',
      },
    },
    company_select: {
      type_del: 'Please type "Delete"',
      exp: 'exp.',
      pay: 'Please select the package',
      select: 'Select your company',
      add: 'Add your company',
      edit: 'Edit your company',
      department: 'Department',
      btnAdd: 'Add',
      btnEdit: 'Edit',
      btnSave: 'Save',
      name: 'Company Name',
      type: 'Type',
      hq: 'Head Office',
      branch: 'Branch',
      branchNumber: 'Branch number',
      branchName: 'Branch name',
      addCompany: 'Add Company',
      deleting: 'You are deleting your bussiness information.',
      afterDelete:
        'After you type "Delete" and confirm to delete. The data will be permanently deleted and this action cannot be undone.',
      confirmDelete: 'Type "Delete" to confirm',
      cancelDelete: 'Cancel',
      delete: 'Delete',
      pending: 'QR code and a receipt will send to you within one to two working days ',
    },
    package: {
      year: 'Yearly or 12 months',
      buy: 'Buy',
      trial: 'Free trial 7 days',
      seeLess: 'See Less',
      seeMore: 'See more',
      activating: 'This package is activating',
      change: 'Change Plan',
      cancel: 'cancel subscription',
      price: 'price',
      thb: 'THB',
      deleting: 'You are deleting your bussiness information.',
      afterDelete:
        'After you type "delete" and confirm to delete. THe data will be permanently deleted and this action cannot be undone. Type "Delete" to confirm',
      cancel_delete: 'Cancel',
      delete: 'Delete',
    },
    payment: {
      information: 'Payment information and Shipping address',
      chosen: 'Selected Package',
      billing: 'Billing information ',
      companyName: 'Company Name',
      type: 'Type',
      department: 'Department',
      hq: 'Head Office',
      branch: 'Branch',
      branchNumber: 'Branch Number',
      branchName: 'Branch Name',
      address: 'Address',
      address2: 'Additional Address',
      city: 'City',
      post: 'Postal Code',
      country: 'Country',
      taxNumber: 'Taxpayer Identification Number',
      email: 'Email',
      back: 'Back',
      next: 'Next',
      head_office: 'Head Office',
      branch: 'Branch',
      accept: 'Confirm',
      assets: 'Assets',
      exist: 'This company is already exist.',
    },
    payment_detail: {
      packageName: 'Package',
      price: 'Price',
      discount: 'discount',
      vat: 'VAT',
      total: 'Total',
      wht: 'Withholding Tax',
      payment_detail: 'Payment detail',
      vat_include: '* This price is VAT included.',
    },
    payment_method: {
      method: 'Choose the payment method',
      back: 'Back',
      select: 'Credit card / Debit card / PromptPay  and the others',
      bank: 'Bank Transfer',
    },
    package_detail: {
      package_detail: 'Package detail',
      package_size: 'Package size',
      payment_plan: 'Package duration',
      monthly_price: 'Average monthly price ',
    },

    bank_transfer: {
      account: 'Payment Account',
      confirm: 'Payment Slip Upload',
      kasikorn: 'Kasikorn Bank',
      hod: 'Account Name: House of Dev Technology Co., Ltd.',
      hod_2: '(บจก. เฮาส์ออฟเดฟ เทคโนโลยี)',
      order: 'Order No.',
      amount: 'Amount',
      time: 'Date',
      bank: 'Bank',
      evidence: 'Transfer slip',
      upload: 'Upload your transfer slip here',
      bankNum: 'Account Number',
    },
    forget_password: {
      header: 'Forgot Password',
      subHeader: 'Please enter your email to recieve password reset link',
      email: 'Email',
      request: 'Email me',
      back: 'Back to Home page',
      finish: 'The email has been sent successfully',
    },
    change_password: {
      password: 'Password',
      confirm: 'Confirm Password',
      change: 'Change Password',
      create: 'Create Password',
      save: 'Save',
      not_match: "Passwords Don't Match",
    },
    promotion: {
      package_promotion: 'Package & Promotion',
      free_trial: 'Free trial',
      s: 'S',
      m: 'M',
      l: 'L',
      package: 'Package',
      unlimit_user: 'Unlimited user accounts',
      free_stricker_40: 'Free 40 pieces of QR code stickers',
      free_stricker_300: 'Free 300 pieces of QR code stickers',
      piece: 'pieces',
      amount_asset: 'Amount of assets',
      trial: 'Free trial',
      select: 'Choose package',
      free: 'Free',
      start: 'Start from',
      year: 'year',
      month: 'month',
      free_duration: 'duration 30 days',
      s_duration: '*annually 8,980 THB/company',
      m_duration: '*annually 17,880 THB/company',
      l_duration: '*annually 22,780 THB/company',
      next_year: 'Next year 665 / month',
      pay_system: 'Billed annually only 7,980 THB/company',
      pay_system_2: '7,980 THB/company',
      scale: 'VERSCAN scale with your fixed assets',
      get_price: 'Get Price',
      date: '(Until 31 Jan 2022)',
    },
    feature: {
      features: 'All Features',
      unlimit_user: 'Unlimited user accounts',
      free_stricker_300: 'Free 300 pieces of QR code stickers',
      offline: 'รองรับการทำงานออฟไลน์',
      free_service_support: 'ฟรีบริการสอนการใช้งานเบื้องต้นและนำเข้าข้อมูล',
      photo_asset: 'ถ่ายรูปสภาพทรัพย์สินปัจจุบัน',
      scan_asset: 'แสกนตรวจนับทรัพย์สิน',
      dashboard: 'แดชบอร์ดสรุปภาพรวม',
      summary_asset: 'สรุปรายงานการตรวจนับ',
    },
    interesting_feature: 'Interesting Features',
    packages: {
      loading: 'Loading…Please wait',
      preparation:
        'QR-Code preparation service on various materials such as waterproof paper, plastic coating, metal sheet, etc.',
      installation: 'QR-Code installation team service to identify assets',
      data_transfer: 'Data transfer service from the former organization’s ERP system ',
      additional: 'Service for additional features related to meet the needs of your organization',
      choose_amount: 'Scale your company assets',
      name: 'Full Name',
      choose_duration: 'Package duration',
      calculate_price: 'Average monthly price',
      paid_annaully: '(paid annually)',
      pay_annaully: 'Pay annually fee of ',
      pay_twoyear: 'Pay two years fee of ',
      next_year: '*Next year, billed annually only 7,980 THB/year ',
      average_pay: '(Average 665 THB/month)',
      get_quotation: 'Get Quotation',
      quotation_remark: '* This price is VAT included.',
      quotation_email: 'We have sent you a quotation by email.',
      quotation_confirm: 'OK',
      quotation_download: 'You can check and download the quotation at your email. Thank you.',
      quotation_company: 'House of Dev Technology Co., Ltd. (Head Office)',
      quotation_company_address: '15 Rajanakarn Building, 12B Floor, Soi Pradipat 17, Pradipat Road, Phayathai, Phayathai, Bangkok, 10400, Thailand',
      product_unit: {
        package: 'package',
        qr: 'piece',
        year: 'year',
      },
      quotation_modal: {
        modal_text: 'Please enter information to request a quote.',
        company_name: 'Company Name',
        branch: 'Department',
        branch_item: [
          {
            text: 'Head Office',
            value: 'headOffice',
          },
          {
            text: 'Branch',
            value: 'branch',
          },
        ],
        branch_display: 'Head Office',
        branch_number: 'Branch Number',
        branch_name: 'Branch Name',
        company_address: 'Address 1',
        company_address2: 'Address 2 (Optional)',
        company_city: 'City',
        company_country: 'Country',
        company_postcode: 'Postal Code',
        company_taxid: 'Tax ID',
        company_tel: 'Tel.',
        company_email: 'Email',
        same_email: 'Use the same email',
        company_receive: 'Email for Receiving Quotation',
        send: 'Send Quotation',
      },
      checkout: 'Get Started',
      one_year: '1 Year',
      two_years: '2 Years (5% Discount)',
      price: 'price',
      per_month: 'THB/month',
      per_year: ' THB/Year',
      baht: ' THB',
      paying: 'Advance paying',
      extra_service: 'Extra services',
      optional: '(optional)',
      want_extra: 'I want extra services',
      contact_us: "Let's get started!",
      contact_info: 'Contact Information',
      package: 'Package size',
      package_info: 'Package Information',
      assets: 'Amount of assets',
      message: 'Message',
      send: 'Send',
      assets_amount: 'Amount of assets',
      already_sent: 'We have already recieved the message.',
      please_check: 'Please check our replies within 24 hours.',
      assets: 'assets',
      contact_now: 'Contact us',
      first: '(Billed annually per company)',
      second: '(Billed for 2 years per company)',
      get: 'What you’ll get',
      get_amount: ' of QR Code',
      get_assets: 'Pieces',
      get_collab: 'for using with VERSCAN',
      get_duration: 'System Usage',
      get_feature: 'All feature include',
      free_day: '30 Days',
      upgrade: 'Upgrade Package',
    },
    footer: {
      verscan: 'VERSCAN',
      assets_systems: 'The easiest way to verify your fixed assets.',
      get_the_app: 'Get the App',
      contact_us: 'Contact Us',
      email: 'info@houseofdev.tech',
      tel: '09 9414 6776',
      tel2: 'Tel. 02 096 2959',
      open: 'Office Hours: 09:00 am. - 5:30 pm. Monday to Friday',
      hod: 'House of Dev Technology Co., Ltd. <nobr>(Head Office)</nobr>',
      hod_quotation: 'House of Dev Technology Co., Ltd. (Head Office)',
      address:
        '15 Rajanakarn Building, 12B Floor, Soi Pradipat 17,<br />Pradipat Road, Phayathai, Phayathai,<br />Bangkok, 10400, Thailand',
      tax_number: 'Tax ID: 0105561064958',
      other: 'Other',
      terms_of_use: 'Terms of Use',
      privacy_policy: 'Privacy Policy',
      data_deletion: 'Data Deletion',
      sla: 'SLA',
    },
    payment_success: {
      success: 'Payment Successful',
      thanks: 'Thank you for your order no.',
      start: 'VERSCAN is ready to verify your assets!',
      qr: 'QR Code stickers for VERSCAN and the Receipt will be sent to your given address by delivery within 1-2 working days.',
      send: 'The order confirmation detail will be sent to you via email again.',
      note: 'Note',
      tax: 'If you selected the “Withholding tax” item, please use the information below to issue the “Withholding Tax Certificate” and sent to our address.',
      hod: 'House of Dev Technology Co., Ltd. (Head Office) ',
      address1: 'Tax ID: 0105561064958 ',
      address2:
        '15 Rajanakarn Building, 12B Floor, Soi Pradipat 17, Pradipat Road, Phayathai, Phayathai, Bangkok, 10400',
      back: 'Choose Company',
      use: 'Get Started',
    },
    price_table: {
      min: 'Number of stickers',
      per: 'Price/piece',
      total: 'Total price (THB)',
      order: 'Additional QR code pricing',
      sticker: 'pieces',
      baht: 'THB',
      set: 'Selling as set, each set contains 100 pieces.',
      price: 'price',
      shipping_exclude: '*shipping excluded',
      sticker_price: 'Sticker 5 baht per piece',
      sticker_min: '*minimum 100 pieces',
    },
    loading: 'Loading please wait...',
    payment_confirm: 'Payment Confirmation',
    toverscan: {
      hello: 'Hello,',
      package_is_activating: ' is activating',
    },
    privacy_policy: {
      header: 'Privacy Policy',
      privacy: {
        header: 'Privacy',
        detail: [
          'HOUSE OF DEV TECHNOLOGY built the Verscan app as a Free app. This SERVICE is provided by HOUSE OF DEV TECHNOLOGY at no cost and is intended for use as is.',
          'This page is used to inform visitors regarding our policies with the collection, use, and disclosure of Personal Information if anyone decided to use our Service.',
          'If you choose to use our Service, then you agree to the collection and use of information in relation to this policy. The Personal Information that we collect is used for providing and improving the Service. We will not use or share your information with anyone except as described in this Privacy Policy.',
          'The terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, which is accessible at Verscan unless otherwise defined in this Privacy Policy.',
        ],
      },
      information: {
        header: 'Information Collection and Use',
        detail: [
          'For a better experience, while using our Service, we may require you to provide us with certain personally identifiable information. The information that we request will be retained by us and used as described in this privacy policy.',
          'The app does use third party services that may collect information used to identify you.',
          'Link to privacy policy of third party service providers used by the app',
        ],
      },
      log_data: {
        header: 'Log Data',
        detail: [
          'We want to inform you that whenever you use our Service, in a case of an error in the app we collect data and information (through third party products) on your phone called Log Data. This Log Data may include information such as your device Internet Protocol (“IP”) address, device name, operating system version, the configuration of the app when utilizing our Service, the time and date of your use of the Service, and other statistics.',
        ],
      },
      cookies: {
        header: 'Cookies',
        detail: [
          "Cookies are files with a small amount of data that are commonly used as anonymous unique identifiers. These are sent to your browser from the websites that you visit and are stored on your device's internal memory.",
          'This Service does not use these “cookies” explicitly. However, the app may use third party code and libraries that use “cookies” to collect information and improve their services. You have the option to either accept or refuse these cookies and know when a cookie is being sent to your device. If you choose to refuse our cookies, you may not be able to use some portions of this Service.',
        ],
      },
      service_provider: {
        header: 'Service Providers',
        detail: [
          'We may employ third-party companies and individuals due to the following reasons:',
          'We want to inform users of this Service that these third parties have access to your Personal Information. The reason is to perform the tasks assigned to them on our behalf. However, they are obligated not to disclose or use the information for any other purpose.',
        ],
        list: [
          'To facilitate our Service;',
          'To provide the Service on our behalf;',
          'To perform Service-related services; or',
          'To assist us in analyzing how our Service is used.',
        ],
      },
      security: {
        header: 'Security',
        detail: [
          'We value your trust in providing us your Personal Information, thus we are striving to use commercially acceptable means of protecting it. But remember that no method of transmission over the internet, or method of electronic storage is 100% secure and reliable, and we cannot guarantee its absolute security.',
        ],
      },
      link_other: {
        header: 'Links to Other Sites',
        detail: [
          'This Service may contain links to other sites. If you click on a third-party link, you will be directed to that site. Note that these external sites are not operated by us. Therefore, we strongly advise you to review the Privacy Policy of these websites. We have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services.',
        ],
      },
      children_privacy: {
        header: 'Children’s Privacy',
        detail: [
          'These Services do not address anyone under the age of 13. We do not knowingly collect personally identifiable information from children under 13 years of age. In the case we discover that a child under 13 has provided us with personal information, we immediately delete this from our servers. If you are a parent or guardian and you are aware that your child has provided us with personal information, please contact us so that we will be able to do necessary actions.',
        ],
      },
      change_privacy: {
        header: 'Changes to This Privacy Policy',
        detail: [
          'We may update our Privacy Policy from time to time. Thus, you are advised to review this page periodically for any changes. We will notify you of any changes by posting the new Privacy Policy on this page.',
          'This policy is effective as of 2021-07-27',
        ],
      },
      contact: {
        header: 'Contact Us',
        detail: [
          'If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us at bot@dev.houseofdev.tech.',
          'This privacy policy page was created at <a href="https://www.privacypolicytemplate.net/" target="_blank">privacypolicytemplate.net</a> and modified/generated by <a href="https://app-privacy-policy-generator.nisrulz.com/" target="_blank">App Privacy Policy Generator</a>',
        ],
      },
    },
    data_deletion: {
      header: 'Deletion Instructions',
      sub_header: 'Verscan Data Deletion Instructions',
      detail: [
        'If you would like to permanently delete your account from our database, please contact our official email <a href="mailto:service@verscan.com">service@verscan.com</a>',
        'Warning: Once an account is deleted from our database, deletion is permanent and any information in your account will no longer be retrievable, including but not limited to any unused booking credits you may still have in your account. Verscan will not be responsible for retrieving or verifying data that have been deleted upon request by the user.',
      ],
    },
    sla: {
      header: 'มาตรฐานการให้บริการ (Service Level Agreement : SLA)',
      detail:
        'ข้อตกลงระดับการบริการในที่นี้จะเรียกว่า Service Level Agreement (SLA) เป็นข้อตกลงร่วมกันระหว่างผู้ให้บริการในที่นี้คือ “บริษัท เฮาส์ออฟเดฟ เทคโนโลยี จำกัด” และผู้ใช้บริการคือ “ลูกค้า” เพื่อตกลงถึงระดับคุณภาพของการให้บริการ ดังนี้',
      service: 'ด้านการบริการ',
      sell_and_account: {
        header: 'ฝ่ายขายและฝ่ายบัญชี',
        detail: [
          'ผู้ใช้บริการสามารถติดต่อเจ้าหน้าที่ได้ที่ 099-414-6776 ให้บริการในวันและเวลาทำการ จันทร์-ศุกร์ เวลา 10.00 น. – 18.00 น. หรือ ติดต่อทาง Line : @VERSCAN, อีเมล service@verscan.com ตลอด 24 ชั่วโมง ผู้ใช้บริการจะได้รับการติดต่อกลับจากเจ้าหน้าที่บริการลูกค้าภายในเวลาไม่เกิน 12 ชั่วโมง ในเวลาทำการ',
        ],
      },
      backup: {
        header: 'ระบบสำรองข้อมูล (Backup)',
        detail: [
          'การสำรองข้อมูลทั้งแบบรายวัน (Daily) ทั้งนี้ขึ้นกับบริการของแต่ละแพคเกจการใช้งานซึ่งแตกต่างกัน',
        ],
      },
      log: {
        header: 'การจัดเก็บ Log',
        detail: [
          'มีการจัดเก็บ Log ในระยะเวลา 90 วัน ตาม พ.ร.บ.ว่าด้วยการกระทำความผิดเกี่ยวกับระบบคอมพิวเตอร์ พ.ศ. 2550',
        ],
      },
      contact: {
        header: 'ฝ่ายบริการลูกค้า',
        detail: [
          {
            detail:
              'เพื่อตรวจสอบ แก้ไขปัญหาทางด้านเทคนิคแก่ผู้ใช้บริการ อันเกิดจากระบบของผู้ให้บริการ ดังต่อไปนี้',
            sub_detail: [
              'ทางโทรศัพท์หมายเลข 099-414-6776 ติดต่อฝ่ายเทคนิค ในวันและเวลาทำการ จันทร์-ศุกร์ เวลา 10.00 น. – 18.00 น.',
              'ทาง Line : @VERSCAN หรือ อีเมล service@verscan.com ตลอด 24 ชั่วโมง ผู้ใช้บริการจะได้รับการติดต่อกลับจากเจ้าหน้าที่ภายในเวลาไม่เกิน 12 ชั่วโมง ในเวลาทำการ',
              'กรณีระบบจำเป็นต้องได้รับเเก้ไขโดยทันที เนื่องจากมีผลกระทบเเละสร้างความเสียหายต่อธุรกิจหรือระบบสำคัญ เเละผู้ใช้บริการในวงกว้าง ผู้ใช้บริการไม่สามารถแก้ไขปัญหาเฉพาะหน้าด้วยตนเอง และไม่สามารถรอการแก้ไขตามขั้นตอนปกติได้ และกระทบต่อการควบคุมการเข้าถึงข้อมูลที่เป็นความลับ กระทบต่อความสมบูรณ์ เช่น มีการเข้าถึงโดยไม่ได้รับอนุญาต การถูกโจมตี หรือถูกแฮกระบบ มีข้อมูลสูญหาย หรือข้อมูลรั่วไหล เป็นต้น สามารถติดต่อทางโทรศัพท์ 099-414-6776, 02-077-7833 ตลอด 24 ชั่วโมง ทั้งในเเละนอกเวลาทำการ',
            ],
          },
          {
            detail:
              'VERSCAN รับประกันระยะเวลาในการแก้ไขปัญหาทางด้านเทคนิคของผู้ใช้บริการ โดยจะสรุปรายงานการแก้ไขปัญหา, สาเหตุของปัญหา และแนวทางการแก้ไข และแจ้งเพื่อให้ผู้ใช้บริการทราบทางหน้าเว็บไซต์และอีเมล์ที่ลงทะเบียนไว้ในระบบ ภายในระยะเวลาไม่เกิน 24 ชั่วโมง หรือ 1 วันทำการโดยประมาณ ภายใต้เงื่อนไขการให้บริการข้อมูลของปัญหาการใช้งานที่ชัดเจนของผู้ใช้บริการ เนื่องจากปัญหาของผู้ใช้บริการแต่ละราย มีสภาพปัญหาที่เกิดจากปัจจัยและสาเหตุของปัญหาที่แตกต่างกัน',
            sub_detail: [],
          },
          {
            detail:
              'การแจ้งปัญหาการใช้บริการหรือการกระทำการต่างๆ อันเกี่ยวข้องกับบริการ เพื่อความรวดเร็วสามารถแจ้งผ่านทางโทรศัพท์ได้ ยกเว้นบางกรณีต้องแจ้งผ่านทางอีเมล service@verscan.com เพื่อเป็นการยืนยันตัวตน เช่น การลบไฟล์หรือข้อมูลของเว็บไซต์ หรือกรณีอื่นๆ ขึ้นอยู่กับการตัดสินใจของผู้ให้บริการ การให้ข้อมูลที่เกี่ยวข้องกับบริการ บริษัทฯ สงวนสิทธิ์ในการจัดส่งไปยังอีเมลที่ลงทะเบียนไว้ในระบบหรือผู้ที่ได้รับการ Authorize แล้วเท่านั้น',
            sub_detail: [],
          },
        ],
      },
    },
  },
  th: {
    home: {
      or: 'หรือ',

      turtorial: {
        steps: 'ขั้นตอนการสมัครใช้งานระบบ VERSCAN',
        one: {
          1: 'สมัครสมาชิก',
          2: 'เพื่อเข้าใช้งานระบบ',
        },
        two: {
          1: 'เลือกแพ็กเกจ',
          2: 'ตามจำนวนสินทรัพย์',
          3: 'ในองค์กรของคุณ',
          4: '*หรือทดลองใช้งานระบบฟรี 30 วัน',
        },
        three: {
          1: 'เริ่มใช้งานระบบ',
          2: 'โดยทางเราจะจัดส่ง QR Code',
          3: 'สำหรับใช้งานกับระบบ',
          4: 'VERSCAN ให้คุณ',
          5: '*จัดส่ง QR Code ภายใน 1-2 วันทำการ',
        },
      },
      section2: {
        1: 'แอปพลิเคชันในการตรวจนับสินทรัพย์ได้อย่างสะดวก รวดเร็ว ใช้งานง่าย ผ่านการสแกน QR code ',
        2: 'ช่วยให้องค์กรของคุณ ลดต้นทุนและบริหารจัดการสินทรัพย์ถาวรได้ง่ายยิ่งขึ้น ลดข้อผิดพลาดในการตรวจนับและสามารถติดตามผลได้อย่างมีประสิทธิภาพ',
        3: 'ลดข้อผิดพลาดในการตรวจนับและสามารถติดตามผลได้อย่างมีประสิทธิภาพ',
      },
      section3: {
        header: 'VERSCAN จะช่วยแก้ปัญหาในองค์กรของคุณได้อย่างไร?',
        solutions: [
          'ช่วยลดต้นทุนและทรัพยากรที่ใช้ในการตรวจนับได้ทันที',
          'ทำให้ไม่พลาดในการตรวจนับสินทรัพย์ทุกรายการได้แบบ 100%',
          'สามารถตรวจนับสินทรัพย์และดูผลการตรวจนับได้แบบ Real-time เพียงแค่เชื่อมต่อสัญญาณอินเตอร์เน็ตกับสมาร์ทโฟนเท่านั้น',
          'แอปพลิเคชันสามารถใช้งานได้แบบ Offline แม้ไม่มีสัญญาญอินเตอร์เน็ต',
          'สามารถบันทึกข้อมูลจากโปรแกรมต่างๆ ได้และมีความปลอดภัย ในการใช้งาน จากการจำกัดสิทธิ์การเข้าถึงของตัวบุคคล ดังนั้นพนักงานทั่วไปหรือบุคคลภายนอกจะไม่สามารถเข้าถึงข้อมูลสำคัญของบริษัทได้',
          'รูปแบบรายงานการตรวจนับเข้าใจง่าย',
          'บริการของเราจะเชื่อมกับ QR code ซึ่งเราสามารถผลิตตัว QR code ลงบนวัสดุอื่นๆ ให้เหมาะกับทุกสภาพการใช้งาน',
        ],
      },

      section4: {
        header: 'พร้อมกับฟีเจอร์ที่น่าสนใจ',
        features: [
          'ระบุสภาพการใช้งานของสินทรัพย์ ณ ปัจจุบัน',
          'บันทึกภาพของสินทรัพย์',
          'แจ้งสูญหาย หรือแจ้งซ่อมสินทรัพย์ได้',
          'ระบุตำแหน่งที่อยู่ของสินทรัพย์ตามผังอาคาร',
          'ผู้มีอำนาจสามารถเซ็นรับรองรายงานการตรวจนับได้ผ่านแอปพลิเคชัน',
          'แจ้งเตือนวันตรวจนับสินทรัพย์และมีระบบ AI ช่วยประเมินเวลาที่ต้องใช้ในการตรวจนับครั้งถัดไป ',
          'Export รายงานผลการตรวจนับสินทรัพย์ และสามารถดูภาพรวมในการตรวจนับได้แบบ Real-time ',
        ],
        bubble: 'bubble_th.svg',
      },
      section5: {
        header: 'ในการตรวจนับสินทรัพย์ถาวรในแต่ละครั้ง องค์กรของคุณเคยประสบปัญหาเหล่านี้หรือไม่?',
        problems: [
          'ใช้ต้นทุน คน เวลา และกระดาษจำนวนมาก',
          'การเก็บรักษาและรวบรวมข้อมูลยากเพราะจดด้วยมือ',
          'ตรวจนับสินทรัพย์ตกหล่น หรือ สูญหาย',
          'ใช้เวลานานในการค้นหาสินทรัพย์และระบุตัวสินทรัพย์',
          'รูปแบบทะเบียนสินทรัพย์ดูเข้าใจยาก ทำให้ยากต่อการตรวจนับ',
          'ข้อมูลการตรวจนับรั่วไหล เนื่องจากระบบไม่มีความปลอดภัยที่มากพอ',
          'กระดาษหรือวัสดุที่ใช้ระบุรหัสสินทรัพย์ขาดความทนทาน ทำให้อาจเกิดข้อผิดพลาดในการระบุตัวสินทรัพย์',
        ],
      },
      section6: {
        header: 'ทำไมต้อง VERSCAN ?',
        efficient:
          'VERSCAN ฉีกกฎการตรวจนับสินทรัพย์แบบเดิมๆ สร้างประสบการณ์ใหม่ ที่สะดวก รวดเร็ว ปลอดภัย และมีขั้นตอนการทำงานที่เป็นระบบ ช่วยลดต้นทุน ลดความผิดพลาดจากการตรวจนับด้วยมือ',
        privacy:
          'เรามีระบบจำกัดสิทธิ์ในการเข้าถึงข้อมูลระหว่างพนักงานทั่วไป ผู้บริหารหรือผู้รับผิดชอบข้อมูลทางการเงิน อาทิ เช่น หัวหน้าแผนกบัญชี เป็นต้น',
        storage:
          'VERSCAN เชื่อมโยงข้อมูลในรูปแบบดิจิทอล ทำให้ปัญหาการเก็บบันทึกข้อมูลในรูปแบบของกระดาษจะหมดไปโดยคุณสามารถเลือกเก็บข้อมูลใน Server ขององค์กรคุณได้',
        easy: 'VERSCAN สามารถใช้ตรวจนับสินทรัพย์ ได้ภายใน 3 ขั้นตอน ในระยะเวลาอันรวดเร็ว และมี ฟีเจอร์ ต่าง ๆ ที่ใช้ได้ง่ายและชัดเจน ',
      },
      customer: 'ลูกค้าของเรา',
      section7: {
        header: 'บริการเสริมของ VERSCAN',
        services: [
          'มีบริการ<span style="color:#0158b6;">จัดทำ QR Code</span> พร้อมส่งให้ถึงมือคุณ บนวัสดุทุกรูปแบบ ที่เหมาะกับสินทรัพย์ของคุณ เพื่อให้มีอายุการใช้งานได้นานขึ้น ไม่ว่าจะเป็น กระดาษกันน้ำ การเคลือบพลาสติก แผ่นเหล็ก และอื่นๆ',
          'มีบริการ<span style="color:#0158b6;">สอนวิธีการใช้งาน</span>เบื้องต้นทั้งในรูปแบบ <span style="color:#0158b6;">On-site และ Online</span>',
          'มีบริการทีมงาน<span style="color:#0158b6;">ติดตั้ง QR Code</span> เพื่อระบุตัวสินทรัพย์',
          'มี<span style="color:#0158b6;">ทีมงาน Support</span> ที่สามารถติดต่อสอบถามเมื่อท่านมีข้อสงสัยหรือเกิดความขัดข้องในการใช้งานตัวแอปพลิเคชันตลอดเวลา',
          'สามารถ<span style="color:#0158b6;">เชื่อมต่อระบบข้อมูล</span>กับระบบ ERP เดิมขององค์กรได้',
          'สามารถ<span style="color:#0158b6;">เพิ่มฟีเจอร์​อื่นๆ</span> ที่เกี่ยวข้องเพื่อให้ตอบโจทย์ธุรกิจของคุณมากยิ่งขึ้นด้วยทีมงาน Software Developer ผู้เชี่ยวชาญ',
        ],
      },
      section8: {
        contacts: [
          'verscan@houseofdev.tech | www.houseofdev.tech ',
          'Tel : 02-0777833',
          'A service team installing QR code to identify assets.',
          'House of Dev Technology Co., Ltd. 15 Rajanakarn Building, 12B Floor, Soi Pradipat 17,Pradipat Road, Phayathai, Phayathai, Bangkok, 10400',
        ],
      },
    },
    footer: {
      verscan: 'VERSCAN',
      assets_systems: 'ให้การตรวจนับสินทรัพย์ในองค์กรของคุณเป็นเรื่องที่ง่ายยิ่งขึ้น',
      get_the_app: 'Get the App',
      contact_us: 'Contact Us',
      email: 'info@houseofdev.tech',
      tel: '09 9414 6776',
      tel2: 'โทร 02 096 2959',
      open: 'เวลาทำการ 9:00 -  17:30 น. จันทร์ - ศุกร์',
      hod: 'บริษัท เฮาส์ออฟเดฟ เทคโนโลยี จำกัด <nobr>(สำนักงานใหญ่)</nobr>',
      hod_quotation: 'บริษัท เฮาส์ออฟเดฟ เทคโนโลยี จำกัด (สำนักงานใหญ่)',
      address:
        '15 อาคารรัจนาการ ชั้น 12บี ซอยประดิพัทธ์ 17<br />ถนนประดิพัทธ์ แขวงพญาไท เขตพญาไท<br />กรุงเทพมหานคร 10400',
      tax_number: 'เลขประจำตัวผู้เสียภาษี 0105561064958',
      other: 'Other',
      terms_of_use: 'ข้อกำหนดและเงื่อนไขการใช้งาน',
      privacy_policy: 'นโยบายความเป็นส่วนตัว',
      data_deletion: 'การลบข้อมูล',
      sla: 'SLA',
    },
    future_feature: {
      header: 'ฟีเจอร์ใหม่ เร็ว ๆ นี้',
      offline: 'รองรับการทำงานออฟไลน์',
      dashboard: 'แสดงแดชบอร์ดหลายบริษัทสำหรับผู้บริหาร',
      repair: 'ระบบใบงานแจ้งซ่อมสำหรับช่าง',
      audit: 'ฟังก์ชันสำหรับ Audit',
    },
    header: {
      register: 'สมัครสมาชิก',
      logout: 'ออกจากระบบ',
      login: 'เข้าสู่ระบบ',
      get_demo: 'Get Demo',
      contact_demo: 'ติดต่อเพื่อรับการ Demo',
      date_demo: 'วัน-เวลา ที่ต้องการ Demo',
      th: 'ไทย',
      en: 'อังกฤษ',
      pass_condition: 'รหัสผ่านต้องมีความยาว 8 - 16 ตัวอักษร และมีตัวอักษรภาษาอังกฤษและตัวเลข',
      pass_confirm: 'รหัสผ่านไม่ตรงกัน',
      get_started: 'เริ่มต้นใช้งาน ฟรี!',
      navbar: {
        home: 'หน้าแรก',
        package: 'แพ็กเกจ & โปรโมชัน',
        select: 'เลือกจำนวนสินทรัพย์',
        company: 'บริษัททั้งหมด',
        contact: 'ติดต่อเรา',
      },
      bussiness: 'ประเภทธุรกิจ',
      asset: 'ประเภทสินทรัพย์',
      more_than_one: '(สามารถเลือกได้มากกว่า 1 ตัวเลือก)',
      checkbox: [
        'เครื่องจักร',
        'อุปกรณ์สำนักงาน',
        'เครื่องตกแต่งสำนักงาน',
        'ยานพาหนะ',
        'ระบบสาธารณูปโภค',
        'อะไหล่/ชิ้นส่วนสำรอง',
        'อื่น ๆ',
      ],
      amount: 'จำนวนสินทรัพย์',
      radio: [
        'น้อยกว่า 200 ชิ้น',
        '200 - 1,700 ชิ้น',
        '1,701 - 3,200 ชิ้น',
        '3,201 - 5,000 ชิ้น',
        'มากกว่า 5,000 ชิ้น',
      ],
      date: 'ช่วงเวลาที่คาดว่าจะเริ่มใช้ระบบ',
      bussiness_eg: 'เช่น บริษัทจำกัด, รัฐวิสาหกิจ เป็นต้น ',
      message_eg: 'เช่น ต้องการผลิต QR Code ที่ทนความร้อนมากกว่า 60 องศาขึ้นไป',
      received: 'เราได้รับข้อมูลของท่านเรียบร้อยแล้ว',
    },

    login: {
      forget: 'ลืมรหัสผ่าน',
      email: 'อีเมล',
      password: 'รหัสผ่าน',
      login: 'เข้าสู่ระบบ',
      register: 'สมัครสมาชิก',
    },
    register: {
      register: 'สมัครสมาชิก',
      next: 'ต่อไป',
      email: 'อีเมล',
      password: 'รหัสผ่าน',
      confirm: 'ยืนยันรหัสผ่าน',
      tel: 'เบอร์ติดต่อ',
      login: 'เข้าสู่ระบบ',
      company_name: 'ชื่อบริษัทของคุณ',
      success: 'สมัครสมาชิกเรียบร้อย',
      error_jagota: {
        header: 'ไม่สามารถสมัครสมาชิกได้!',
        detail: 'กรุณาติดต่อผู้มีส่วนเกี่ยวข้องเพื่อขอรับคำเชิญ',
      },
    },
    company_select: {
      type_del: 'กรุณาพิมพ์คำว่า Delete',
      exp: 'หมดอายุวันที่',
      pay: 'กรุณาเลือกแพ็กเกจ',
      select: 'กรุณาเลือกบริษัทของคุณ',
      department: 'สำนักงาน',
      add: 'เพิ่มบริษัทของคุณ',
      edit: 'แก้ไขบริษัทของคุณ',
      btnAdd: 'เพิ่ม',
      btnEdit: 'แก้ไข',
      btnSave: 'บันทึก',
      name: 'ชื่อบริษัท',
      type: 'สำนักงาน',
      hq: 'สำนักงานใหญ่',
      branch: 'สำนักงานย่อย',
      branchNumber: 'เลขที่สาขา',
      branchName: 'ชื่อสาขา',
      addCompany: 'เพิ่มบริษัท',
      deleting: 'คุณกำลังลบข้อมูลธุรกิจ !',
      afterDelete:
        'หลังจากใส่ข้อความ “Delete” และกดยืนยันการลบข้อมูลแล้ว ข้อมูลธุรกิจนี้จะถูกลบทันที และไม่สามารถเรียกคืนได้อีกต่อไป',
      confirmDelete: 'กรอกข้อความยืนยันการลบข้อมูล',
      cancelDelete: 'ยกเลิก',
      delete: 'ยืนยันการลบข้อมูล',
      pending: 'รอการจัดส่ง QR Code และ ใบเสร็จรับเงินภายใน 1-2 วันทำการ',
    },
    package: {
      year: 'รายปี หรือ 12 เดือน',
      buy: 'ซื้อ',
      trial: 'ทดลองใช้ 7 วัน',
      seeLess: 'ดูน้อยลง',
      seeMore: 'รายละเอียด',
      activating: 'กำลังใช้งานแก็จเกจนี้อยู่',
      change: 'เปลี่ยนแพ็กเกจ',
      cancel: 'ยกเลิกแพ็กเกจ',
      price: 'ราคา',
      thb: 'บาท',
    },
    payment: {
      information: 'ข้อมูลการชำระเงิน และที่อยู่ในการจัดส่ง QR Code',
      chosen: 'แพ็กเกจที่คุณเลือก',
      billing: 'รายละเอียดการชำระเงิน',
      companyName: 'ชื่อบริษัท',
      type: 'สำนักงาน',
      department: 'สำนักงาน',
      hq: 'สำนักงานใหญ่',
      branch: 'สาขา',
      branchNumber: 'เลขที่สาขา',
      branchName: 'ชื่อสาขา',
      address: 'ที่อยู่',
      address2: 'ที่อยู่เพิ่มเติม',
      city: 'จังหวัด',
      post: 'รหัสไปรษณีย์',
      country: 'ประเทศ',
      taxNumber: 'เลขประจำตัวผู้เสียภาษี',
      email: 'อีเมลติดต่อ',
      back: 'กลับ',
      next: 'ต่อไป',
      head_office: 'สำนักงานใหญ่',
      branch: 'สาขา',
      accept: 'ยืนยัน',
      assets: 'ชิ้น',
      exist: 'มีบริษัทนี้ในระบบแล้ว',
    },
    payment_detail: {
      packageName: 'แพ็กเกจ',
      price: 'ราคาแพ็กเกจ',
      discount: 'ส่วนลด',
      vat: 'ภาษี',
      total: 'ยอดชำระ',
      wht: 'ภาษีหัก ณ ที่จ่าย ',
      payment_detail: 'รายละเอียดการชำระเงิน',
      vat_include: '* ราคานี้รวมภาษีมูลค่าเพิ่มแล้ว',
    },
    package_detail: {
      package_detail: 'รายละเอียดแพ็กเกจ',
      package_size: 'ขนาดแพ็กเกจ',
      payment_plan: 'ระยะเวลาแพ็กเกจ',
      monthly_price: 'ราคาเฉลี่ยต่อเดือน',
    },
    payment_method: {
      method: 'เลือกวิธีการชำระเงิน ',
      select: 'ชำระผ่านบัตรเครดิต / บัตรเดบิต / พร้อมเพย์  และช่องทางอื่น ๆ',
      bank: 'โอนเงินผ่านธนาคาร',
      back: 'กลับ',
    },

    bank_transfer: {
      account: 'ชำระเงินตามบัญชีด้านล่าง',
      confirm: 'หลักฐานการโอนเงิน',
      kasikorn: 'ธนาคาร กสิกรไทย',
      hod: 'ชื่อบัญชี บจก. เฮาส์ออฟเดฟ เทคโนโลยี',
      hod_2: '',
      order: 'เลขที่ออเดอร์.',
      amount: 'จำนวนเงินที่โอน',
      time: 'วันที่โอน',
      bank: 'ธนาคารที่ใช้โอน',
      evidence: 'หลักฐานการโอนเงิน',
      upload: 'อัพโหลดหลักฐานการโอนเงิน',
      bankNum: 'เลขที่บัญชี',
    },
    forget_password: {
      header: 'ลืมรหัสผ่าน',
      subHeader: 'กรุณากรอกอีเมลเพื่อรับลิงค์รีเซ็ตรหัสผ่าน',
      email: 'อีเมล',
      request: 'ส่งอีเมลให้ฉัน',
      back: 'กลับไปหน้าหลัก',
      finish: 'ระบบได้ทำการส่งอีเมลเรียบร้อยแล้ว ',
    },
    change_password: {
      password: 'รหัสผ่านใหม่',
      confirm: 'ยืนยันรหัสผ่าน',
      change: 'ตั้งรหัสผ่านใหม่',
      create: 'สร้างรหัสผ่าน',
      save: 'บันทึก',
      not_match: 'รหัสผ่านไม่ตรงกัน',
    },
    promotion: {
      package_promotion: 'แพ็กเกจ & โปรโมชัน',
      free_trial: 'Free trial',
      s: 'S',
      m: 'M',
      l: 'L',
      package: 'Package',
      unlimit_user: 'ไม่จำกัดบัญชีใช้งาน',
      free_stricker_40: 'ฟรี QR Code 40 ดวง',
      free_stricker_300: 'ฟรี QR Code 300 ดวง',
      piece: 'ชิ้น',
      amount_asset: 'ปริมาณสินทรัพย์ในองค์กร',
      trial: 'ทดลองใช้งาน',
      select: 'เลือกแพ็กเกจ',
      free: 'ฟรี',
      start: 'เริ่มต้น',
      year: 'ปี',
      month: 'เดือน',
      free_duration: 'ระยะเวลาการใช้งาน 30 วัน',
      s_duration: '*ชำระรายปี 8,980 บาท/บริษัท',
      m_duration: '*ชำระรายปี 17,880 บาท/บริษัท',
      l_duration: '*ชำระรายปี 22,780 บาท/บริษัท',
      next_year: 'ปีถัดไป 665 / เดือน',
      pay_system: 'ชำระรายปี 7,980 บาท/บริษัท',
      scale: 'VERSCAN คิดค่าใช้จ่ายตามจำนวนสินทรัพย์ของคุณ',
      get_price: 'เช็คราคา',
      date: '(วันนี้ - 31 ม.ค. 65 เท่านั้น)',
    },
    feature: {
      features: 'ฟีเจอร์ทั้งหมด',
      unlimit_user: 'ไม่จำกัดบัญชีใช้งาน',
      free_stricker_300: 'ฟรี QR Code 300 ดวง',
      offline: 'รองรับการทำงานออฟไลน์',
      free_service_support: 'ฟรีบริการสอนการใช้งานเบื้องต้นและนำเข้าข้อมูล',
      photo_asset: 'ถ่ายรูปสภาพทรัพย์สินปัจจุบัน',
      scan_asset: 'แสกนตรวจนับทรัพย์สิน',
      dashboard: 'แดชบอร์ดสรุปภาพรวม',
      summary_asset: 'สรุปรายงานการตรวจนับ',
    },
    interesting_feature: 'ฟีเจอร์ที่น่าสนใจ',
    packages: {
      loading: 'กำลังดำเนินการ กรุณารอสักครู่',
      preparation:
        'บริการจัดทำ QR-Code บนวัสดุชนิดต่างๆ เช่น กระดาษกันน้ำ, การเคลือบพลาสติก, แผ่นโลหะ, อื่นๆ',
      installation: 'บริการทีมงานติดตั้ง QR-Code เพื่อระบุสินทรัพย์',
      data_transfer: 'บริการโอนย้ายข้อมูลจากระบบ ERP เดิมขององค์กร',
      additional: 'บริการเพิ่มฟีเจอร์อื่นๆ ที่เกี่ยวข้อง เพื่อให้ตอบโจทย์กับองค์กรของคุณ',
      name: 'ชื่อ-นามสกุล',
      choose_amount: 'เลือกจำนวนสินทรัพย์ภายในองค์กรของคุณ',
      choose_duration: 'ระยะเวลาแพ็กเกจ',
      calculate_price: 'ราคาเฉลี่ยต่อเดือน',
      paid_annaully: '(ชำระรายปี)',
      paying: 'ชำระ',
      pay_annaully: 'ชำระค่าบริการเป็นรายปี ปีแรก ',
      pay_twoyear: 'ชำระค่าบริการล่วงหน้ารายสองปีรวม ',
      next_year: '*ปีถัดไปชำระเพียง 7,980 บาท /ปี ',
      average_pay: '(เฉลี่ย 665 บาท/เดือน)',
      get_quotation: 'ขอใบเสนอราคา',
      quotation_remark: '* ราคานี้รวมภาษีมูลค่าเพิ่มแล้ว',
      quotation_email: 'เราได้ส่งใบเสนอราคาให้ท่านทางอีเมลเรียบร้อย',
      quotation_confirm: 'ตกลง',
      quotation_download: 'โดยท่านสามารถตรวจสอบ และดาวน์โหลดใบเสนอราคาได้ที่อีเมลของท่าน ขอบคุณค่ะ',
      quotation_company: 'บริษัท เฮาส์ออฟเดฟ เทคโนโลยี จำกัด (สำนักงานใหญ่)',
      quotation_company_address: '15 อาคารรัจนาการ ชั้น 12บี ซอยประดิพัทธ์ 17 ถนนประดิพัทธ์ แขวงพญาไท เขตพญาไท กรุงเทพมหานคร 10400',
      product_unit: {
        package: 'แพ็กเกจ',
        qr: 'ดวง',
        year: 'ปี',
      },
      quotation_modal: {
        modal_text: 'กรุณากรอกข้อมูลสำหรับขอใบเสนอราคา',
        company_name: 'ชื่อบริษัทของคุณ',
        branch: 'สำนักงาน',
        branch_item: [
          {
            text: 'สำนักงานใหญ่',
            value: 'headOffice',
          },
          {
            text: 'สาขา',
            value: 'branch',
          },
        ],
        branch_display: 'สำนักงานใหญ่',
        branch_number: 'เลขที่สาขา',
        branch_name: 'ชื่อสาขา',
        company_address: 'ที่อยู่',
        company_address2: 'ที่อยู่เพิ่มเติม',
        company_city: 'จังหวัด',
        company_country: 'ประเทศ',
        company_postcode: 'รหัสไปรษณีย์',
        company_taxid: 'เลขประจำตัวผู้เสียภาษี',
        company_tel: 'เบอร์โทร',
        company_email: 'อีเมล',
        same_email: 'ใช้อีเมลเดียวกัน',
        company_receive: 'อีเมลรับใบเสนอราคา',
        send: 'ส่งใบเสนอราคา',
      },
      checkout: 'เริ่มต้นใช้งาน',
      one_year: '1 ปี',
      two_years: '2 ปี (ลด 5%)',
      price: 'ราคา',
      per_month: 'บาท/เดือน',
      per_year: ' บาท/ปี',
      baht: ' บาท',
      extra_service: 'บริการเสริม ',
      optional: '(ถ้าสนใจ)',
      want_extra: 'ฉันต้องการบริการเสริม',
      contact_us: 'ลงทะเบียนเพื่อใช้งานระบบ',
      contact_now: 'ติดต่อเรา',
      contact_info: 'ข้อมูลติดต่อ',
      package: 'ขนาดแพ็กเกจ',
      package_info: 'ข้อมูลแพ็กเกจ',
      assets_amount: 'จำนวนสินทรัพย์',
      message: 'ข้อความ',
      send: 'ส่ง',
      already_sent: 'เราได้รับข้อมูลเรียบร้อยแล้ว',
      please_check: 'เราจะติดต่อกลับภายใน 24 ชม.',
      assets: 'ชิ้น',
      first: '(ชำระรายปีต่อบริษัท)',
      second: '(ชำระราย 2 ปีต่อบริษัท)',
      get: 'สิ่งที่คุณจะได้รับ',
      get_amount: 'QR Code จำนวน',
      get_assets: 'ดวง',
      get_collab: 'สำหรับใช้ร่วมกับระบบ VERSCAN ',
      get_duration: 'ระยะเวลาการใช้งานระบบ',
      get_feature: 'สามารถเข้าถึงในทุกฟีเจอร์ภายในระบบ VERSCAN',
      free_day: '30 วัน',
      upgrade: 'อัปเกรดแพ็กเกจ',
    },
    payment_success: {
      success: 'ชำระเงินเสร็จสิ้น',
      thanks: 'ขอบคุณสำหรับคำสั่งซื้อหมายเลข',
      start: 'ท่านสามารถเริ่มใช้งานระบบ VERSCAN  ได้ทันที !',
      qr: 'โดย QR Code สำหรับใช้งานร่วมกับระบบ VERSCAN และ ใบเสร็จรับเงิน จะถูกจัดส่งให้ท่านทางไปรษณีย์ ภายใน 1-2 วันทำการตามที่อยู่ที่ท่านได้แจ้งไว้ ',
      send: 'และรายละเอียดการยืนยันคำสั่งซื้อจะจัดส่งให้ท่านผ่านทางอีเมลอีกครั้ง',
      note: 'หมายเหตุ',
      tax: 'หากตอนชำระเงินท่านเลือกรายการ หักภาษี ณ ที่จ่าย โปรดใช้ข้อมูลด้านล่างในการออกเอกสาร หนังสือรับรอง หัก ณ ที่จ่าย และจัดส่งเอกสารมาได้ตามที่อยู่นี้',
      hod: 'บริษัท เฮาส์ออฟเดฟ เทคโนโลยี จำกัด (สำนักงานใหญ่)',
      address1: 'เลขประจำตัวผู้เสียภาษี 0105561064958',
      address2:
        '15 อาคารรัจนาการ ชั้น 12บี ซอยประดิพัทธ์ 17 ถนนประดิพัทธ์ แขวงพญาไท เขตพญาไท กรุงเทพมหานคร 10400',
      back: 'เลือกบริษัท',
      use: 'ใช้งานระบบ',
    },
    price_table: {
      min: 'จำนวนสติ๊กเกอร์',
      per: 'ราคา/ดวง',
      total: 'ราคาทั้งหมด(บาท)',
      order: 'สั่งซื้อ QR code เพิ่มเติม',
      sticker: 'ดวง',
      baht: 'บาท',
      set: 'จำหน่ายเป็นชุด ชุดละ 100 ดวง',
      price: 'ราคา',
      shipping_exclude: '*ราคายังไม่รวมส่ง',
      sticker_price: 'สติ๊กเกอร์ราคาดวงละ 5 บาท',
      sticker_min: '*ขั้นต่ำ 100 ดวง',
    },
    loading: 'กรุณารอสักครู่...',
    payment_confirm: 'รายละเอียดคำสั่งซื้อ',
    toverscan: {
      hello: 'สวัสดี,',
      package_is_activating: ' กำลังใช้งาน',
    },
    privacy_policy: {
      header: 'Privacy Policy',

      privacy: {
        header: 'Privacy',
        detail: [
          'HOUSE OF DEV TECHNOLOGY built the Verscan app as a Free app. This SERVICE is provided by HOUSE OF DEV TECHNOLOGY at no cost and is intended for use as is.',
          'This page is used to inform visitors regarding our policies with the collection, use, and disclosure of Personal Information if anyone decided to use our Service.',
          'If you choose to use our Service, then you agree to the collection and use of information in relation to this policy. The Personal Information that we collect is used for providing and improving the Service. We will not use or share your information with anyone except as described in this Privacy Policy.',
          'The terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, which is accessible at Verscan unless otherwise defined in this Privacy Policy.',
        ],
      },
      information: {
        header: 'Information Collection and Use',
        detail: [
          'For a better experience, while using our Service, we may require you to provide us with certain personally identifiable information. The information that we request will be retained by us and used as described in this privacy policy.',
          'The app does use third party services that may collect information used to identify you.',
          'Link to privacy policy of third party service providers used by the app',
        ],
      },
      log_data: {
        header: 'Log Data',
        detail: [
          'We want to inform you that whenever you use our Service, in a case of an error in the app we collect data and information (through third party products) on your phone called Log Data. This Log Data may include information such as your device Internet Protocol (“IP”) address, device name, operating system version, the configuration of the app when utilizing our Service, the time and date of your use of the Service, and other statistics.',
        ],
      },
      cookies: {
        header: 'Cookies',
        detail: [
          "Cookies are files with a small amount of data that are commonly used as anonymous unique identifiers. These are sent to your browser from the websites that you visit and are stored on your device's internal memory.",
          'This Service does not use these “cookies” explicitly. However, the app may use third party code and libraries that use “cookies” to collect information and improve their services. You have the option to either accept or refuse these cookies and know when a cookie is being sent to your device. If you choose to refuse our cookies, you may not be able to use some portions of this Service.',
        ],
      },
      service_provider: {
        header: 'Service Providers',
        detail: [
          'We may employ third-party companies and individuals due to the following reasons:',
          'We want to inform users of this Service that these third parties have access to your Personal Information. The reason is to perform the tasks assigned to them on our behalf. However, they are obligated not to disclose or use the information for any other purpose.',
        ],
        list: [
          'To facilitate our Service;',
          'To provide the Service on our behalf;',
          'To perform Service-related services; or',
          'To assist us in analyzing how our Service is used.',
        ],
      },
      security: {
        header: 'Security',
        detail: [
          'We value your trust in providing us your Personal Information, thus we are striving to use commercially acceptable means of protecting it. But remember that no method of transmission over the internet, or method of electronic storage is 100% secure and reliable, and we cannot guarantee its absolute security.',
        ],
      },
      link_other: {
        header: 'Links to Other Sites',
        detail: [
          'This Service may contain links to other sites. If you click on a third-party link, you will be directed to that site. Note that these external sites are not operated by us. Therefore, we strongly advise you to review the Privacy Policy of these websites. We have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services.',
        ],
      },
      children_privacy: {
        header: 'Children’s Privacy',
        detail: [
          'These Services do not address anyone under the age of 13. We do not knowingly collect personally identifiable information from children under 13 years of age. In the case we discover that a child under 13 has provided us with personal information, we immediately delete this from our servers. If you are a parent or guardian and you are aware that your child has provided us with personal information, please contact us so that we will be able to do necessary actions.',
        ],
      },
      change_privacy: {
        header: 'Changes to This Privacy Policy',
        detail: [
          'We may update our Privacy Policy from time to time. Thus, you are advised to review this page periodically for any changes. We will notify you of any changes by posting the new Privacy Policy on this page.',
          'This policy is effective as of 2021-07-27',
        ],
      },
      contact: {
        header: 'Contact Us',
        detail: [
          'If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us at bot@dev.houseofdev.tech.',
          'This privacy policy page was created at <a href="https://www.privacypolicytemplate.net/" target="_blank">privacypolicytemplate.net</a> and modified/generated by <a href="https://app-privacy-policy-generator.nisrulz.com/" target="_blank">App Privacy Policy Generator</a>',
        ],
      },
    },
    data_deletion: {
      header: 'Deletion Instructions',
      sub_header: 'Verscan Data Deletion Instructions',
      detail: [
        'If you would like to permanently delete your account from our database, please contact our official email <a href="mailto:service@verscan.com">service@verscan.com</a>',
        'Warning: Once an account is deleted from our database, deletion is permanent and any information in your account will no longer be retrievable, including but not limited to any unused booking credits you may still have in your account. Verscan will not be responsible for retrieving or verifying data that have been deleted upon request by the user.',
      ],
    },
    sla: {
      header: 'มาตรฐานการให้บริการ (Service Level Agreement: SLA)',
      detail:
        'ข้อตกลงระดับการบริการในที่นี้จะเรียกว่า Service Level Agreement (SLA) เป็นข้อตกลงร่วมกันระหว่างผู้ให้บริการในที่นี้คือ “บริษัท เฮาส์ออฟเดฟ เทคโนโลยี จำกัด” และผู้ใช้บริการคือ “ลูกค้า” เพื่อตกลงถึงระดับคุณภาพของการให้บริการ ดังนี้',
      service: 'ด้านการบริการ',
      sell_and_account: {
        header: 'ฝ่ายขายและฝ่ายบัญชี',
        detail: [
          'ผู้ใช้บริการสามารถติดต่อเจ้าหน้าที่ได้ที่ 099-414-6776 ให้บริการในวันและเวลาทำการ จันทร์-ศุกร์ เวลา 10.00 น. – 18.00 น. หรือ ติดต่อทาง Line : @VERSCAN, อีเมล service@verscan.com ตลอด 24 ชั่วโมง ผู้ใช้บริการจะได้รับการติดต่อกลับจากเจ้าหน้าที่บริการลูกค้าภายในเวลาไม่เกิน 12 ชั่วโมง ในเวลาทำการ',
        ],
      },
      backup: {
        header: 'ระบบสำรองข้อมูล (Backup)',
        detail: [
          'การสำรองข้อมูลทั้งแบบรายวัน (Daily) ทั้งนี้ขึ้นกับบริการของแต่ละแพคเกจการใช้งานซึ่งแตกต่างกัน',
        ],
      },
      log: {
        header: 'การจัดเก็บ Log',
        detail: [
          'มีการจัดเก็บ Log ในระยะเวลา 90 วัน ตาม พ.ร.บ.ว่าด้วยการกระทำความผิดเกี่ยวกับระบบคอมพิวเตอร์ พ.ศ. 2550',
        ],
      },
      contact: {
        header: 'ฝ่ายบริการลูกค้า',
        detail: [
          {
            detail:
              'เพื่อตรวจสอบ แก้ไขปัญหาทางด้านเทคนิคแก่ผู้ใช้บริการ อันเกิดจากระบบของผู้ให้บริการ ดังต่อไปนี้',
            sub_detail: [
              'ทางโทรศัพท์หมายเลข 099-414-6776 ติดต่อฝ่ายเทคนิค ในวันและเวลาทำการ จันทร์-ศุกร์ เวลา 10.00 น. – 18.00 น.',
              'ทาง Line : @VERSCAN หรือ อีเมล service@verscan.com ตลอด 24 ชั่วโมง ผู้ใช้บริการจะได้รับการติดต่อกลับจากเจ้าหน้าที่ภายในเวลาไม่เกิน 12 ชั่วโมง ในเวลาทำการ',
              'กรณีระบบจำเป็นต้องได้รับเเก้ไขโดยทันที เนื่องจากมีผลกระทบเเละสร้างความเสียหายต่อธุรกิจหรือระบบสำคัญ เเละผู้ใช้บริการในวงกว้าง ผู้ใช้บริการไม่สามารถแก้ไขปัญหาเฉพาะหน้าด้วยตนเอง และไม่สามารถรอการแก้ไขตามขั้นตอนปกติได้ และกระทบต่อการควบคุมการเข้าถึงข้อมูลที่เป็นความลับ กระทบต่อความสมบูรณ์ เช่น มีการเข้าถึงโดยไม่ได้รับอนุญาต การถูกโจมตี หรือถูกแฮกระบบ มีข้อมูลสูญหาย หรือข้อมูลรั่วไหล เป็นต้น สามารถติดต่อทางโทรศัพท์ 099-414-6776, 02-077-7833 ตลอด 24 ชั่วโมง ทั้งในเเละนอกเวลาทำการ',
            ],
          },
          {
            detail:
              'VERSCAN รับประกันระยะเวลาในการแก้ไขปัญหาทางด้านเทคนิคของผู้ใช้บริการ โดยจะสรุปรายงานการแก้ไขปัญหา, สาเหตุของปัญหา และแนวทางการแก้ไข และแจ้งเพื่อให้ผู้ใช้บริการทราบทางหน้าเว็บไซต์และอีเมล์ที่ลงทะเบียนไว้ในระบบ ภายในระยะเวลาไม่เกิน 24 ชั่วโมง หรือ 1 วันทำการโดยประมาณ ภายใต้เงื่อนไขการให้บริการข้อมูลของปัญหาการใช้งานที่ชัดเจนของผู้ใช้บริการ เนื่องจากปัญหาของผู้ใช้บริการแต่ละราย มีสภาพปัญหาที่เกิดจากปัจจัยและสาเหตุของปัญหาที่แตกต่างกัน',
            sub_detail: [],
          },
          {
            detail:
              'การแจ้งปัญหาการใช้บริการหรือการกระทำการต่างๆ อันเกี่ยวข้องกับบริการ เพื่อความรวดเร็วสามารถแจ้งผ่านทางโทรศัพท์ได้ ยกเว้นบางกรณีต้องแจ้งผ่านทางอีเมล service@verscan.com เพื่อเป็นการยืนยันตัวตน เช่น การลบไฟล์หรือข้อมูลของเว็บไซต์ หรือกรณีอื่นๆ ขึ้นอยู่กับการตัดสินใจของผู้ให้บริการ การให้ข้อมูลที่เกี่ยวข้องกับบริการ บริษัทฯ สงวนสิทธิ์ในการจัดส่งไปยังอีเมลที่ลงทะเบียนไว้ในระบบหรือผู้ที่ได้รับการ Authorize แล้วเท่านั้น',
            sub_detail: [],
          },
        ],
      },
    },
  },
}

const i18n = new VueI18n({
  locale: 'th', // set locale
  lng: 'th',
  fallbackLocale: 'en', // set fallback locale
  messages, //set locale messages
})

export default i18n
