<template>
  <div>
    <b-container>
      <b-row>
        <b-col cols="12" class="d-flex justify-content-center">
          <h1 class="name-too mt-5">
            {{ $t('toverscan.hello') }} {{ branch.company_name }} ( {{ branch.branch_name }} )
          </h1>
        </b-col>
        <b-col cols="12" class="d-flex justify-content-center my-5">
          <img
            class="pointer"
            @click="toVerscan"
            alt="pointer"
            :src="require(`@/assets/material/redirect_th.png`)"
            v-if="$i18n.locale == 'th'"
          />
          <img
            class="pointer"
            @click="toVerscan"
            alt="pointer"
            :src="require(`@/assets/material/redirect_eng.png`)"
            v-else
          />
        </b-col>
        <b-col cols="12" class="d-flex justify-content-center">
          <h1>
            {{ branch.package }} Package
            {{ $t('toverscan.package_is_activating') }}
          </h1>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'ToVerscan',
  components: {},
  methods: {
    toVerscan() {
      switch (window.location.hostname) {
        case 'verscan.com':
          window.location.replace('https://app.verscan.com/selectcompany')
          break
        case 'staging.verscan.com':
          window.location.replace('https://staging-app.verscan.com/selectcompany')
          break
        case 'dev.verscan.com':
          window.location.replace('https://dev-app.verscan.com/selectcompany')
          break
        case 'localhost':
          window.location.replace('https://dev-app.verscan.com/selectcompany')
          break
        default:
          return null
      }
    },
  },
  computed: {
    ...mapGetters({
      branch: 'getCurrent_branch',
      user: 'getUser',
      currentPackageId: 'getSelected_package',
    }),
  },
}
</script>

<style scoped>
h1 {
  font-size: 24px;
}
.name-too {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 500px;
  font-size: 20px;
}

@media only screen and (in-width: 320px) and (max-width: 494px) {
  .name-too {
    overflow: hidden;
    text-overflow: ellipsis;
    width: 200px;
    font-size: 20px;
  }
}
</style>
