// import objCodec from "object-encode";
//import moment from "moment";
import { baseUrl } from "./backend";
export const formPaysolution = (data) => {
  //const refNo = moment().unix();
  //   const transactionDate = moment().format("YYYY-MM-DD");
  const THAI_E_PAY_CONFIG = {
    url:
      process.env.VUE_APP_PAY_SOLUTION_URL ||
      "https://www.thaiepay.com/epaylink/payment.aspx",
    methodPost: "POST",
    merchantid: 67016103,
    postbackurl: `${baseUrl()}postback_log?payment_type=paysolution`,
    returnUrl: `${window.location.origin}/paymentsuccess`,
  };

  const form = document.createElement("form");
  form.method = THAI_E_PAY_CONFIG.methodPost;
  form.action = THAI_E_PAY_CONFIG.url;

  const hiddenRefno = document.createElement("input");
  hiddenRefno.type = "hidden";
  hiddenRefno.name = "refno";
  hiddenRefno.value = data.refNo;
  form.appendChild(hiddenRefno);

  const hiddenMerchantid = document.createElement("input");
  hiddenMerchantid.type = "hidden";
  hiddenMerchantid.name = "merchantid";
  hiddenMerchantid.value = THAI_E_PAY_CONFIG.merchantid;
  form.appendChild(hiddenMerchantid);

  const hiddenEmail = document.createElement("input");
  hiddenEmail.type = "hidden";
  hiddenEmail.name = "customeremail";
  hiddenEmail.value = "test@test.com";
  form.appendChild(hiddenEmail);

  const hiddenProductdetail = document.createElement("input");
  hiddenProductdetail.type = "hidden";
  hiddenProductdetail.name = "productdetail";
  hiddenProductdetail.value = data.detail;
  form.appendChild(hiddenProductdetail);

  const hiddenTotal = document.createElement("input");
  hiddenTotal.type = "hidden";
  hiddenTotal.name = "total";
  hiddenTotal.value = data.total;
  form.appendChild(hiddenTotal);

  const hiddenReturnurl = document.createElement("input");
  hiddenReturnurl.type = "hidden";
  hiddenReturnurl.name = "returnurl";
  hiddenReturnurl.value = THAI_E_PAY_CONFIG.returnUrl;
  form.appendChild(hiddenReturnurl);

  const hiddenPostbackurl = document.createElement("input");
  hiddenPostbackurl.type = "hidden";
  hiddenPostbackurl.name = "postbackurl";
  hiddenPostbackurl.value = THAI_E_PAY_CONFIG.postbackurl;
  form.appendChild(hiddenPostbackurl);

  document.body.appendChild(form);

  form.submit();
};
