<template>
  <div>
    <div id="tutorial">
      <b-container>
        <b-row class="px-2 py-3">
          <h2 class="mt-4 my-5 xs:text-base sm:text-base md:text-2xl lg:text-3xl xl:text-4xl">
            {{ $t('home').turtorial.steps }}
          </h2>
          <b-col id="one" class="flex mb-4 justify-center" cols="12" md="4">
            <b-img class="pr-5 h-20" :src="require(`@/assets/material/tutorial/one.svg`)" alt="one" />
            <div class="grid text-start">
              <h3>{{ $t('home').turtorial.one['1'] }}</h3>
              <h4>{{ $t('home').turtorial.one['2'] }}</h4>
              <p class="invisible">{{ $t('home').turtorial.three['5'] }}</p>
            </div>
          </b-col>

          <b-col class="flex mb-4 justify-center d-md-none" cols="12" md="0">
            <b-img :src="require(`@/assets/material/tutorial/blue-line.svg`)" alt="blueline"/>
          </b-col>

          <b-col class="flex mb-4 justify-center">
            <b-img
              class="h-20 pr-5 d-none d-md-block"
              :src="require(`@/assets/material/tutorial/down-line.svg`)"
              alt="downline"
            />
            <b-img class="pr-5 h-20" :src="require(`@/assets/material/tutorial/two.svg`)" alt="two"/>
            <div class="grid text-start">
              <h3>{{ $t('home').turtorial.two['1'] }}</h3>
              <h4>{{ $t('home').turtorial.two['2'] }}</h4>
              <h4>{{ $t('home').turtorial.two['3'] }}</h4>
              <p>{{ $t('home').turtorial.two['4'] }}</p>
            </div>
          </b-col>

          <b-col class="flex mb-4 justify-center d-md-none" cols="12" md="0">
            <b-img :src="require(`@/assets/material/tutorial/blue-line.svg`)" alt="blueline"/>
          </b-col>

          <b-col class="flex mb-4 justify-center" cols="12" md="4">
            <b-img
              class="pr-5 h-20 d-none d-md-block"
              :src="require(`@/assets/material/tutorial/down-line.svg`)"
              alt="downline"
            />
            <b-img class="pr-5 h-20" :src="require(`@/assets/material/tutorial/three.svg`)" alt="three"/>
            <div class="grid text-start">
              <h3>{{ $t('home').turtorial.three['1'] }}</h3>
              <h4>{{ $t('home').turtorial.three['2'] }}</h4>
              <h4>{{ $t('home').turtorial.three['3'] }}</h4>
              <h4>{{ $t('home').turtorial.three['4'] }}</h4>
              <p>{{ $t('home').turtorial.three['5'] }}</p>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
export default {
  setup() {
    return {}
  },
}
</script>

<style scoped>
#tutorial {
  background-color: #eff6ff;
}

h2 {
  color: #0158b6;
  font-weight: 500;
  line-height: 30px;
}

h3 {
  color: #0158b6;
  font-weight: 500;
  font-size: 24px;
}

h4 {
  color: #0158b6;
  font-weight: 500;
  font-size: 14px;
}

p {
  color: #545454;
  font-size: 12px;
}
</style>