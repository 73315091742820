export const getters = {
  getUser: (state) => state.user,
  getRegister: (state) => state.register,
  getCompany: (state) => state.company,
  getToken: (state) => state.token,
  getPackages: (state) => state.packages,
  getCurrent_branch: (state) => state.current_branch,
  getSelected_package: (state) => state.selected_package,
  getBill: (state) => state.bill,
  getLoading: (state) => state.loading,
  getPackage_id: (state) => state.package_id,
  getPackagesInformation: (state) => state.package_information,
  getWHT: (state) => state.wht,
  getPackageDetail: (state) => state.package_detail,
  getTotalPrice: (state) => state.totalPrice,
  locale: (state) => state.locale,
  getRefNo: (state) => state.refNo,
  getAltBill: (state) => state.altBill,
}
