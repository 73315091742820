<template>
  <DatePicker
    class="inline-block h-full"
    v-model="date"
    :mode="type"
    :is-required="req"
    :valid-hours="validHours"
    :disabled-dates="disabledDates"
    :min-date="min_date"
    :max-date="max_date"
    :minute-increment="minuteIncrement"
    is24hr
  >
    <template v-slot="{ inputValue, togglePopover }">
      <div class="flex items-center">
        <button
          class="p-2 bg-blue-100 border border-blue-200 hover:bg-blue-200 text-blue-600 rounded-l focus:bg-blue-500 focus:text-white focus:border-blue-500 focus:outline-none"
          type="button"
          @click="togglePopover()"
        >
          <svg v-if="icon == 'calendar'" viewBox="0 0 20 20" class="w-4 h-4 fill-current">
            <path
              d="M1 4c0-1.1.9-2 2-2h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V4zm2 2v12h14V6H3zm2-6h2v2H5V0zm8 0h2v2h-2V0zM5 9h2v2H5V9zm0 4h2v2H5v-2zm4-4h2v2H9V9zm0 4h2v2H9v-2zm4-4h2v2h-2V9zm0 4h2v2h-2v-2z"
            ></path>
          </svg>
        </button>
        <input
          :value="inputValue"
          class="bg-white text-gray-700 w-full py-1 px-2 appearance-none border rounded-r focus:outline-none focus:border-blue-500"
          :required="req"
          readonly
        />
      </div>
    </template>
  </DatePicker>
</template>

<script>
import DatePicker from 'v-calendar/lib/components/date-picker.umd'

export default {
  props: {
    icon: {
      type: String,
      default: 'calendar',
    },
    type: {
      type: String,
      default: 'date',
    },
    req: {
      type: Boolean,
      default: false,
    },
    validHours: {
      type: Object,
      default: null,
    },
    disabledDates: {
      type: Object,
      default: null,
    },
    min_date: {
      type: Date,
      default: null,
    },
    max_date: {
      type: Date,
      default: null,
    },
    minuteIncrement: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      date: '',
    }
  },
  components: {
    DatePicker,
  },
  watch: {
    date: function returnDate() {
      this.$emit('changeDate', this.date)
    },
  },
}
</script>

<style lang="scss" scoped></style>
