export const mutations = {
  companyMutations(state, payload) {
    state.company = payload
    console.log('company is ->', state.company)
  },
  tokenMutations(state, payload) {
    state.token = payload
    console.log('token is ->', state.token)
  },
  tokenResetMutation(state) {
    state.token = ''
  },
  async addCompany(state, payload) {
    state.company.push(payload)
    // console.log('company is ->', state.company)
  },
  addAllCompany(state, payload) {
    state.company = payload
  },
  createBillForConfirmMutation(state, payload) {
    state.package_information = payload
  },
  BillEmailMutation(state, payload) {
    state.package_information.email = payload
  },
  packagesMutations(state, payload) {
    state.packages = payload
    console.log('packages is ->', state.packages)
  },
  getUserMutations(state, payload) {
    state.user = payload
    console.log('user is ->', state.user)
  },
  registerMutations(state, payload) {
    state.register = payload
    console.log('register is ->', state.register)
  },
  current_branchMutations(state, payload) {
    state.current_branch = payload
    console.log('current_branch is ->', state.current_branch)
  },
  current_branchUpgrades(state, payload) {
    state.current_branch.package = payload
    console.log('current_branch is ->', state.current_branch.package)
  },
  selected_packageMutations(state, payload) {
    state.selected_package = payload
    console.log('selected_package is ->', state.selected_package)
  },
  billMutations(state, payload) {
    state.bill = payload
    console.log('bill is ->', state.bill)
  },
  loadingMutations(state, payload) {
    state.loading = payload
    console.log('loading is ->', state.loading)
  },
  package_idMutations(state, payload) {
    state.package_id = payload
    console.log('package_id is ->', state.package_id)
  },
  ISWTH(state, payload) {
    state.wht = payload
  },
  TOTAL_PRICE_MUTATION(state, payload) {
    console.log('totalPrice', payload)
    state.totalPrice = payload
  },
  PACKAGE_DETAIL_MUTATION(state, payload) {
    console.log('package_detail ->', payload)
    state.package_detail = payload
  },
  ALT_BILL_MUTATION(state, payload) {
    console.log('altBill ->', payload)
    state.altBill = payload
  },

  clear(state) {
    state.register = {}
    state.user = false
    state.company = []
    state.packages = []
    state.token = ''
    state.current_branch = ''
    state.selected_package = false
    state.package_id = ''
    state.bill = {}
  },
  changeLocaleMutation(state, payload) {
    state.locale = payload
  },
  refNoMutation(state, payload) {
    state.refNo = payload
  },
}
