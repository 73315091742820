<template>
  <div>
    <div class="text-center">
      <h1 class="font-bold" v-if="!packages">
        {{ $t('feature').features }}
      </h1>
    </div>
    <b-container v-if="this.$i18n.locale === 'en'" class="mb-4 pb-3">
      <b-row class="justify-content-center justify-content-center">
        <b-col cols="0"></b-col>
        <b-col cols="10">
          <b-row>
            <b-col xl="4" lg="6" md="6" sm="12">
              <img src="@/assets/features/en/1.png" class="shadow-border my-2 w-100" alt="feature" />
            </b-col>
            <b-col xl="4" lg="6" md="6" sm="12">
              <img src="@/assets/features/en/2.png" class="shadow-border my-2 w-100" alt="feature" />
            </b-col>
            <b-col xl="4" lg="6" md="6" sm="12">
              <img src="@/assets/features/en/3.png" class="shadow-border my-2 w-100" alt="feature" />
            </b-col>
            <b-col xl="4" lg="6" md="6" sm="12">
              <img src="@/assets/features/en/4.png" class="shadow-border my-2 w-100" alt="feature" />
            </b-col>
            <b-col xl="4" lg="6" md="6" sm="12">
              <img src="@/assets/features/en/5.png" class="shadow-border my-2 w-100" alt="feature" />
            </b-col>
            <b-col xl="4" lg="6" md="6" sm="12">
              <img src="@/assets/features/en/6.png" class="shadow-border my-2 w-100" />
            </b-col>
            <!-- <b-col xl="4" lg="6" md="6" sm="12">
              <img
                src="@/assets/features/en/7.png"
                class="shadow-border my-2 w-100"
              />
            </b-col>
            <b-col xl="4" lg="6" md="6" sm="12">
              <img
                src="@/assets/features/en/8.png"
                class="shadow-border my-2 w-100"
              />
            </b-col> -->
          </b-row>
        </b-col>
        <b-col cols="0"></b-col>
      </b-row>
      <b-row> </b-row>
    </b-container>
    <b-container v-else class="mb-4 pb-3">
      <b-row>
        <b-col> </b-col>
        <b-col cols="12">
          <b-row>
            <b-col xl="4" lg="6" md="6" sm="12">
              <img src="@/assets/features/th/1.png" class="shadow-border my-2 w-100" alt="feature" />
            </b-col>
            <b-col xl="4" lg="6" md="6" sm="12">
              <img src="@/assets/features/th/2.png" class="shadow-border my-2 w-100" alt="feature" />
            </b-col>
            <b-col xl="4" lg="6" md="6" sm="12">
              <img src="@/assets/features/th/3.png" class="shadow-border my-2 w-100" alt="feature" />
            </b-col>
            <b-col xl="4" lg="6" md="6" sm="12">
              <img src="@/assets/features/th/4.png" class="shadow-border my-2 w-100" alt="feature" />
            </b-col>
            <b-col xl="4" lg="6" md="6" sm="12">
              <img src="@/assets/features/th/5.png" class="shadow-border my-2 w-100" alt="feature" />
            </b-col>
            <b-col xl="4" lg="6" md="6" sm="12">
              <img src="@/assets/features/th/6.png" class="shadow-border my-2 w-100" alt="feature" />
            </b-col>
            <!-- <b-col xl="4" lg="6" md="6" sm="12">
              <img
                src="@/assets/features/th/7.png"
                class="shadow-border my-2 w-100"
                alt="feature"
              />
            </b-col>
            <b-col xl="4" lg="6" md="6" sm="12">
              <img
                src="@/assets/features/th/8.png"
                class="shadow-border my-2 w-100"
                alt="feature"
              />
            </b-col> -->
          </b-row>
        </b-col>
        <b-col> </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import i18n from '@/plugins/i18n'

export default {
  data() {
    return {
      locale: i18n.locale,
      // th:"assets/features/TH",
    }
  },
  computed: {
    // thai() {
    //     return `../assets/features/TH`
    // }
  },
  props: {
    packages: Boolean,
  },
  created() {
    this.locale = this.$i18n.locale
  },
}
</script>

<style scoped>
h1 {
  color: #024ea1;
  font-size: 36px;
  font-weight: 700;
}
p {
  font-size: 16px;
}
</style>
