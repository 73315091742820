<template>
  <div class="h-100 success">
    <div class="cus-positon">
      <h2 class="mb-3">คู่มือการใช้งาน VERSCAN</h2>
      <h2 class="mb-5">VERSCAN MANUAL</h2>
      <img class="mb-5" src="@/assets/material/logo-verscan.png" alt="logo" />
      <div class="flex flex-wrap justify-content-center">
        <a class="m-3" href="https://api.verscan.com/download/verscan_manual_TH_V_2.pdf"
          ><button class="cus-btn">ดาวน์โหลดคู่มือ</button></a
        >
        <a class="m-3" href="https://api.verscan.com/download/verscan_manual_EN_V_2.pdf"
          ><button class="cus-btn">Download Manual</button></a
        >
      </div>
      <!-- <a href="./assets/manual/ทดสอบ.pdf" download><button class="mb-5 cus-btn">คู่มือภาษาไทย</button></a> -->
    </div>
  </div>
</template>
<script>
export default {
  name: 'Manual',
  components: {},
  methods: {},
}
</script>

<style scoped>
.cus-positon {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  height: 100vh;
  justify-content: center;
}
.cus-btn {
  background-color: #ffffff;
  color: #007afe;
  font-size: 1.2rem;
  border-radius: 10px;
  width: 15rem;
  height: 5rem;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}
</style>
