<template>
  <div>
    <div v-if="!loading">
      <div class="grid w-full justify-content-center gap-y-4 mt-4 px-3">
        <h1 class="text-2xl font-bold">
          {{ $t('payment_method').method }}
        </h1>
        <Seperator class="justify-self-center" />
        <b-col cols="12" class="d-flex justify-content-center">
          <Card
            :label="$t('payment_method.select')"
            filename="paysolution.png"
            :width="200"
            :height="50"
            @click.native="paySolution()"
            class="pointer"
          />
        </b-col>
        <b-col cols="12" class="d-flex justify-content-center">
          <Card
            :label="$t('payment_method.bank')"
            :width="150"
            :height="50"
            filename="bank_transfer.svg"
            @click.native="toBankTransfer()"
            class="pointer"
          />
        </b-col>

        <Button
          btnColor="white-btn"
          :text="$t('payment_method').back"
          @click.native="toPayment()"
          class="w-2/3 justify-self-center"
        />
      </div>
      <Footer class="mt-5" />
    </div>
    <div v-else class="loading d-flex h-screen">
      <Loading white class="m-auto" />
    </div>
  </div>
</template>

<script>
import Card from '../../components/Payment_method/image_card.vue'
import Button from '../../components/general/general_button.vue'
import Seperator from '../../components/general/seperate_line.vue'
import Loading from '../../components/general/loading.vue'

// import PaymentDetail from "../../components/payment_detail_confirm.vue";
import { mapGetters } from 'vuex'
import { find } from 'lodash'
import Footer from '../../components/footer.vue'

export default {
  name: 'paymentMethod',
  components: {
    Card,
    Button,
    Seperator,
    Loading,
    Footer,
    // PaymentDetail,
  },
  mounted() {
    this.$store.commit('loadingMutations', false)
    window.scrollTo(0, 0)
  },
  computed: {
    ...mapGetters({
      packages: 'getPackages',
      user: 'getUser',
      currentPackageId: 'getPackage_id',
      packageDetail: 'getPackageDetail',
      loading: 'getLoading',
      bill: 'getBill',
    }),
    plan: function () {
      return find(this.packages, { package_id: this.currentPackageId })
    },
  },
  methods: {
    paySolution() {
      this.$store.dispatch('paySolution', {
        package_id: this.currentPackageId,
        pay_type: 'paysolution',
        productdetail:
          (this.packageDetail?.addOn?.preparation
            ? 'บริการจัดทำ QR-code บนวัสดุชนิดต่างๆ เช่น กระดาษกันน้ำ, การเคลือบพลาสติก, แผ่นโลหะ, อื่นๆ, '
            : '') +
          (this.packageDetail?.addOn?.installation
            ? 'บริการทีมงานติดตั้ง QR-Code เพื่อระบุสินทรัพย์, '
            : '') +
          (this.packageDetail?.addOn?.data_transfer
            ? 'บริการโอนย้ายข้อมูลจากระบบ ERP เดิมขององค์กร, '
            : '') +
          (this.packageDetail?.addOn?.additional
            ? 'บริการเพิ่มฟีเจอร์อื่นๆ ที่เกี่ยวข้อง เพื่อให้ตอบโจทย์กับองค์กรของคุณ '
            : ''),
        bill_detail: this.bill,
      })
    },
    toBankTransfer() {
      this.$router.push('/banktransfer')
    },
    toPayment() {
      this.$router.push('/paymentConfirm')
    },
  },
}
</script>

<style scoped>
/* h1 {
} */
.card {
  width: 300px;
}
</style>
