<template>
  <div>
    <b-container class="py-4">
      <b-row class="justify-content-center">
        <b-col class="white-box text-center py-4" cols="10">
          <b-row align-h="center">
            <img src="@/assets/material/light_blue_check.svg" align-self="center" alt="check"/>
          </b-row>
          <h1 class="my-4" :v-html="$t('payment_success').success"></h1>
          <b-row class="my-4" align-h="center">
            <Seperator blue />
          </b-row>
          <h2 class="my-4">{{ $t('payment_success').thanks }} {{ this.refNo }}</h2>
          <h2 class="my-4">{{ $t('payment_success').start }}</h2>

          <h3 v-if="$route.name != 'TrialPaymentSuccess'">{{ $t('payment_success').qr }}</h3>
          <h3 v-if="$route.name != 'TrialPaymentSuccess'" class="mt-2 mb-5">
            {{ $t('payment_success').send }}
          </h3>
          <h3 class="mb-2 note">{{ $t('payment_success').note }}</h3>
          <p class="my-4">{{ $t('payment_success').tax }}</p>
          <b-row align-h="center">
            <b-col class="white-box text-center py-4 grey-box" cols="10" sm="10" md="8" lg="6">
              <p class="">{{ $t('payment_success').hod }}</p>
              <p class="">{{ $t('payment_success').address1 }}</p>
              <p class="">{{ $t('payment_success').address2 }}</p>
            </b-col>
          </b-row>
          <b-row class="mt-2" align-h="center">
            <b-col cols="10" sm="10" md="8" lg="6">
              <b-row class="mt-4 gap-y-3">
                <b-col>
                  <b-button variant="outline-primary" @click="toCompany"
                    >{{ $t('payment_success').back }}
                  </b-button>
                </b-col>
                <b-col>
                  <b-button variant="primary" @click="toVerscan">
                    {{ $t('payment_success').use }}
                  </b-button>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
    <Footer />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Seperator from '../components/general/seperate_line.vue'
import Footer from '../components/footer.vue'

export default {
  name: 'PaymentSuccess',
  components: {
    Seperator,
    Footer,
  },
  mounted() {
    // this.$store.commit("billMutations", {});
    // this.$store.commit("createBillForConfirmMutation", null);
    this.$store.commit('PACKAGE_DETAIL_MUTATION', false)
    // this.$store.dispatch("totalPriceAction", 0);
    // this.deleting = true
    this.$store.dispatch('getRef')
  },
  computed: {
    ...mapGetters({
      refNo: 'getRefNo',
    }),
  },
  methods: {
    toVerscan() {
      this.$router.push('/toverscan')
    },
    toCompany() {
      this.$router.push('/companyselect')
    },
  },
}
</script>

<style scoped>
.white-box {
  background: #ffffff;
  border-radius: 10px;
  color: #007afe;
}
.grey-box {
  background: #ededed;
}
img {
  width: 90px;
}
h1 {
  font-size: 24px;
  color: #013f81;
}
h2 {
  font-weight: 500;
  font-size: 17px !important;
  color: #4b4b4b;
}
h3 {
  font-size: 15px !important;
  color: #4b4b4b;
}
.note {
  color: #013f81;
  font-weight: 500;
}
p {
  font-size: 12px;
  color: #4b4b4b;
}
</style>