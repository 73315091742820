<template>
  <div>
    <b-row>
      <!-- <b-col class="md:block hidden"></b-col> -->
      <b-col cols="auto" class="text-start">
        <h2 style="font-size: 18px !important">
          {{ $t('footer.contact_us') }}
        </h2>

        <div class="mb-2 mt-3">
          <div class="flex mb-2">
            <img
              src="../../assets/material/line_icon.png"
              width="30"
              height="30"
              class="mr-4 cursor-pointer"
              alt="icon"
              @click="toExternal('https://line.me/ti/p/~@verscan')"
            />
            <p
              class="mb-1 my-auto cursor-pointer"
              @click="toExternal('https://line.me/ti/p/~@verscan')"
            >
              @VERSCAN
            </p>
          </div>
          <div class="flex mb-2">
            <img
              src="../../assets/material/tel_icon.png"
              width="30"
              height="30"
              class="mr-4"
              alt="icon"
            />
            <p class="mb-1 my-auto">{{ $t('footer.tel') }}</p>
          </div>
          <div class="flex mb-2">
            <img
              src="../../assets/material/mail_icon.png"
              width="30"
              height="30"
              class="mr-4"
              alt="icon"
            />
            <p class="mb-1 my-auto">service@verscan.com</p>
          </div>
          <div class="flex mb-2 items-start">
            <img
              src="../../assets/material/map_pin_icon.png"
              width="30"
              height="30"
              class="mr-4 object-contain"
              alt="icon"
            />
            <div>
              <p class="mb-1 my-auto" v-html="$t('footer.hod')" />
              <p class="mb-1" v-html="$t('footer.address')"></p>
              <p class="mb-1">{{ $t('footer.tel2') }}</p>
              <p class="mb-1">{{ $t('footer.open') }}</p>
              <p class="mb-1">{{ $t('footer.tax_number') }}</p>
            </div>
          </div>
        </div>

        <b-row align-h="start" class="my-3">
          <b-col class="d-flex justify-content-start">
            <img
              src="../../assets/material/likind.png"
              width="30"
              height="30"
              class="cursor-pointer mr-4"
              @click="toExternal('https://www.linkedin.com/company/houseofdevtech/')"
              alt="icon"
            />
            <img
              src="../../assets/material/fb.png"
              width="30"
              height="30"
              class="cursor-pointer mr-4"
              @click="toExternal('https://www.facebook.com/houseofdevtech')"
              alt="icon"
            />
            <img
              src="../../assets/material/ig.png"
              width="30"
              height="30"
              class="cursor-pointer mr-4"
              @click="toExternal('https://www.instagram.com/houseofdevtech/')"
              alt="icon"
            />
            <img
              src="../../assets/material/line.png"
              width="30"
              height="30"
              class="cursor-pointer"
              @click="toExternal('https://line.me/ti/p/~@verscan')"
              alt="icon"
            />
          </b-col>
        </b-row>
      </b-col>
      <!-- <b-col class="md:block hidden"></b-col> -->
    </b-row>
    <b-row>
      <b-col></b-col>
      <b-col cols="12" class="text-center text-md-start my-4 d-md-none">
        <b-row class="d-flex flex-column align-items-start flex-md-row">
          <!-- <li @click="goToTerm('term')">{{$t("footer.terms_of_use")}}</li> -->
          <li @click="goToTerm('policy')">{{ $t('footer.privacy_policy') }}</li>
          <li @click="goToTerm('data')">{{ $t('footer.data_deletion') }}</li>
          <li @click="goToTerm('sla')">{{ $t('footer.sla') }}</li>
        </b-row>
      </b-col>
      <b-col></b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  setup() {},
  methods: {
    toExternal(link) {
      window.open(link, '_blank')
    },
    goToTerm(name) {
      let route = {}
      if (name === 'term') {
        route = this.$router.resolve({ name: 'Terms' })
      }
      if (name === 'sla') {
        route = this.$router.resolve({ name: 'SLA' })
      }
      if (name === 'policy') {
        route = this.$router.resolve({ name: 'Policy' })
      }
      if (name === 'data') {
        route = this.$router.resolve({ name: 'DataDelete' })
      }
      window.open(route.href, '_blank')
    },
  },
}
</script>

<style scoped>
p {
  font-size: 12px;
}

li {
  font-size: 12px;
  width: fit-content;
  cursor: pointer;
}

@media only screen and (min-width: 1535px) {
}
@media only screen and (max-width: 1199px) {
}
@media only screen and (max-width: 767px) {
}
@media only screen and (max-width: 575px) {
}
@media only screen and (max-width: 424px) {
}
</style>
