const stage = () => {
  const url = window.location.hostname
  switch (url) {
    case 'verscan.com':
      return 'production'
    case 'staging.verscan.com':
      return 'uat'
    case 'dev.verscan.com':
      return 'dev'
    case 'localhost':
      return 'local'
    default:
      return null
  }
}
export const baseUrl = () => {
  switch (stage()) {
    case 'production':
      return 'https://api.verscan.com/register/'
    case 'uat':
      return 'https://api.verscan.com/register-uat/'
    case 'dev':
      return 'https://api.verscan.com/register-dev/'
    case 'local':
      return 'https://api.verscan.com/register-dev/'
    default:
      return 'http://localhost:4000/'
  }
}
export default baseUrl()
