<template>
  <div>
    <div class="flex justify-between">
      <label class="flex text-gray-50 font-bold mb-1 2">
        {{ label }} <span style="color: red" v-if="required">*</span>
      </label>
      <slot></slot>
    </div>
    <div class="position-relative">
      <input
        :placeholder="placeholder ? placeholder : label"
        :type="type"
        :value="value"
        :disabled="disabled"
        class="flex w-full py-1"
        :class="this.noBorder ? 'noborder' : 'px-2 flex w-full'"
        :required="required"
        :pattern="this.pattern"
        @input="onInput"
        :maxlength="maxlength"
        :minlength="minlength"
        :autocomplete="autoComplete"
      />
      <slot name="icon"></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    type: String,
    label: String,
    value: [Number, String],
    disabled: Boolean,
    required: Boolean,
    noBorder: Boolean,
    pattern: String,
    maxlength: String,
    minlength: String,
    placeholder: String,
    autoComplete: {
      type: String,
      required: false,
    },
  },
  methods: {
    onInput(event) {
      this.$emit('input', event.target.value)
    },
  },
}
</script>

<style scoped>
input {
  background: #ffffff;
  border: 1px solid #015fc3;
  box-sizing: border-box;
  border-radius: 8px;
  color: #333333;
}

.noborder {
  outline: none;
  border: 1px solid #ffffff;
  box-sizing: border-box;
  border-radius: 8px;
  color: #777;
}
label {
  font-weight: bold;
  color: #015fc3;
  font-size: 16px;
}
</style>
