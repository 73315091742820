<template>
  <div>
    <form class="py-10 grid justify-items-center" v-on:submit.prevent="confirm">
      <div class="grid w-2/3 sm:w-2/3 md:w-1/4 lg:1/5 xl:1/8 gap-y-2 justify-items-center">
        <h1 class="font-bold text-lg md:text-3xl justify-self-start">
          {{ $t('change_password').change }}
        </h1>
        <Inputbar
          :label="$t('change_password').password"
          :type="showNewPassword ? 'text' : 'password'"
          v-model="password"
          blueLabel
          required
          class="w-full"
        >
          <template v-slot:icon>
            <img
              v-if="showNewPassword"
              @click="showNewPassword = !showNewPassword"
              src="../assets/material/showpassword.png"
              alt="showpassword"
              class="password-toggler"
            />
            <img
              v-else
              @click="showNewPassword = !showNewPassword"
              src="../assets/material/hidepassword.png"
              alt="showpassword"
              class="password-toggler"
            />
          </template>
        </Inputbar>
        <Inputbar
          :label="$t('change_password').confirm"
          :type="showConfirmPassword ? 'text' : 'password'"
          v-model="confirmPassword"
          blueLabel
          required
          class="w-full py-4"
        >
          <template v-slot:icon>
            <img
              v-if="showConfirmPassword"
              @click="showConfirmPassword = !showConfirmPassword"
              src="../assets/material/showpassword.png"
              alt="showpassword"
              class="password-toggler"
            />
            <img
              v-else
              @click="showConfirmPassword = !showConfirmPassword"
              src="../assets/material/hidepassword.png"
              alt="showpassword"
              class="password-toggler"
            />
          </template>
        </Inputbar>
        <Button
          btnColor="gradient-btn"
          :text="$t('change_password').save"
          type="submit"
          class="w-4/5 md:w-3/10"
        />
      </div>
    </form>
    <Footer />
  </div>
</template>

<script>
import axios from 'axios'
import { baseUrl } from '../utils/backend'
import Inputbar from '../components/general/form_input.vue'
import Button from '../components/general/general_button.vue'
import Footer from '../components/footer.vue'
import { sameAs } from 'vuelidate/lib/validators'

export default {
  name: 'changePassword',
  data() {
    return {
      password: '',
      confirmPassword: '',
      showNewPassword: false,
      showConfirmPassword: false,
    }
  },
  methods: {
    async confirm(e) {
      e.preventDefault()
      if (this.$v.$invalid) {
        alert(this.$t('change_password').not_match)
        this.confirmPassword.setCustomValidity("Passwords Don't Match")
        return false // stop here if form is invalid
      } else {
        try {
          await axios
            .put(
              `${baseUrl()}changepassword`,
              {
                user_id: this.$route.query.user_id,
                password: this.password,
              },
              {
                headers: {
                  Authorization: `Bearer ${this.$route.query.token}`,
                },
              }
            )
            .then(async (response) => {
              if (response.status == 200) {
                console.log(response)
                this.$router.push('/home')
              } else if (response.status == 400) {
                alert(response.data.message)
              }
            })
        } catch (error) {
          console.error(error)
        }
      }
    },
  },
  validations: {
    confirmPassword: { sameAsPassword: sameAs('password') },
  },
  components: {
    Inputbar,
    Button,
    Footer,
  },
}
</script>

<style scoped>
form {
  background: white;
}
h1 {
  color: #024ea1;
}

.password-toggler {
  position: absolute;
  width: 15px;
  height: auto;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}
</style>
