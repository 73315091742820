import axios from 'axios'
import router from '../router'
import { formPaysolution } from '../utils/paysolutions'
import Cookie from 'js-cookie'
import Swal from 'sweetalert2'
import { baseUrl } from '../utils/backend'
import i18n from '../plugins/i18n'

// import i18n from '../plugins/i18n'

export const actions = {
  async login({ commit, dispatch }, data) {
    try {
      const response = await axios.post(`${baseUrl()}user/login`, data)
      if (response.data.data.user_id) {
        localStorage.setItem('userId', response.data.data.user_id)
        localStorage.setItem('access_token', response.data.data.access_token)
        localStorage.setItem('refresh_token', response.data.data.refresh_token)
        commit('tokenMutations', response.data.data.access_token)
        await dispatch('getUser', response.data.data.user_id)
        return true
      }

      if (response.data.data.data.id_user) {
        const customName = response.data.data.custom
        const { access_token, refresh_token, id_user } = response.data.data.data
        Cookie.set(
          'cookielogin',
          JSON.stringify({
            id_user: id_user,
            access_token: access_token,
            refresh_token: refresh_token,
          }),
          {
            expires: 7,
            domain: location.hostname.endsWith('.verscan.com') ? '.verscan.com' : 'localhost',
          }
        )
        const redirectTo = getAppLinkByStage()
        if (customName) {
          window.location.href = `${redirectTo}?customName=${customName}`
        } else {
          window.location.href = redirectTo
        }
        return
      }
    } catch (error) {
      commit('loadingMutations', false)
      console.error(error)
      if (error.response.data) {
        if (error.response.data.message === 'email is not correct') {
          alert('Email or password is incorrect')
        } else {
          alert(error.response?.data?.message)
        }
      } else {
        alert(error)
      }
    }
  },
  async getUser({ dispatch, commit, getters }, id) {
    try {
      commit('loadingMutations', true)
      await axios
        .get(`${baseUrl()}api/user/?user_id=${id}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        })
        .then(async (response) => {
          if (response.status == 200) {
            let user = response.data.message.user
            let companies = response.data.message.user.user_relate_companies.filter(
              (company) => company.company_info !== null
            )
            companies = companies.map((company) => {
              return {
                name: company.company_info.company_name,
                company_id: company.company_info.company_id,
                branches: company.company_info.branch_infos,
              }
            })
            commit('addAllCompany', companies)
            if (response.data.token) {
              const value = JSON.stringify(response.data.token)
              Cookie.set('cookielogin', value, {
                expires: 7,
                domain: location.hostname.endsWith('.verscan.com') ? '.verscan.com' : 'localhost',
              })
            }
            commit('getUserMutations', user)
            dispatch('getPackages')
            // await dispatch('getCompanies', companies)
            commit('loadingMutations', false)
          } else if (response.status == 400) {
            alert(response.data.message)
            commit('loadingMutations', false)
          }
        })
        .catch((err) => {
          if (
            err.response.data.message.toString() == 'jwt expired' ||
            err.response.data.message.toString() == 'Invalid token' ||
            err.response.data.message.toString() == 'no permission'
          ) {
            dispatch('logout')
            commit('loadingMutations', false)
          } else {
            alert(err.response.data.message.toString())
          }
        })
    } catch (error) {
      console.error(error)
      commit('loadingMutations', false)
    }
  },
  async getCompanies({ commit, dispatch, getters }, data) {
    commit('companyMutations', [])
    data.map(async (company_id) => {
      await axios
        .get(
          `${baseUrl()}api/company/?company_id=${company_id}&user_id=${localStorage.getItem(
            'userId'
          )}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            },
          }
        )
        .then((response) => {
          if (response.status == 200) {
            let company = {
              name: response.data.data.company.company_name,
              company_id: response.data.data.company.company_id,
              branches: response.data.data.company.branch_infos,
            }
            if (
              !find(getters.getCompany, {
                company_id: company.company_id,
              })
            ) {
              commit('addCompany', company)
            }
          } else if (response.status == 400) {
            alert(response.data.message)
          }
        })
        .catch((err) => {
          if (
            err.response.data.message.toString() == 'jwt expired' ||
            err.response.data.message.toString() == 'Invalid token' ||
            err.response.data.message.toString() == 'no permission'
          ) {
            dispatch('logout')
            commit('loadingMutations', false)
          } else {
            alert(err.response.data.message.toString())
          }
        })
    })
  },
  async getPackages({ commit, dispatch }) {
    await axios
      .get(`${baseUrl()}packages`)
      .then((response) => {
        if (response.status == 200) {
          commit('packagesMutations', response.data.message.packages)
        } else if (response.status == 400) {
          alert(response.data.message)
        }
      })
      .catch((err) => {
        if (
          err.response.data.message.toString() == 'jwt expired' ||
          err.response.data.message.toString() == 'Invalid token' ||
          err.response.data.message.toString() == 'no permission'
        ) {
          dispatch('logout')
          commit('loadingMutations', false)
        } else {
          alert(err.response.data.message.toString())
        }
      })
  },
  async register({ commit, getters, dispatch }, data) {
    try {
      await axios
        .post(`${baseUrl()}check_companyname`, {
          company_name: data.company_name,
        })
        .then((response) => {
          if (response.status == 200) {
            if (response.data.message === 'Company name is unique.') {
              axios
                .post(`${baseUrl()}create_user`, data)
                .then(async (response) => {
                  if (response.status == 200) {
                    console.log('register response-->', response.data)
                    commit('current_branchMutations', response.data.message)
                    await Swal.fire({
                      icon: 'success',
                      text: i18n.t('register').success,
                      confirmButtonText: 'ยืนยัน',
                      confirmButtonColor: '#007AFE',
                    })
                    dispatch('login', {
                      email: data.email.toLowerCase(),
                      password: data.password,
                    }).then(() => {
                      if (getters.getPackage_id) {
                        dispatch('getUser', localStorage.getItem('userId')).then(() => {
                          router.push('/payment')
                        })
                      } else {
                        router.push('/companyselect')
                      }
                    })
                  } else if (response.status == 400) {
                    alert(response.data.message)
                  }
                })
                .catch((err) => {
                  alert(err.response.data.message.toString())
                })
            } else if (response.data.message === 'Company Name is exist.') {
              alert('This company is already exist')
            }
          } else if (response.status == 400) {
            alert(response.data.message)
          }
        })
        .catch((err) => {
          alert(err.response.data.message.toString())
        })
    } catch (error) {
      console.error(error)
      dispatch('logout')
    }
    return
  },
  async altRegister({ commit, dispatch }, body) {
    try {
      return await axios
        .post(`${baseUrl()}regisinfo`, body)
        .then(async (response) => {
          console.log('response ->', response)
          if (response.status == 200) {
            if (response.data.status == 'success') {
              await localStorage.setItem('userId', response.data.message.user_id)
              await localStorage.setItem('access_token', response.data.message.access_token)
              await localStorage.setItem('refresh_token', response.data.message.refresh_token)
              commit('BillEmailMutation', body.email)
              await dispatch('getUser', response.data.message.user_id)
              commit('tokenMutations', response.data.message.access_token)
              commit('current_branchMutations', {
                company_id: response.data.message.company_id,
                company_name: response.data.message.company_name,
                branch_id: response.data.message.branch_id,
                branch_no: response.data.message.branch_no,
                branch_name: 'Head Office',
              })
              return true
            } else {
              console.log(response)
              return false
            }
          }
        })
        .catch((err) => {
          commit('loadingMutations', false)
          alert(err.response.data.message.toString())
          console.error(err)

          return false
        })
    } catch (error) {
      alert(error.response.data.message.toString())
      return false
    }
  },
  async altLogin({ dispatch, commit }, data) {
    console.log('data ->', data)
    const o = data

    try {
      await axios
        .post(`${baseUrl()}user/login`, {
          email: data.email,
          password: data.password,
        })
        .then(async (response) => {
          console.log('response ->', response)
          if (response.status == 200) {
            await localStorage.setItem('userId', response.data.data.user_id)
            await localStorage.setItem('access_token', response.data.data.access_token)
            await localStorage.setItem('refresh_token', response.data.data.refresh_token)
            commit('tokenMutations', response.data.data.access_token)
            await dispatch('getUser', response.data.data.user_id)
            console.log('body ->', body)
            let body = {
              user_id: response.data.data.user_id,
              company_name: o.company_name,
              bill_detail: {
                taxNumber: o.bill_detail.taxNumber,
                email: o.email,
                address1: o.bill_detail.address1,
                address2: o.bill_detail.address2,
                city: o.bill_detail.city,
                country: o.bill_detail.country,
                postal: o.bill_detail.postal,
              },
            }
            try {
              await axios
                .post(`${baseUrl()}create_company`, body, {
                  headers: {
                    Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                  },
                })
                .then(async (response) => {
                  if (response.status == 200) {
                    commit('current_branchMutations', {
                      company_id: response.data.company.company_id,
                      company_name: response.data.company.company_name,
                      branch_id: response.data.branch.branch_id,
                      branch_no: response.data.branch.branch_no,
                      branch_name: 'Head Office',
                    })
                    commit('BillEmailMutation', data.email)
                    return true
                  } else if (response.status == 400) {
                    alert(response.data.message)
                    commit('loadingMutations', false)
                  }
                })
                .catch((err) => {
                  if (
                    err.response.data.message.toString() == 'jwt expired' ||
                    err.response.data.message.toString() == 'Invalid token' ||
                    err.response.data.message.toString() == 'no permission'
                  ) {
                    dispatch('logout')
                    commit('loadingMutations', false)
                  } else {
                    alert(err.response.data.message.toString())
                  }
                })
            } catch (error) {
              alert(error.response.data.message.toString())
              console.error(error)
            }

            return true
          }
        })
        .catch((err) => {
          commit('loadingMutations', false)
          alert(err.response.data.message.toString())
          return false
        })
    } catch (error) {
      console.error(error)
    }
  },
  async createBranch({ dispatch, commit }, body) {
    try {
      await axios
        .post(`${baseUrl()}create_company`, body, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        })
        .then(async (response) => {
          if (response.status == 200) {
            commit('companyMutations', [])
            await dispatch('getUser', localStorage.getItem('userId')).then(() => {
              commit('loadingMutations', false)
            })
          } else if (response.status == 400) {
            alert(response.data.message)
            commit('loadingMutations', false)
          }
        })
        .catch((err) => {
          if (
            err.response.data.message.toString() == 'jwt expired' ||
            err.response.data.message.toString() == 'Invalid token' ||
            err.response.data.message.toString() == 'no permission'
          ) {
            dispatch('logout')
            commit('loadingMutations', false)
          } else {
            alert(err.response.data.message.toString())
          }
        })
    } catch (error) {
      alert(error.response.data.message.toString())
      console.error(error)
    }
  },
  async editBranch({ dispatch, commit }, body) {
    try {
      await axios
        .put(
          `${baseUrl()}company/update?company_id=${body.company_id}&user_id=${localStorage.getItem(
            'userId'
          )}&branch_id=${body.detail.branch_id}`,
          body,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            },
          }
        )
        .then(async (response) => {
          if (response.status == 200) {
            commit('companyMutations', [])
            await dispatch('getUser', localStorage.getItem('userId'))
          } else if (response.status == 400) {
            alert(response.data.message)
          }
        })
        .catch((err) => {
          if (
            err.response.data.message.toString() == 'jwt expired' ||
            err.response.data.message.toString() == 'Invalid token' ||
            err.response.data.message.toString() == 'no permission'
          ) {
            dispatch('logout')
            commit('loadingMutations', false)
          } else {
            alert(err.response.data.message.toString())
          }
        })
    } catch (error) {
      alert(error.response.data.message.toString())
      console.error(error)
    }
  },
  async createBill({ getters, commit, dispatch }, data) {
    let form = {
      info_type: 'other',
      TAX_ID: data.taxNumber,
      billing_email: data.email.toLowerCase(),
      billing_address_1: data.address1,
      billing_address_2: data.address2,
      city: data.city,
      country: data.country,
      zipcode: data.postal,
    }

    try {
      commit('billMutations', form)
      await axios
        .post(
          `${baseUrl()}create_bill?user_id=${localStorage.getItem('userId')}&company_id=${
            getters.getCurrent_branch.company_id
          }&branch_id=${getters.getCurrent_branch.branch_id}`,
          form,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            },
          }
        )
        .then((response) => {
          if (response.status == 200) {
            return true
          } else if (response.status == 400) {
            alert(response.data.message)
          }
        })
        .catch((err) => {
          if (
            err.response.data.message.toString() == 'jwt expired' ||
            err.response.data.message.toString() == 'Invalid token' ||
            err.response.data.message.toString() == 'no permission'
          ) {
            dispatch('logout')
            commit('loadingMutations', false)
          } else {
            alert(err.response.data.message.toString())
          }
        })
    } catch (error) {
      console.error(error)
    }
  },
  async createBillForConfirm({ commit }, payload) {
    commit('createBillForConfirmMutation', payload)
  },
  async trial({ getters, commit }, data) {
    commit('loadingMutations', true)
    try {
      await axios
        .post(
          `${baseUrl()}create_order?user_id=${localStorage.getItem('userId')}&company_id=${
            getters.getCurrent_branch.company_id
          }&branch_id=${getters.getCurrent_branch.branch_id}`,
          {
            pay_type: 'freetrial',
            package_id: null,
            bill_detail: data,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            },
          }
        )
        .then((response) => {
          if (response.status == 200) {
            commit('loadingMutations', false)
            return true
          } else if (response.status == 400) {
            commit('loadingMutations', false)
            alert(response.data.message)
          }
        })
        .catch((err) => {
          console.log(err)
        })
    } catch (error) {
      console.error(error)
    }
    return
  },
  async paySolution({ getters, commit }, data) {
    commit('loadingMutations', true)

    try {
      await axios
        .post(
          `${baseUrl()}create_order?user_id=${localStorage.getItem('userId')}&company_id=${
            getters.getCurrent_branch.company_id
          }&branch_id=${getters.getCurrent_branch.branch_id}`,
          data,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            },
          }
        )
        .then(async (response) => {
          if (response.status == 200) {
            let form = response.data.data
            console.log(form)
            await formPaysolution({
              refNo: form.refno,
              total:
                window.location.hostname == 'staging.verscan.com' ||
                window.location.hostname == 'dev.verscan.com' ||
                window.location.hostname == 'localhost'
                  ? 1
                  : getters.getTotalPrice,
              detail: form.productdetail,
            })
            commit('loadingMutations', false)

            commit('package_id', '')
          } else if (response.status == 400) {
            alert(response.data.message)
            commit('loadingMutations', false)
          }
        })
        .catch((err) => {
          console.log(err)
          commit('loadingMutations', false)
        })
    } catch (error) {
      console.error(error)
      commit('loadingMutations', false)
    }
  },
  async upgradeBranch({ commit }, name) {
    commit('current_branchUpgrades', name)
  },
  async bankTransfer({ commit, getters, dispatch }, data) {
    commit('loadingMutations', true)
    try {
      return await axios
        .post(
          `${baseUrl()}create_order?user_id=${localStorage.getItem('userId')}&company_id=${
            getters.getCurrent_branch.company_id
          }&branch_id=${getters.getCurrent_branch.branch_id}`,
          data,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            },
          }
        )
        .then(async (response) => {
          if (response.status == 200) {
            commit('selected_package', data.package_id)
            commit('companyMutations', [])
            commit('package_id', '')
            commit('loadingMutations', false)
            return true
          } else if (response.status == 400) {
            alert(response.data.message)
            commit('loadingMutations', false)
            return false
          }
        })
        .catch((err) => {
          if (
            err.response.data.message.toString() == 'jwt expired' ||
            err.response.data.message.toString() == 'Invalid token' ||
            err.response.data.message.toString() == 'no permission'
          ) {
            dispatch('logout')
            commit('loadingMutations', false)
            return false
          } else {
            alert(err.response.data.message.toString())
            commit('loadingMutations', false)
            return false
          }
        })
    } catch (error) {
      console.error(error)
    }
    return
  },
  async deleteBranch({ dispatch }, data) {
    try {
      await axios
        .put(
          `${baseUrl()}branch/delete?user_id=${localStorage.getItem('userId')}&company_id=${
            data.company_id
          }&branch_id=${data.branch_id}`,
          data,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            },
          }
        )
        .then((response) => {
          if (response.status == 200) {
            dispatch('getUser', localStorage.getItem('userId')).then(() => {
              router.push('/companyselect')
            })
          } else if (response.status == 400) {
            alert(response.data.message)
          }
        })
        .catch((err) => {
          console.log(err)
        })
    } catch (error) {
      console.error(error)
    }
  },
  async getRef({ commit, getters }) {
    console.log('getters.getCurrent_branch.company_id', getters.getCurrent_branch.company_id)
    try {
      await axios
        .get(
          `${baseUrl()}getrefno?user_id=${localStorage.getItem('userId')}&package_id=${
            getters.getPackage_id
          }&branch_id=${getters.getCurrent_branch.branch_id}`
        )
        .then((response) => {
          if (response.status == 200) {
            console.log('res-->', response.data.message.response.refno)
            commit('refNoMutation', response.data.message.response.refno)
            commit('package_idMutations', false)
          } else if (response.status == 400) {
            alert(response.data.message)
          }
        })
        .catch((err) => {
          console.log(err)
        })
    } catch (error) {
      console.error(error)
    }
  },
  totalPriceAction({ commit }, payload) {
    commit('TOTAL_PRICE_MUTATION', payload)
  },
  async contactUs({ commit }, payload) {
    commit('loadingMutations', true)
    return await axios
      .post(`${baseUrl()}email_contact_us`, payload)
      .then(async (response) => {
        if (response.status == 200) {
          await Swal.fire({
            icon: 'success',
            text: i18n.t('header.received'),
            confirmButtonText: 'ยืนยัน',
            confirmButtonColor: '#007AFE',
          })
          commit('loadingMutations', false)
          return true
        } else if (response.status == 400) {
          alert(response.data.message)
          commit('loadingMutations', false)
          return false
        }
      })
      .catch((err) => {
        console.log(err)
        return false
      })
  },
  logout({ commit }) {
    commit('clear')
    localStorage.setItem('userId', '')
    localStorage.setItem('access_token', '')
    commit('tokenResetMutation')
    commit('loadingMutations', false)
    router.push('/home')
  },
}

function getAppLinkByStage() {
  switch (window.location.hostname) {
    case 'verscan.com':
      return 'https://app.verscan.com/'
    case 'staging.verscan.com':
      return 'https://staging-app.verscan.com/'
    case 'dev.verscan.com':
      return 'https://dev-app.verscan.com/'
    case 'localhost':
      return 'https://dev-app.verscan.com/'
    default:
      return null
  }
}
