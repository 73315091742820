<template>
  <div class="forget_password">
    <div v-if="!this.loading">
      <form class="py-10 grid justify-items-center" v-on:submit.prevent="confirm">
        <div class="grid w-2/3 sm:w-2/3 md:w-1/2 lg:w-1/3 xl:w-1/4 gap-y-2 justify-items-center">
          <h1 class="font-bold text-lg md:text-3xl justify-self-start">
            {{ $t('forget_password').header }}
          </h1>
          <h2 class="text-base mt-4 justify-self-start text-start">
            {{ $t('forget_password').subHeader }}
          </h2>
          <Inputbar
            :label="$t('forget_password').email"
            type="email"
            v-model="email"
            class="w-full py-2"
            blueLabel
          />
          <Button
            btnColor="gradient-btn"
            :text="$t('forget_password').request"
            type="submit"
            class="w-4/5 md:w-3/10"
          />
          <Button
            :text="$t('forget_password').back"
            btnColor="white-btn"
            @click.native="toHome"
            class="w-4/5 mt-2 md:w-3/10"
          />
        </div>
      </form>
      <Footer />
    </div>
    <div v-else class="d-flex h-screen">
      <Loading />
    </div>
  </div>
</template>

<script>
import Inputbar from '../components/general/form_input.vue'
import { baseUrl } from '../utils/backend'
import Button from '../components/general/general_button.vue'
import Loading from '../components/general/loading.vue'
import Footer from '../components/footer.vue'
import axios from 'axios'
import { mapGetters } from 'vuex'

export default {
  name: 'forget_password',
  data() {
    return {
      email: '',
    }
  },
  computed: {
    ...mapGetters({
      loading: 'getLoading',
    }),
  },
  methods: {
    async confirm(e) {
      e.preventDefault()
      try {
        this.$store.commit('loadingMutations', true)
        await axios
          .get(`${baseUrl()}forgotpassword?email=${this.email}`)
          .then(async (response) => {
            if (response.status == 200) {
              alert(this.$t('forget_password').finish)
              this.$store.commit('loadingMutations', false)
              this.$router.push('/home')
            } else if (response.status == 400) {
              alert(response.data.message)
              this.$store.commit('loadingMutations', false)
            }
          })
          .catch((err) => {
            alert(err.response.data.message.toString())
            this.$store.commit('loadingMutations', false)
          })
      } catch (error) {
        console.error(error)
      }
    },
    toHome() {
      this.$router.push('/home')
    },
  },
  components: {
    Inputbar,
    Button,
    Loading,
    Footer,
  },
}
</script>

<style scoped>
h1 {
  color: #024ea1;
}
h2 {
  font-size: 16px !important;
  color: #024ea1;
}

Button {
  font-size: 12px !important;
  color: #024ea1;
}
.forget_password {
  background: white;
}
</style>
