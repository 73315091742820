<template>
  <b-container class="background_pic px-3 py-5" fluid>
    <b-row class="text-center" align-v="center">
      <b-col></b-col>
      <b-col cols="10">
        <b-row class="justify-content-xl-around">
          <b-col xl="4" lg="5" md="6" sm="12" xs="12" class="my-3">
            <Verscan></Verscan>
          </b-col>
          <b-col xl="5" md="6" sm="12" xs="12" class="my-3">
            <ContactUs></ContactUs>
          </b-col>
          <!-- <b-col xl="3" lg="3" md="3" sm="6" xs="6" class="my-3">
            <Other></Other>
          </b-col> -->
        </b-row>
      </b-col>
      <b-col></b-col>
    </b-row>
    <div class="flex pb-5 w-full justify-center md:justify-end">
      <a href="https://houseofdev.tech/" target="_blank"><img class="power-by" src="@/assets/material/power-by-hod.png" alt="power-by"/></a>
    </div>
  </b-container>
</template>

<script>
import Verscan from './footer/verscan_footer.vue'
// import GetApp from "./footer/get_app_footer.vue";
import ContactUs from './footer/contact_footer.vue'
// import Other from "./footer/other_footer.vue";
export default {
  data() {
    return {}
  },
  components: {
    Verscan,
    // GetApp,
    ContactUs,
    // Other,
  },
}
</script>

<style scoped>
.power-by {
  max-width: 250px;
}
.background_pic {
  width: 100%;
  height: 100%;
  background: #13459b;

  background-size: 100% 100%;
}

h2 {
  color: white;
  font-size: 18px;
  line-height: 21px;
  font-weight: 600;
}
p {
  font-size: 12px;
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .background_pic {
    width: 100%;
    height: 100%;
    background: #13459b;

    background-size: 100% 100%;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .background_pic {
    width: 100%;
    height: 100%;
    background: #13459b;

    background-size: 100% 100%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .background_pic {
    width: 100%;
    height: 100%;
    background: #13459b;

    background-size: 100% 100%;
  }
}
@media only screen and (min-width: 425px) and (max-width: 767px) {
  .background_pic {
    width: 100%;
    height: 100%;
    background: #13459b;

    background-size: 100% 100%;
  }
}
@media only screen and (min-width: 320px) and (max-width: 424px) {
  .background_pic {
    width: 100%;
    height: 100%;
    background: #13459b;

    background-size: 100% 100%;
  }
}

/* @media only screen and (max-width: 1199px) {
  .background_pic {
  width: 100%;
  height: 350px;
   background: #13459B;

  background-size: 100% 100%;
}

}
@media only screen and (max-width: 767px) {
    .background_pic {
  width: 100%;
  height: 570px;
   background: #13459B;

  background-size: 100% 100%;
}

}
@media only screen and (max-width: 575px) {
 
 .background_pic {
  width: 100%;
  height: 630px;
  background: #13459B;

  background-size: 100% 100%;
}

}
@media only screen and (max-width: 424px) {
.background_pic {
  width: 100%;
  height: 630px;
  background: #13459B;

  background-size: 100% 100%;
}
} */
</style>
