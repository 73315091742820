<template>
  <div>
    <form
      v-if="!loading"
      class="grid gap-y-3 justify-items-center bank_transfer"
      v-on:submit.prevent="bankTransfer"
    >
      <PaymentDetail class="w-2/3 md:w-1/3 lg:w-2/5 mt-3" :package="plan" :page="'BankTransfer'" />
      <h1 class="mt-4">{{ $t('bank_transfer').account }}</h1>
      <BankAccount class="w-2/3 md:w-1/3 lg:w-2/5" />
      <h1 class="mt-4">{{ $t('bank_transfer').confirm }}</h1>
      <Input
        :label="$t('bank_transfer').amount"
        :value="getPriceJa"
        disabled
        class="w-2/3 md:w-1/3 lg:w-2/5"
      />
      <label class="flex font-bold w-2/3 md:w-1/3 lg:w-2/5">
        {{ $t('bank_transfer').time }}
      </label>

      <DatePicker
        :max_date="new Date()"
        @changeDate="($event) => (dateTime = $event)"
        class="w-2/3 md:w-1/3 lg:w-2/5"
      />

      <label class="flex font-bold w-2/3 md:w-1/3 lg:w-2/5">
        {{ $t('bank_transfer').evidence }}
      </label>
      <div v-if="url" id="preview" class="w-2/3 mb-3 md:w-1/3 lg:w-2/5 pointer">
        <img :src="url" @click="chooseFiles()" alt="preview"/>
      </div>
      <AddCard
        :text="$t('bank_transfer').upload"
        class="w-2/3 mb-3 md:w-1/3 lg:w-2/5 pointer"
        @click.native="chooseFiles()"
        v-if="!url"
      />
      <div class="flex w-2/3 md:w-1/3 lg:w-2/5 justify-between mb-4">
        <b-col cols="12" class="text-center d-xl-flex mb-3">
          <b-col cols="12" xl="6" class="mt-3">
            <Button
              :text="$t('payment').back"
              btnColor="white-btn"
              class="w-full xl:w-4/5"
              @click.native="back()"
            />
          </b-col>
          <b-col cols="12" xl="6" class="my-3">
            <Button
              :text="$t('payment').next"
              btnColor="blue-btn w-1/2"
              type="submit"
              class="w-full xl:w-4/5"
              ref="submit"
              :disabled="!url"
            />
          </b-col>
        </b-col>
      </div>
      <input
        id="fileUpload"
        type="file"
        @change="uploadImage($event)"
        accept="image/png, image/gif, image/jpeg"
        hidden
        required
      />
      <Footer />
    </form>
    <div v-else class="loading flex h-screen">
      <Loading white class="m-auto" />
    </div>
  </div>
</template>

<script>
import PaymentDetail from '../components/general/payment_detail.vue'
import Input from '../components/general/form_input.vue'
import Button from '../components/general/general_button.vue'
import AddCard from '../components/general/add_card.vue'
import BankAccount from '../components/Bank_transfer/bank_account.vue'
import Loading from '../components/general/loading.vue'
import Footer from '../components/footer.vue'
import DatePicker from '../components/general/datepicker.vue'
import { mapGetters } from 'vuex'
import { find } from 'lodash'
import axios from 'axios'

export default {
  data() {
    return { order: '', url: '', bank: '', dateTime: new Date() }
  },
  methods: {
    chooseFiles() {
      document.getElementById('fileUpload').click()
    },
    async uploadImage(e) {
      const fd = new FormData()
      fd.append('file', e.target.files[0])
      fd.append('folder_id', 1)
      await axios
        .post(`https://api.verscan.com/file/api/file/create`, fd)
        .then((response) => {
          if (response.status == 200) {
            this.url = response.data.data.uploadFileAtLevel.url.toString()
          } else if (response.status == 400) {
            alert(response.data.message)
          }
        })
        .catch((err) => {
          alert(err.response.data.message.toString())
        })
    },
    bankTransfer(e) {
      e.preventDefault()
      this.$store
        .dispatch('bankTransfer', {
          package_id: this.currentPackageId,
          pay_date: new Date(),
          withholding_tax: this.wht,
          addon_detail:
            (this.packageDetail?.addOn?.preparation
              ? 'บริการจัดทำ QR-code บนวัสดุชนิดต่างๆ เช่น กระดาษกันน้ำ, การเคลือบพลาสติก, แผ่นโลหะ, อื่นๆ, '
              : '') +
            (this.packageDetail?.addOn?.installation
              ? 'บริการทีมงานติดตั้ง QR-Code เพื่อระบุสินทรัพย์, '
              : '') +
            (this.packageDetail?.addOn?.data_transfer
              ? 'บริการโอนย้ายข้อมูลจากระบบ ERP เดิมขององค์กร, '
              : '') +
            (this.packageDetail?.addOn?.additional
              ? 'บริการเพิ่มฟีเจอร์อื่นๆ ที่เกี่ยวข้อง เพื่อให้ตอบโจทย์กับองค์กรของคุณ '
              : ''),
          pay_slip: this.url,
          pay_type: 'banktranfer',
          bill_detail: this.bill,
        })
        .then((res) => {
          if (res) {
            this.$store.dispatch('upgradeBranch', this.packageDetail.package_name)
            this.$router.push('/paymentsuccess')
          }
        })
    },
    back() {
      this.$router.push('/paymentmethod')
    },
  },
  async mounted() {
    const id = await localStorage.getItem('userId')
    if (!this.user && id) {
      this.$router.push('/companyselect')
    } else if (!this.user && !id) {
      this.$router.push('/home')
    }
  },
  computed: {
    ...mapGetters({
      packages: 'getPackages',
      user: 'getUser',
      currentPackageId: 'getPackage_id',
      loading: 'getLoading',
      getTotalPrice: 'getTotalPrice',
      packageDetail: 'getPackageDetail',
      wht: 'getWHT',
      bill: 'getBill',
    }),
    plan() {
      return find(this.packages, { package_id: this.currentPackageId })
    },
    getPriceJa() {
      let price = this.getTotalPrice
      return price
        .toFixed(2)
        .toLocaleString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
  },
  name: 'bankTransfer',
  components: {
    PaymentDetail,
    Input,
    Button,
    AddCard,
    BankAccount,
    Loading,
    DatePicker,
    Footer,
  },
}
</script>

<style scoped>
.bank_transfer h1 {
  font-size: 24px;
}
</style>
