<template>
  <div class="fixed right-2 top-1/3 z-10">
    <div
      class="soical bg-clip-padding bg-gray-300 backdrop-filter backdrop-blur-xl bg-opacity-60 border border-gray-200 h-auto w-16 rounded-lg mb-2 -mr-6 -ml-2 transform duration-500"
      :class="isOpen ? '' : 'translate-x-16'"
    >
      <div class="flex flex-col justify-between h-48 my-2 ml-2">
        <img
          src="../../assets/material/line.png"
          width="40"
          height="40"
          class="cursor-pointer"
          @click="toExternal('https://line.me/ti/p/~@verscan')"
          alt="icon"
        />
        <img
          src="../../assets/material/messenger.png"
          width="40"
          height="40"
          class="cursor-pointer mr-4"
          @click="clickMessenger"
          alt="icon"
        />
        <img
          src="../../assets/material/fb.png"
          width="40"
          height="40"
          class="cursor-pointer mr-4"
          @click="toExternal('https://www.facebook.com/houseofdevtech')"
          alt="icon"
        />
        <img
          src="../../assets/material/ig.png"
          width="40"
          height="40"
          class="cursor-pointer mr-4"
          @click="toExternal('https://www.instagram.com/houseofdevtech/')"
          alt="icon"
        />
      </div>
    </div>
    <div
      class="bg-clip-padding cursor-pointer bg-gray-300 backdrop-filter backdrop-blur-xl bg-opacity-60 border border-gray-200 h-10 w-10 rounded-full"
      @click="isOpen = !isOpen"
    >
      <div class="flex justify-center items-center w-full h-full">
        <img
          src="../../assets/material/ep_arrow-down-bold.svg"
          width="80"
          height="80"
          class="object-cover h-4/6 w-4/6 fa-arrow-down"
          :class="isOpen ? 'open' : ''"
          alt="icon"
        />
      </div>
    </div>
    <Messenger id="messenger" class="mt-10" />
  </div>
</template>
<script>
import Messenger from "../../components/messenger.vue";
export default {
  data() {
    return {
      isOpen: true,
    };
  },
  methods: {
    toExternal(link) {
      window.open(link, "_blank");
    },
    clickMessenger(){
      window.FB.CustomerChat.showDialog()
    }
  },
  components: {
    Messenger,
  },
};
</script>
<style scoped>
.fa-arrow-down {
  transform: rotate(0deg);
  transition: transform 0.25s linear;
}

.fa-arrow-down.open {
  transform: rotate(180deg);
  transition: transform 0.25s linear;
}
</style>
