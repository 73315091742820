<template>
  <div id="app">
    <Social />
    <Header />
    <router-view />
    <Messenger v-if="false" class="mb-2" />
  </div>
</template>

<script>
import Messenger from './components/messenger.vue'
import Header from './components/header.vue'
import Social from './components/home/social_media.vue'

;(function () {
  if (window.__PRERENDER_INJECTED && window.__PRERENDER_INJECTED.isPrerendering) return
  window.tolstoyAppKey = 'd6614cc3-e520-485a-a53a-8c2316d84697'
  var s = document.createElement('script')
  s.type = 'text/javascript'
  s.async = true
  s.src = 'https://widget.gotolstoy.com/widget/widget.js'
  document.head.appendChild(s)
})()

export default {
  name: 'App',
  metaInfo() {
    return {
      title: 'VERSCAN',
      titleTemplate: '%s | A new way to verify the fixed assets',
    }
  },
  components: {
    Messenger,
    Header,
    Social,
  },
  watch: {
    '$i18n.locale'() {
      document.getElementsByTagName('html')[0].lang = this.$i18n.locale
      // window.cwcCookieBanner.setLang(this.$i18n.locale)
    },
  },
  mounted() {
    document.getElementsByTagName('html')[0].lang = this.$i18n.locale
    // window.cwcCookieBanner.setLang(this.$i18n.locale)
  },
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Prompt:wght@300;400;500;600;700;800&display=swap');
html,
body.modal-open {
  font-family: 'Prompt', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#app {
  font-family: 'Prompt', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: white;
  min-height: 100vh;
  background: rgb(1, 122, 254);
  background: linear-gradient(90deg, rgba(1, 122, 254, 1) 0%, rgba(1, 64, 131, 1) 100%);
  overflow-x: hidden;
}

b-modal {
  font-family: 'Prompt', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.radio_style {
  width: 20px;
  height: 20px;
  color: #015fc3;
}

.pointer {
  cursor: pointer;
}
</style>
