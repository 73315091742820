<template>
  <b-container>
    <b-row>
      <b-col
        cols="12"
        class="why-verscan-bg"
        v-if="this.$i18n.locale == 'th'"
      ></b-col>
      <b-col cols="12" class="why-verscan-bg-en" v-else></b-col>
      <!-- <img src="@/assets/material/home_sec5.png" class="object-scale-down" alt=""/> -->
    </b-row>
  </b-container>
</template>

<script>
export default {
  setup() {
    return {};
  },
};
</script>

<style scoped>
h1 {
  color: #0158b6;
  font-size: 36px;
  line-height: inherit;
}
.why-verscan-bg {
  background-image: url("../../assets/material/home_sec5.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  height: 660px;
}

.why-verscan-bg-en {
  background-image: url("../../assets/material/home_sec5_en.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  height: 660px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .why-verscan-bg {
    background-image: url("../../assets/material/home_sec5.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
    height: 530px;
  }
  .why-verscan-bg-en {
    background-image: url("../../assets/material/home_sec5_en.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
    height: 530px;
  }
}
@media only screen and (min-width: 767px) and (max-width: 991px) {
  .why-verscan-bg {
    background-image: url("../../assets/material/ipad_home_sec5.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
    height: 505px;
  }
  .why-verscan-bg-en {
    background-image: url("../../assets/material/ipad_home_sec5_en.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
    height: 505px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .why-verscan-bg {
    background-image: url("../../assets/material/ipad_home_sec5.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
    height: 440px;
  }
  .why-verscan-bg-en {
    background-image: url("../../assets/material/ipad_home_sec5_en.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
    height: 440px;
  }
}
@media only screen and (min-width: 425px) and (max-width: 575px) {
  .why-verscan-bg {
    background-image: url("../../assets/material/ipad_home_sec5.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
    height: 400px;
  }
  .why-verscan-bg-en {
    background-image: url("../../assets/material/ipad_home_sec5_en.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
    height: 400px;
  }
}
@media only screen and (min-width: 300px) and (max-width: 424px) {
  .why-verscan-bg {
    background-image: url("../../assets/material/mobile_home_sec5.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
    height: 450px;
  }
  .why-verscan-bg-en {
    background-image: url("../../assets/material/mobile_home_sec5_en.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
    height: 450px;
  }
}
</style>