<template>
  <div class="cardStyle grid py-5 px-2 justify-items-center">
    <img
      src="@/assets/material/Kbank-logo.png"
      class="object-scale-down w-27 "
      alt="kbank-logo"
    />
    <p class="my-3">{{ $t("bank_transfer").kasikorn }}</p>
    <p>{{ $t("bank_transfer").hod }}</p>
    <p>{{ $t("bank_transfer").hod_2 }}</p>
    <p class="pt-3">{{ $t("bank_transfer").bankNum }} 040-1-81306-3</p>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.cardStyle {
  background: #ffffff;
  border-radius: 10px;
  font-size: 14px;
  color: #5c5c5c;
}
</style>
