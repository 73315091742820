export const state = {
  register: {},
  user: false,
  loading: false,
  company: [],
  packages: [],
  token: '',
  current_branch: '',
  selected_package: false,
  package_id: false,
  bill: {},
  package_information: null,
  wht: true,
  package_detail: null,
  totalPrice: 0,
  locale: 'th',
  refNo: '',
  altBill: {},
}