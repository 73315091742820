<template>
  <svg
    width="148"
    height="12"
    viewBox="0 0 148 12"
    fill="none"
    :class="flip ? 'flip' : ''"
  >
    <path
      d="M0.226497 6L6 11.7735L11.7735 6L6 0.226497L0.226497 6ZM6 7H148V5H6V7Z"
      :fill="blue ? '#014083' : 'white'"
    ></path>
  </svg>
</template>

<script>
export default {
  props: {
    flip: Boolean,
    blue: Boolean,
  },
};
</script>

<style scoped>
.flip {
  transform: rotate(180deg);
}
</style>
