<template>
  <b-button
    class="shadow x font-bold py-2 text-center"
    :class="btnColor"
    type="button"
    :disabled="disabled"
  >
    {{ text }}
  </b-button>
</template>

<script>
export default {
  methods: {},
  props: {
    btnColor: String,
    text: String,
    space: String,
    disabled: Boolean,
  },
}
</script>

<style scoped>
button {
  border-radius: 8px;
  border: 0px;
}
.gradient-btn {
  background: rgb(52, 157, 229);
  background: linear-gradient(90deg, rgba(52, 157, 229, 1) 0%, rgba(93, 189, 184, 1) 100%);
  color: white;
}

.white-btn {
  color: #015fc3;
  background-color: #ffffff;
}

.blue-btn {
  color: #fff;
  background-color: #007afe;
  border: 2px solid #007afe;
}
.blue-border-btn {
  border: 2px solid #007afe;
  background-color: #fff;
  color: #007afe;
}
.blue-gradient-btn {
  color: #fff;
  background: linear-gradient(90deg, #2367ed 0%, #63b5ff 106.01%);
}
</style>
