<template>
  <b-button class="font-bold py-2 px-4 rounded-full" type="button">
    <!-- <img src="@/assets/material/trash.svg"  alt=""/> -->
     <b-icon
          icon="trash"
          variant="danger"
      
        ></b-icon>
  </b-button>
</template>

<script>
export default {};
</script>

<style scoped>
.btn {
background:#ddd !important;
  border-radius: 8px;
  border:solid 0px #ddd !important
  /* color: white; */
}

</style>
