<template>
  <b-container>
    <b-row>
      <b-col xl="5" lg="5" md="5" align-self="center">
        <img src="@/assets/material/home_sec3.png" class="d-none-pic" alt="home"/>
      </b-col>
      <b-col xl="7" lg="7" md="7" sm="12" xs="12" align-self="center">
        <h1 class="font-bold text-center text-md-start">
          {{ $t('home').section3.header }}
        </h1>
        <div v-for="(solution, index) in $t('home').section3.solutions" :key="index">
          <List
            v-if="index != 3"
            class="text-black"
            tick
            :filename="`solution${index + 1}.svg`"
            :text="solution"
          />
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import List from './list_with_icon.vue'
export default {
  components: {
    List,
  },
}
</script>

<style scoped>
h1 {
  font-size: 36px;
  line-height: inherit;
  color: #0158b6 !important;
}
</style>