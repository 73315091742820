<template>
  <b-container>
    <b-row>
      <b-col lg="6" xs="12" align-self="center" class="text-center text-md-start">
        <h1 class="text-left my-3">VERSCAN</h1>
        <div class="text-left my-3">
          <h2>A NEW WAY TO VERIFY</h2>
          <h2 class="mt-1 md:mt-2">THE FIXED ASSETS</h2>
        </div>
        <p class="text-left my-3">
          {{ $t('home').section2['1'] }}
        </p>
        <div class="flex flex-row items-center md:hidden">
          <img
            v-if="this.$i18n.locale === 'en'"
            src="../../assets/material/Web-EN.png"
            class="cursor-pointer my-1 app-icon"
            alt="icon"
          />
          <img
            v-else
            src="../../assets/material/Web-TH.png"
            class="cursor-pointer my-1 app-icon"
            alt="icon"
          />
          <p class="text-left ml-4">
            {{ $t('home').or }}
          </p>
        </div>

        <div class="items-center mt-2 mb-3 justify-start flex gap-x-2">
          <div class="md:flex justify-content-center hidden">
            <img
              v-if="this.$i18n.locale === 'en'"
              src="../../assets/material/Web-EN.png"
              class="cursor-pointer my-1 app-icon"
              alt="icon"
            />
            <img
              v-else
              src="../../assets/material/Web-TH.png"
              class="cursor-pointer my-1 app-icon"
              alt="icon"
            />
          </div>
          <p class="text-left my-3 hidden md:flex">
            {{ $t('home').or }}
          </p>
          <div class="flex justify-content-center">
            <img
              @click="toAppStore"
              src="../../assets/material/app_store.svg"
              class="cursor-pointer my-1 app-icon"
              alt="icon"
            />
          </div>
          <div class="flex justify-content-center">
            <img
              @click="toPlayStore"
              src="../../assets/material/google_play.svg"
              class="cursor-pointer app-icon"
              height="150"
              alt="icon"
            />
          </div>
        </div>
      </b-col>

      <b-col lg="6" xs="12" align-self="center" class="my-2">
        <img src="@/assets/material/phone.png" alt="icon"/>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  methods: {
    toPlayStore() {
      window.open('https://play.google.com/store/apps/details?id=app.web.verscan')
    },
    toAppStore() {
      window.open('https://apps.apple.com/us/app/verscan/id1580360921')
    },
  },
}
</script>

<style scoped>
.row > * {
  padding-right: calc(var(--bs-gutter-x) * 0.4);
  padding-left: calc(var(--bs-gutter-x) * 0.4);
}
.app-icon {
  height: 40px !important;
}
p {
  color: black;
}
h1 {
  font-size: 36px;
  color: #0068d9 !important;
  font-weight: 600;
}
h2 {
  font-size: 36px;
  color: #0f4c82;
  font-weight: 600;
  line-height: 30px;
}
</style>
