<template>
  <div>
    <div v-if="!loading">
      <b-container class="py-5">
        <b-row class="text-center" align-h="center">
          <b-col cols="10">
            <h1 class="mt-5 mb-4 CompanySelect">
              {{ $t('company_select').select }}
            </h1>
            <Seperator class="mb-4 w-full" />
          </b-col>
        </b-row>
        <b-row align-h="center">
          <b-col cols="10" class="d-flex flex-wrap pb-5">
            <b-col
              lg="4"
              md="6"
              xs="12"
              class="d-flex p-3 w-full md:w-3/6 lg:w-2/6"
              v-for="(branch, index) in branches"
              :key="index"
            >
              <CompanyCard :branch="branch" />
            </b-col>
            <b-col
              lg="4"
              md="6"
              xs="12"
              class="d-flex p-3 w-full md:w-3/6 lg:w-2/6 pointer"
              @click="clickAdd"
              align-h="center"
            >
              <AddCard class="w-full" :text="$t('company_select').addCompany" />
            </b-col>
          </b-col>
        </b-row>

        <!-- Start of Add & Edit Modal  -->

        <b-modal v-model="modal" hide-footer hide-header centered>
          <b-row>
            <b-cols cols="12" class="d-flex justify-content-end pb-3">
              <b-icon icon="x-circle" class="cursor-pointer" @click="modal = !modal"></b-icon>
            </b-cols>
            <b-col cols="12" class="text-center">
              <h1 class="modal_header">
                {{ edit ? $t('company_select').edit : $t('company_select').add }}
              </h1>
              <img
                class="w-full xl:px-40 lg:px-40 md:px-36 px-24 py-2 my-3"
                src="@/assets/material/add_company.svg"
                alt="add"
              />
              <form class="justify-items-center" v-on:submit.prevent="submitForm">
                <div class="w-full">
                  <label class="flex text-gray-50 font-bold mb-1 pr-4" for="inline-full-name">
                    {{ $t('company_select').department }}
                  </label>
                  <div class="flex justify-start items-center">
                    <input
                      class="radio_style"
                      type="radio"
                      :disabled="this.edit ? true : false"
                      :value="true"
                      v-model="hq"
                    />
                    <span class="mx-2">{{ $t('company_select').hq }}</span>
                    <input
                      v-if="this.company.length != 0"
                      class="radio_style"
                      type="radio"
                      :disabled="this.edit ? true : false"
                      v-model="hq"
                      :value="false"
                    />

                    <span v-if="this.company.length != 0" class="mx-2">{{
                      $t('company_select').branch
                    }}</span>
                  </div>
                </div>

                <Input
                  v-if="hq"
                  :label="$t('company_select').name"
                  :placeholder="$t('company_select').name"
                  class="w-full my-3"
                  v-model="companyName"
                  required
                />

                <div class="w-full my-2" v-else>
                  <label class="flex text-gray-50 font-bold pr-4" for="inline-full-name">
                    {{ $t('company_select').name }}
                  </label>
                  <select
                    v-model="companyName"
                    class="form-select block w-full"
                    required
                    :disabled="edit"
                  >
                    <option v-for="(comp, i) in this.company" :key="i" :value="comp.name">
                      {{ comp.name }}
                    </option>
                  </select>
                  <Input
                    :label="$t('company_select').branchNumber"
                    class="my-2"
                    type="text"
                    pattern="\d*"
                    maxlength="6"
                    v-model="branchNumber"
                    required
                  />
                  <Input
                    :label="$t('company_select').branchName"
                    class="my-2"
                    type="text"
                    v-model="branchName"
                    required
                  />
                </div>

                <div v-if="edit" class="flex justify-between mt-4 mb-3 w-full">
                  <Trash @click.native="clickDelete" class="w-1/2 mr-2" />
                  <Button
                    :text="$t('company_select').btnSave"
                    btnColor="gradient-btn"
                    class="w-1/2 self-center"
                    type="submit"
                  />
                </div>
                <Button
                  v-else
                  :text="$t('company_select').btnAdd"
                  btnColor="gradient-btn"
                  class="w-1/2 my-2 self-center"
                  :disabled="onSummit"
                  type="submit"
                />
              </form>
            </b-col>
          </b-row>
        </b-modal>

        <!-- End of Add & Edit Modal  -->

        <!-- Start of Delete Modal  -->

        <b-modal v-model="deleting" hide-footer hide-header centered>
          <b-row>
            <b-cols cols="12" class="d-flex justify-content-end pb-3">
              <b-icon icon="x-circle" class="cursor-pointer" @click="deleting = !deleting"></b-icon>
            </b-cols>
            <b-col cols="12" class="text-center delete_modal px-3">
              <h1>{{ $t('company_select').deleting }}</h1>
              <img src="@/assets/material/deleting.svg" class="w-full px-24 py-2 my-3" alt="delete"/>
              <div class="text-left">
                <p>{{ $t('company_select').afterDelete }}</p>
              </div>
            </b-col>
            <b-col cols="12">
              <b-form @submit.stop.prevent>
                <b-form-input
                  v-model="deleteConfirm"
                  :state="validation"
                  class="my-3"
                ></b-form-input>

                <b-row class="pt-3">
                  <b-col cols="6">
                    <b-button @click="deleting = !deleting" variant="" class="grey-button w-full">
                      {{ $t('company_select').cancelDelete }}</b-button
                    >
                  </b-col>
                  <b-col cols="6">
                    <b-button
                      variant="danger"
                      class="w-full"
                      type="submit"
                      :disabled="!validation"
                      @click="deleteBranch"
                    >
                      {{ $t('company_select').delete }}
                    </b-button>
                  </b-col>
                </b-row>
              </b-form>
            </b-col>
          </b-row>
        </b-modal>

        <!-- End of Delete Modal  -->
      </b-container>
      <Footer />
    </div>

    <div v-else class="loading d-flex h-screen">
      <Loading white class="m-auto" />
    </div>
  </div>
</template>

<script>
import Seperator from '../components/general/seperate_line.vue'
import Input from '../components/general/modal_input.vue'
import Button from '../components/general/general_button.vue'
import Trash from '../components/Company_select/trash_button.vue'
import Loading from '../components/general/loading.vue'
import Footer from '../components/footer.vue'
import AddCard from '../components/general/add_card.vue'
import CompanyCard from '../components/Company_select/company_card.vue'
import { mapGetters, mapMutations } from 'vuex'
import { find, sortBy } from 'lodash'
import moment from 'moment'

export default {
  data() {
    return {
      onSummit: false,
      modal: false,
      edit: false,
      deleting: false,
      hq: '',
      companyId: '',
      companyName: '',
      branchId: '',
      branchNumber: '',
      branchName: '',
      deleteConfirm: '',
      id: '',
    }
  },
  mounted() {
    const id = localStorage.getItem('userId')
    this.id = id
    this.$store.commit('billMutations', {})
    this.$store.commit('createBillForConfirmMutation', null)
    this.$store.commit('current_branchMutations', null)
    this.$store.dispatch('getUser', id)
    this.$store.commit('PACKAGE_DETAIL_MUTATION', null)
    this.$store.dispatch('totalPriceAction', 0)
  },
  computed: {
    ...mapGetters({
      company: 'getCompany',
      packages: 'getPackages',
      user: 'getUser',
      loading: 'getLoading',
      current_package: 'getPackage_id',
    }),
    validation() {
      return this.deleteConfirm === 'Delete' && this.deleteConfirm !== ''
    },
    branches: function () {
      let branches = []
      this.company.map((company) => {
        company.branches.map((branch) => {
          const packageName = () => {
            if (branch.orders.length != 0) {
              return find(this.packages, {
                package_id: branch.orders[branch.orders.length - 1].package_id,
              }).package_name
            } else {
              return ''
            }
          }
          const packageId = () => {
            if (branch.orders.length != 0) {
              return branch.orders[branch.orders.length - 1].package_id
            } else {
              return ''
            }
          }
          const expire = () => {
            if (
              branch.orders.length != 0 &&
              branch.orders[branch.orders.length - 1].package_status == 'pending'
            ) {
              return 'pending'
            } else if (
              branch.orders.length != 0 &&
              branch.orders[branch.orders.length - 1].exp_date
            ) {
              return moment(branch.orders[branch.orders.length - 1].exp_date).format('DD/MM/YYYY')
            } else {
              return ''
            }
          }
          // console.log('this.current_package:', this.current_package)
          // if (this.current_package) {
          //   if (this.current_package == 1) {
          //     if (!find(branches, { branch_id: branch.branch_id }) && expire() == '') {
          //       branches.push({
          //         branch_id: branch.branch_id,
          //         company_id: company.company_id,
          //         company_name: company.name,
          //         branch_name:
          //           branch.branch_name == 'Head Office'
          //             ? this.$t('company_select').hq
          //             : branch.branch_name,
          //         package: packageName(),
          //         expire: expire(),
          //         branch_no: branch.branch_no,
          //         payment_detail: {
          //           taxNumber: branch.TAX_ID,
          //           address1: branch.billing_address_1,
          //           address2: branch.billing_address_2,
          //           billing_email: branch.billing_email,
          //           city: branch.city,
          //           country: branch.country,
          //           postal: branch.zipcode,
          //         },
          //       })
          //     }
          //   } else {
          //     if (
          //       !find(branches, { branch_id: branch.branch_id }) &&
          //       (expire() == '' || packageId() == 1)
          //     ) {
          //       branches.push({
          //         branch_id: branch.branch_id,
          //         company_id: company.company_id,
          //         company_name: company.name,
          //         branch_name:
          //           branch.branch_name == 'Head Office'
          //             ? this.$t('company_select').hq
          //             : branch.branch_name,
          //         package: packageName(),
          //         expire: expire(),
          //         branch_no: branch.branch_no,
          //         payment_detail: {
          //           taxNumber: branch.TAX_ID,
          //           address1: branch.billing_address_1,
          //           address2: branch.billing_address_2,
          //           billing_email: branch.billing_email,
          //           city: branch.city,
          //           country: branch.country,
          //           postal: branch.zipcode,
          //         },
          //       })
          //     }
          //   }
          // } else {
          if (!find(branches, { branch_id: branch.branch_id })) {
            branches.push({
              branch_id: branch.branch_id,
              company_id: company.company_id,
              company_name: company.name,
              branch_name:
                branch.branch_name == 'Head Office'
                  ? this.$t('company_select').hq
                  : branch.branch_name,
              package: packageName(),
              expire: expire(),
              branch_no: branch.branch_no,
              payment_detail: {
                taxNumber: branch.TAX_ID,
                address1: branch.billing_address_1,
                address2: branch.billing_address_2,
                billing_email: branch.billing_email,
                city: branch.city,
                country: branch.country,
                postal: branch.zipcode,
              },
            })
          }
          // }
        })
      })
      console.log('branches -->', branches)
      return sortBy(branches, ['company_id', 'branch_id'])
    },
  },
  methods: {
    ...mapMutations({
      setPackageId: 'package_idMutations',
    }),
    async submitForm(e) {
      console.log('edit')
      this.onSummit = true
      e.preventDefault()
      this.modal = false
      const form = this.edit
        ? {
            company_id: this.companyId,
            company_name: this.companyName,
            detail: this.hq
              ? {
                  branch_id: this.branchId,
                  branch_no: 0,
                  branch_name: 'Head Office',
                }
              : {
                  branch_id: this.branchId,
                  branch_no: parseInt(this.branchNumber),
                  branch_name: this.branchName,
                },
          }
        : {
            user_id: this.user.user_id,
            company_name: this.companyName,
            detail: this.hq
              ? {
                  branch_no: 0,
                  branch_name: 'Head Office',
                }
              : {
                  branch_no: parseInt(this.branchNumber),
                  branch_name: this.branchName,
                },
          }

      this.$store.dispatch(this.edit ? 'editBranch' : 'createBranch', form).then(() => {
        console.log('.then(())')
        this.$store.dispatch('getUser', this.id)
        this.onSummit = false
        this.loading = false
        this.modal = false
      })
    },
    async deleteBranch() {
      if (this.deleteConfirm == 'Delete') {
        this.modal = this.deleting = false
        this.deleteConfirm = ''
        await this.$store
          .dispatch('deleteBranch', {
            company_id: this.companyId,
            branch_id: this.branchId,
          })
          .then(() => {
            this.modal = false
            this.$store.dispatch('getUser', this.id)
          })
      } else {
        alert(this.$t('company_select').type_del)
      }
    },
    clickDelete() {
      this.deleting = !this.deleting
      this.modal = !this.modal
    },
    cardClicked(expire, branch) {
      this.$store.commit('current_branchMutations', branch)
      if (expire == '') {
        if (this.current_package) {
          this.$router.push('/payment')
        } else {
          this.$router.push('/packages')
        }
      } else {
        this.$router.push('/toverscan')
      }
    },
    async trial(branch) {
      this.setPackageId(1)
      this.$store.commit('current_branchMutations', branch)
      this.$router.push('/payment')
    },
    changePackage(branch) {
      this.$store.commit('current_branchMutations', branch)
      this.$store.commit('createBillForConfirmMutation', branch.payment_detail)
      if (this.current_package) {
        this.$router.push('/payment')
      } else {
        this.$router.push('/packages/upgrade')
      }
    },
    clickEdit(branch) {
      this.branchId = branch.branch_id
      this.branchName = branch.branch_name
      this.branchNumber = branch.branch_no
      this.companyId = branch.company_id
      this.companyName = branch.company_name
      this.hq = branch.branch_no == 0 ? true : false
      this.modal = true
      this.edit = true
    },
    clickAdd() {
      this.branchName = ''
      this.branchNumber = ''
      this.companyName = ''
      this.hq = true
      this.modal = true
      this.edit = false
    },
  },

  name: 'CompanySelect',
  components: {
    CompanyCard,
    Seperator,
    Input,
    Button,
    Trash,
    AddCard,
    Footer,
    Loading,
  },
}
</script>

<style scoped>
.loading {
  margin: 0 auto;
}
.wrapword {
  white-space: -moz-pre-wrap !important;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  white-space: pre-wrap;
  word-wrap: break-word;
  white-space: -webkit-pre-wrap;
  word-break: break-all;
  white-space: normal;
}
.card-body h1 {
  font-size: 18px;
  color: #014083;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.card-body h2 {
  font-size: 14px !important;
  color: #014083;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.card-body p {
  font-size: 12px;
  color: #007afe;
}

.CompanySelect {
  font-size: 24px;
  color: #ffffff;
}

label {
  font-weight: bold;
  color: #015fc3;
  font-size: 16px;
}
.modal_header {
  color: #015fc3;
  font-size: 24px;
}
.delete_modal h1 {
  color: #ed1616;
  font-weight: bold;
  font-size: 18px;
}
.delete_modal p {
  font-size: 14px;
}
.delete_confirm {
  background: #f1f1f1;
  border-radius: 5px;
}
.grey-button {
  background: #ddd;
  border: solid 1px #ddd;
}

.btn-red {
  background: #ed1616;
}
.addCard {
  border: 2px dashed #ffffff;
  box-sizing: border-box;
  border-radius: 10px;
  font-size: 18px;
}
</style>
