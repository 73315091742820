<template>
  <b-row class="addCard py-7" align-v="center">
    <div class="grid w-full justify-items-center items-center">
      <img class="mb-2" src="@/assets/material/plus.svg" alt="" />
      <p>{{ text }}</p>
    </div>
  </b-row>
</template>

<script>
export default {
  props: {
    text: String,
  },
}
</script>

<style scoped>
.addCard {
  border: 2px dashed #ffffff;
  box-sizing: border-box;
  border-radius: 10px;
  font-size: 18px;
}
.row {
  --bs-gutter-x: 0;
}
</style>
