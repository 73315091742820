<template>
  <div>
    <label class="flex font-bold mb-1 pr-4" :class="blueLabel ? 'blueLabel' : ''">
      {{ label }} <span style="color: red" v-if="required">*</span>
    </label>
    <div class="position-relative">
      <input
        :placeholder="label"
        :type="type"
        class="w-full text-left appearance-none border-2 border-gray-200 rounded-form py-2 color-grey leading-tight focus:outline-none focus:bg-white pl-3"
        :class="disabled ? 'disabled-bg' : 'bg-white'"
        :value="value"
        :required="required"
        :disabled="disabled"
        @input="onInput"
        :pattern="pattern"
        :maxlength="maxlength"
        :minlength="minlength"
        :id="id"
      />
      <slot name="icon"></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    type: String,
    rules: String,
    label: String,
    value: String,
    disabled: Boolean,
    pattern: String,
    maxlength: Number,
    minlength: Number,
    required: Boolean,
    id: String,
    blueLabel: Boolean,
  },
  methods: {
    onInput(event) {
      if (this.id === 'tax') {
        // only numeric
        event.target.value = event.target.value
          .replace(/[^0-9.]/g, '')
          .replace(/(\..*?)\..*/g, '$1')
      }
      this.$emit('input', event.target.value)
    },
  },
}
</script>

<style>
#company-name {
  overflow: hidden;
  text-overflow: ellipsis;
}
.color-grey {
  color: #333333 !important;
}
.disabled-bg {
  background: #dddddd;
}
.blueLabel {
  color: #024ea1 !important;
}
</style>
