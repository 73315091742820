<template>
  <div>
    <form v-if="!loading" class="grid" v-on:submit.prevent="createBill">
      <h1 class="text-2xl font-bold mt-4">
        {{ $t('payment').information }}
      </h1>
      <Seperator class="justify-self-center my-4" />

      <b-row class="">
        <b-col xl="5" xs="12" class="text-center xl:px-20 md:px-48">
          <h2 style="font-weight: 700; font-size: 16px !important">
            {{ $t('payment').chosen }}
          </h2>
          <PackageDetail class="mb-5 mt-2 justify-self-center" :package="plan" />
          <PaymentDetail class="mb-5 mt-2 justify-self-center" :package="plan" :page="'Payment'" />
        </b-col>
        <b-col xl="7" xs="12" class="d-block">
          <!-- <h2 class="my-4 lg:hidden">{{ $t("payment").billing }}</h2> -->
          <b-container fluid>
            <b-row>
              <b-col md="6" sm="12" xs="12" align-self="center">
                <FormInput
                  :label="$t('payment').companyName"
                  :value="this.branch ? this.branch.company_name : ''"
                  id="company-name"
                  class="mb-2 w-full justify-self-center"
                  :disabled="this.$route.name != 'RegisterPayment'"
                  :required="this.$route.name === 'RegisterPayment'"
                  v-model="companyName"
                />
              </b-col>
              <b-col
                md="6"
                sm="12"
                xs="12"
                align-self="center"
                v-if="this.$route.name != 'RegisterPayment'"
              >
                <label class="flex font-bold mb-2" for="inline-full-name">
                  {{ $t('payment').department }}
                </label>
                <div class="flex items-center">
                  <input
                    class="radio_style"
                    type="radio"
                    :checked="this.branch.branch_no == 0 ? true : false"
                    :disabled="this.branch.branch_no != 0 ? true : false"
                  /><span class="mx-2"> {{ $t('payment').head_office }}</span>
                  <input
                    class="radio_style"
                    type="radio"
                    :checked="this.branch.branch_no != 0 ? true : false"
                    :disabled="this.branch.branch_no == 0 ? true : false"
                  /><span class="mx-2">{{ $t('payment').branch }}</span>
                </div>
              </b-col>
              <b-col md="6" sm="12" xs="12" v-if="this.$route.name != 'RegisterPayment'">
                <FormInput
                  :label="$t('payment').branchNumber"
                  :value="String(this.branch.branch_no)"
                  disabled
                  class="mb-2 w-full justify-self-center"
                  v-if="branch.branch_no != 0"
              /></b-col>
              <b-col md="6" sm="12" xs="12" v-if="this.$route.name != 'RegisterPayment'">
                <FormInput
                  :label="$t('payment').branchName"
                  :value="this.branch.branch_name"
                  disabled
                  class="mb-2 w-full justify-self-center"
                  v-if="branch.branch_no != 0"
              /></b-col>

              <b-col md="6" sm="12" xs="12">
                <FormInput
                  :label="$t('payment').taxNumber"
                  :type="'text'"
                  :id="'tax'"
                  required
                  v-model="taxNumber"
                  class="mb-2 w-full text-left"
              /></b-col>

              <b-col md="6" sm="12" xs="12" v-if="this.$route.name != 'RegisterPayment'">
                <FormInput
                  :label="$t('payment').email"
                  :value="this.user.email"
                  class="mb-2 w-full justify-self-center"
                  disabled
              /></b-col>
              <b-col md="6" sm="12" xs="12"
                ><FormInput
                  :label="$t('payment').address"
                  required
                  v-model="address1"
                  class="mb-2 w-full justify-self-center"
              /></b-col>
              <b-col md="6" sm="12" xs="12"
                ><FormInput
                  :label="$t('payment').address2"
                  v-model="address2"
                  class="mb-2 w-full justify-self-center"
              /></b-col>

              <b-col md="6" sm="12" xs="12">
                <FormInput
                  :label="$t('payment').city"
                  v-model="city"
                  required
                  class="mb-2 w-full justify-self-center"
              /></b-col>
              <b-col md="6" sm="12" xs="12"
                ><FormInput
                  :label="$t('payment').country"
                  v-model="country"
                  required
                  class="mb-2 w-full justify-self-center"
              /></b-col>
              <b-col md="6" sm="12" xs="12"
                ><FormInput
                  :label="$t('payment').post"
                  v-model="postal"
                  :pattern="'[0-9]{5}'"
                  :maxlength="5"
                  required
                  class="mb-2 w-full justify-self-center"
              /></b-col>
              <b-col md="6" sm="12" xs="12"></b-col>
              <b-col xl="6" cols="12" class="text-center d-xl-flex mb-3">
                <b-col cols="12" xl="6" class="text-xl-start mt-3">
                  <Button
                    :text="$t('payment').back"
                    btnColor="white-btn"
                    class="xl:w-4/5 w-full"
                    @click.native="back()"
                  />
                </b-col>
                <b-col cols="12" xl="6" class="text-xl-start my-3">
                  <Button
                    :text="$t('payment').next"
                    btnColor="blue-btn"
                    class="xl:w-4/5 w-full"
                    type="submit"
                  />
                </b-col>
              </b-col>
            </b-row>
          </b-container>
        </b-col>
      </b-row>
      <Footer />
    </form>
    <div v-else class="my-5">
      <Loading />
      <p class="text-dark">{{ $t('loading') }}</p>
    </div>
  </div>
</template>

<script>
import Seperator from '../../components/general/seperate_line.vue'
import PaymentDetail from '../../components/general/payment_detail.vue'
import PackageDetail from '../../components/Payment/package_detail.vue'
import FormInput from '../../components/general/form_input.vue'
import Button from '../../components/general/general_button.vue'
import Loading from '../../components/general/loading.vue'
import Footer from '../../components/footer.vue'
import { mapGetters, mapMutations } from 'vuex'
import { find } from 'lodash'
import axios from 'axios'
import { baseUrl } from '../../utils/backend'

export default {
  name: 'payment',
  components: {
    Seperator,
    PaymentDetail,
    FormInput,
    Button,
    Loading,
    PackageDetail,
    Footer,
  },
  computed: {
    ...mapGetters({
      packages: 'getPackages',
      currentPackageId: 'getPackage_id',
      branch: 'getCurrent_branch',
      user: 'getUser',
      getPackagesInformation: 'getPackagesInformation',
    }),
    plan: function () {
      return find(this.packages, { package_id: this.currentPackageId })
    },
    headOffice() {
      let word = this.$t('payment').head_office
      return word
    },
  },
  data() {
    return {
      taxNumber: '',
      address1: '',
      address2: '',
      city: '',
      postal: '',
      country: '',
      loading: false,
      companyName: '',
      modal: false,
      email: '',
      password: '',
      confirm: '',
      tel: '',
      step: 0,
      // wht:true
    }
  },
  async mounted() {
    window.scrollTo(0, 0)
    const id = await localStorage.getItem('userId')
    if (this.$route.name === 'RegisterPayment') {
      console.log('here')
    } else {
      if (!this.user && id) {
        this.$router.push('/company_select')
      } else if (!this.user && !id) {
        this.$router.push('/home')
      } else {
        this.companyName = this.branch.company_name
      }
      this.email = this.user.email
    }
    if (this.getPackagesInformation) {
      this.taxNumber = this.getPackagesInformation.taxNumber
      this.address1 = this.getPackagesInformation.address1
      this.address2 = this.getPackagesInformation.address2
      this.city = this.getPackagesInformation.city
      this.country = this.getPackagesInformation.country
      this.postal = this.getPackagesInformation.postal
    }
  },
  methods: {
    ...mapMutations({
      altBill: 'ALT_BILL_MUTATION',
    }),

    async createBill(e) {
      e.preventDefault()
      let form = {
        taxNumber: this.taxNumber,
        email: this.email,
        address1: this.address1,
        address2: this.address2,
        city: this.city,
        country: this.country,
        postal: this.postal,
      }
      this.$store.dispatch('createBillForConfirm', form)
      if (this.$route.name === 'RegisterPayment') {
        await axios
          .post(`${baseUrl()}check_companyname`, {
            company_name: this.companyName,
          })
          .then((response) => {
            if (response.status == 200) {
              if (response.data.message === 'Company name is unique.') {
                this.altBill({
                  company_name: this.companyName,
                  bill_detail: {
                    taxNumber: this.taxNumber,
                    email: this.email,
                    address1: this.address1,
                    address2: this.address2,
                    city: this.city,
                    country: this.country,
                    postal: this.postal,
                  },
                })
                this.step = 0
                this.$root.$emit('bv::show::modal', 'register')
              } else if (response.data.message === 'Company Name is exist.') {
                alert(this.$t('payment').exist)
              }
            } else {
              alert(this.$t('payment').exist)
            }
          })
          .catch((err) => {
            if (err.response.data.message.toString() === 'Company Name is exist.') {
              alert(this.$t('payment').exist)
            } else {
              alert(err.response.data.message.toString())
            }
            return false
          })
      } else {
        this.$router.push('/paymentConfirm')
      }
    },
    back() {
      this.$router.push('/packages')
    },
  },
}
</script>

<style scoped>
</style>
