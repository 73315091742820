import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './plugins/i18n'
import axios from 'axios'
import VueGtag from 'vue-gtag'
import VueMeta from 'vue-meta'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import '@/assets/css/tailwind.css'

import Vuelidate from 'vuelidate'

import Calendar from 'v-calendar/lib/components/calendar.umd'
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import VueSlider from 'vue-slider-component'
import '../src/assets/css/slider.css'

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

import VueCarousel from 'vue-carousel'
Vue.use(VueCarousel)

import VueAgile from 'vue-agile'
Vue.use(VueAgile)

Vue.component('VueSlider', VueSlider)
Vue.use(Vuelidate)

Vue.component('calendar', Calendar)
Vue.component('date-picker', DatePicker)

Vue.use(VueMeta)

if (window.location.hostname === 'verscan.com') {
Vue.use(
  VueGtag,
  {
    config: {
      id: 'G-6GRY126VF4',
      params: {
        send_page_view: false,
        debug_mode: true,
      },
    },
    includes: [
      {
        id: 'UA-113910924-11',
      },
    ],
    pageTrackerTemplate(to) {
      return {
        page_title: to.name,
        page_path: to.path,
      }
    },
  },
  router
)
}

Vue.prototype.$http = axios

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app')
