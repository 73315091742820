<template>
  <b-card class="w-full">
    <b-row class="px-3" align-v="center">
      <b-col cols="12" align-self="center" class="d-flex justify-content-center mb-4">
        <img :src="require(`@/assets/material/${filename}`)" alt="material"/>
      </b-col>
      <b-col cols="12">
        <p class="label-text pb-1">{{ label }}</p>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
export default {
  props: {
    filename: String,
    label: String,
  },
}
</script>

<style scoped>
.cardStyle {
  background: #ffffff;
  border-radius: 10px;
}
.label-text {
  font-size: 16px;
  color: #013f81 !important;
}
img {
  width: 46px;
  height: 37px;
}
</style>
