<template>
  <b-col class="h-full" cols="12" align-self="center">
    <b-card class="h-full" :class="branch.expire == 'pending' ? 'pointer' : 'pointer'">
      <b-col cols="12" class="text-right" align-self="center">
        <b-icon
          class="cursor-pointer"
          icon="pencil-square"
          style="color: #007afe; font-size: 24px"
          @click="$parent.clickEdit(branch)"
        ></b-icon>
      </b-col>
      <h1
        @click="$parent.cardClicked(branch.expire, branch)"
        class="mb-1 wrap-word"
        style="color: #014083; font-size: 18px"
      >
        {{ branch.company_name }}
      </h1>

      <h2 @click="$parent.cardClicked(branch.expire, branch)">
        {{ branch.branch_name }}
      </h2>
      <p v-if="branch.expire" class="py-3" @click="$parent.cardClicked(branch.expire, branch)">
        <span v-if="branch.expire === 'pending'">
          {{ $t('company_select').pending }}
        </span>
        <span v-else>
          {{ branch.package }} - {{ $t('company_select').exp }}
          {{ branch.expire }}
        </span>
      </p>
      <div v-else>
        <p class="py-3">
          {{ $t('company_select').pay }}
        </p>
        <b-row class="gap-y-2">
          <b-col class="" cols="12" xl="6">
            <Button
              :text="$t('promotion').trial"
              btnColor="blue-border-btn"
              style="z-index: 2"
              class="w-full"
              @click.native="$parent.trial(branch)"
            />
          </b-col>
          <b-col cols="12" xl="6">
            <Button
              :text="$t('promotion').select"
              btnColor="blue-btn"
              style="z-index: 2"
              class="w-full"
              @click.native="$parent.cardClicked(branch.expire, branch)"
            />
          </b-col>
        </b-row>
      </div>
      <Button
        :text="$t('packages').upgrade"
        btnColor="blue-btn"
        style="z-index: 2"
        @click.native="$parent.changePackage(branch)"
        v-if="branch.package == 'free trial'"
      />
    </b-card>
  </b-col>
</template>

<script>
import Button from '../general/general_button.vue'

export default {
  props: {
    branch: Object,
  },
  components: {
    Button,
  },
}
</script>

<style scoped>
.cardStyle {
  background: #ffffff;
  border-radius: 10px;
  color: #014083;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
h1 {
  font-size: 18px;
  overflow: hidden;
  word-wrap: break-word;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
h2 {
  font-size: 14px !important;
  overflow: hidden;
  word-wrap: break-word;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: #014083;
}
p {
  font-size: 12px;
  color: #007afe;
}
</style>
