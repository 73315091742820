<template>
  <div>
    <b-container fluid>
      <b-row>
        <b-col cols="12">
          <b-card class="paymentDetail px-3 py-3">
            <h1 class="pb-3">{{ $t('payment_detail').payment_detail }}</h1>
            <b-row class="mt-3">
              <b-col cols="5" class="text-left px-0">
                <h2>{{ $t('payment_detail').packageName }}</h2>
              </b-col>
              <b-col cols="2"> </b-col>

              <b-col cols="5" class="text-end px-0" v-if="getPackage_id != 1">
                <p>{{ packageText }}</p>
                <p>{{ packageTextYear }}</p>
              </b-col>
              <b-col cols="5" class="text-end px-0" v-else>
                <p>{{ $t('packages').free_day }}</p>
              </b-col>
            </b-row>
            <b-row class="mt-3">
              <b-col cols="5" class="text-left px-0">
                <h2>{{ $t('payment_detail').price }}</h2>
              </b-col>
              <b-col cols="2"> </b-col>
              <b-col cols="5" class="text-end px-0">
                <p>{{ packagePrice }}</p>
              </b-col>
            </b-row>
            <b-row class="mt-3">
              <b-col cols="9" class="text-left px-0 d-flex">
                <b-form-checkbox
                  id="checkbox-1"
                  v-model="getWHT"
                  name="checkbox-1"
                  v-if="isPaymentPage"
                  @change="changeValueWHT"
                >
                  <p class="text-blue pl-1" style="word-wrap: break-word">
                    {{ $t('payment_detail').wht }} (3%)
                  </p>
                </b-form-checkbox>
                <h2 v-else>{{ $t('payment_detail').wht }} (3%)</h2>
              </b-col>

              <b-col cols="3" class="text-end px-0">
                <p>{{ whtPrice }}</p>
              </b-col>
            </b-row>
            <b-row class="mt-3">
              <hr class="w-full" />
            </b-row>
            <b-row class="mt-3">
              <b-col cols="5" class="text-left px-0">
                <h2>{{ $t('payment_detail').total }}</h2>

                <p style="font-size: 12px; color: #545454" class=",t-2">
                  {{ $t('payment_detail.vat_include') }}
                </p>
              </b-col>
              <b-col cols="2"> </b-col>
              <b-col cols="5" class="text-end px-0">
                <p>
                  {{
                    totalPrice
                      .toFixed(2)
                      .toLocaleString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                  }}
                </p>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      // wht: Boolean,
    }
  },
  props: {
    package: Object,
    page: String,
  },
  methods: {
    changeValueWHT() {
      this.$store.commit('ISWTH', !this.getWHT)
    },
  },
  mounted() {
    this.$store.dispatch('totalPriceAction', parseFloat(this.totalPrice))
  },
  computed: {
    ...mapGetters(['getWHT', 'getPackage_id']),
    packageText() {
      let packageText = null
      if (this.$i18n.locale === 'en') {
        packageText = `${this.package.package_assets.toLocaleString()} assets  `
      } else {
        packageText = `${this.package.package_assets.toLocaleString()} ชิ้น `
      }

      return packageText
    },
    packageTextYear() {
      let packageText = null
      if (this.package.package_assets == 40) {
        if (this.$i18n.locale === 'en') {
          packageText = ` (${this.package.package_lifetime} Days)`
        } else {
          packageText = ` (${this.package.package_lifetime} วัน)`
        }
      } else {
        if (this.$i18n.locale === 'en') {
          packageText = ` (${this.package.package_lifetime / 365} Years)`
        } else {
          packageText = ` (${this.package.package_lifetime / 365} ปี)`
        }
      }

      return packageText
    },
    packagePrice() {
      let price = this.package.package_price
      return price
        .toFixed(2)
        .toLocaleString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    whtPrice() {
      if (this.getWHT) {
        return ((this.package.package_price / 1.07) * 0.03)
          .toFixed(2)
          .toLocaleString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }
      return '0'
    },
    totalPrice() {
      let total = this.package.package_price

      if (this.getWHT) {
        total = total - (total / 1.07) * 0.03
      }
      if (this.package.package_discount > 0) {
        total = total - this.package.package_discount
      }
      return total
    },
    isPaymentPage() {
      return this.page === 'Payment'
    },
  },
  watch: {
    getWHT() {
      this.$store.dispatch('totalPriceAction', parseFloat(this.totalPrice))
    },
  },
}
</script>

<style scoped>
.paymentDetail h1 {
  font-weight: 600;
  font-size: 18px;
  color: #014083;
}
.text-blue {
  color: #014083;
  font-weight: 600;
  font-size: 14px;
}
h2 {
  font-weight: 600;
  font-size: 14px !important;
  color: #014083;
}
.packageText {
  font-weight: 500;
  font-size: 14px;
  color: #014083;
}
p {
  font-size: 14px;
}
hr {
  background-color: #014083;
  height: 1px;
  border: none;
  color: #014083;
}
</style>
