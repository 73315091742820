<template>
  <div>
    <b-container fluid>
      <b-row class="justify-content-center">
        <b-col cols="12" class="p-0">
          <b-row>
            <b-col
              md="4"
              lg="4"
              cols="4"
              order-xl="1"
              order-lg="1"
              order-md="1"
              order="1"
              class="d-flex justify-content-start p-0"
            >
              <img
                src="../../assets/material/footer_logo.png"
                width="100"
                height="100"
                class="mt-2 mt-lg-1"
                alt="footer-logo"
              />
            </b-col>
            <b-col
              md="6"
              lg="6"
              cols="8"
              align-self="center"
              order-xl="2"
              order-lg="2"
              order="2"
              class="text-start"
            >
              <h2 style="font-size: 18px !important">
                {{ $t('footer.verscan') }}
              </h2>
              <p class="my-2">
                {{ $t('footer.assets_systems') }}
              </p>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <!-- <b-row>
      <b-col cols="8" class="text-center text-md-start">
        <b-row class="d-flex flex-column align-items-center flex-md-row">
          <li @click="goToTerm('term')">a</li>
          <li @click="goToTerm('policy')">b</li>
          <li @click="goToTerm('data')">c</li>
        </b-row>
      </b-col>
    </b-row> -->
    </b-container>
    <b-container fluid>
      <b-row class="mt-5">
        <b-col cols="12" class="text-center text-md-start d-none d-md-block">
          <b-row class="d-flex flex-column align-items-center flex-md-row">
            <!-- <li @click="goToTerm('term')">{{$t("footer.terms_of_use")}}</li> -->
            <li class="p-0" @click="goToTerm('policy')">{{ $t('footer.privacy_policy') }}</li>
            <li @click="goToTerm('data')">{{ $t('footer.data_deletion') }}</li>
            <li @click="goToTerm('sla')">{{ $t('footer.sla') }}</li>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
export default {
  methods: {
    goToTerm(name) {
      let route = {}
      if (name === 'term') {
        route = this.$router.resolve({ name: 'Terms' })
      }
      if (name === 'sla') {
        route = this.$router.resolve({ name: 'SLA' })
      }
      if (name === 'policy') {
        route = this.$router.resolve({ name: 'Policy' })
      }
      if (name === 'data') {
        route = this.$router.resolve({ name: 'DataDelete' })
      }
      window.open(route.href, '_blank')
    },
  },
}
</script>

<style scoped>
p {
  font-size: 12px;
}
img {
  height: max-content;
}

li {
  font-size: 12px;
  width: fit-content;
  cursor: pointer;
}

/* .space-text {
  margin-left: 0.5rem;
  text-align: start;
} */

@media only screen and (min-width: 1690px) {
  /* .space-text {
    margin-left: 0rem;
    text-align: start;
  } */
}
@media only screen and (min-width: 1440px) {
  /* .space-text {
    margin-left: 1rem;
    text-align: start;
  } */
}

@media only screen and (min-width: 1200px) {
  /* .space-text {
    margin-left: 1rem;
    text-align: start;
  } */
}
@media only screen and (min-width: 992px) {
}
@media only screen and (max-width: 991px) and (min-width: 576px) {
  /* .space-text {
    margin-top: 1rem;
    text-align: start;
  } */
}
@media only screen and (max-width: 575px) and (min-width: 425px) {
}
@media only screen and(max-width: 424px) and (min-width: 320px) {
}
</style>
