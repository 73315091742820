<template>
  <b-container fluid class="px-0">
    <div class="home">
      <sec1 class="py-4 my-2 w-10/12" />
      <Tutorial id="section-tutorial" class="mb-5" />
      <sec3 class="my-5 w-10/12" />

      <sec5 />
      <sec6 class="w-10/12" />
      <customer />
      <sec7 id="section-7" />
      <sec8 class="w-10/12" />
      <Footer />
    </div>
  </b-container>
</template>

<script>
import Footer from '../components/footer.vue'
import { mapMutations } from 'vuex'
import sec1 from '../components/home/section1.vue'
import sec3 from '../components/home/section3.vue'
import sec5 from '../components/home/section5.vue'
import sec6 from '../components/home/section6.vue'
import sec7 from '../components/home/section7.vue'
import sec8 from '../components/home/section8.vue'
import customer from '../components/home/customer.vue'
import Tutorial from '../components/home/section_tutorial.vue'
import '@/assets/css/home.css'

export default {
  name: 'Home',
  components: {
    Footer,
    sec1,
    sec3,
    sec5,
    sec6,
    sec7,
    sec8,
    customer,
    Tutorial,
  },
  mounted() {
    this.selectPackage(false)
    this.$store.commit('loadingMutations', false)
    this.$store.commit('PACKAGE_DETAIL_MUTATION', false)
    this.$store.commit('billMutations', {})
    this.$store.commit('createBillForConfirmMutation', null)
    this.$store.commit('current_branchMutations', null)
  },
  methods: {
    ...mapMutations({
      selectPackage: 'selected_packageMutations', // map `this.add()` to `this.$store.commit('increment')`
    }),

    choosePackages(plan) {
      if (plan == 'free') {
        this.$router.push('/packages')
        this.selectPackage({
          package_name: 'trial',
          package_assets: 0,
          lifetime_years: 1,
          package_addon: false,
        })
      } else if (plan == 's') {
        this.$router.push('/packages')
        this.selectPackage({
          package_name: 'S',
          package_assets: 500,
          lifetime_years: 1,
          package_addon: false,
        })
      } else if (plan == 'm') {
        this.$router.push('/packages')
        this.selectPackage({
          package_name: 'M',
          package_assets: 2500,
          lifetime_years: 1,
          package_addon: false,
        })
      } else if (plan == 'l') {
        this.$router.push('/packages')
        this.selectPackage({
          package_name: 'L',
          package_assets: 4000,
          lifetime_years: 1,
          package_addon: false,
        })
      }
    },
  },
}
</script>

<style>
.home {
  background-color: white;
  overflow-x: hidden;
}
.b-card {
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

p {
  font-size: 16px;
}

.card {
  background: #ffffff;
  border-radius: 10px;
  color: black;
}

.cardStyle {
  background: #ffffff;
  border-radius: 15px;
  color: #014083;
  box-shadow: 0px 4px 4px 2px rgba(0, 0, 0, 0.1);
}

.free-trial-but {
  width: 218px;
  height: 86px;
}
.cursor-pointer {
  cursor: pointer !important;
}
</style>
