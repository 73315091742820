<template>
  <b-container class="mb-5">
    <b-row>
      <b-col xl="5" lg="5" md="5" align-self="center">
        <img class="d-none-pic" src="@/assets/material/home_sec6.png" alt=""/>
      </b-col>
      <b-col xl="7" lg="7" md="7" sm="12" xs="12" align-self="center">
        <h1 class="font-bold text-center text-md-start" style="line-height: inherit">
          {{ $t('home').section7.header }}
        </h1>

        <List
          v-for="(service, index) in $t('home').section7.services"
          :key="index"
          class="text-black my-3"
          filename="blue-circle.svg"
          :text="service"
        />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import List from './list_with_icon.vue'
export default {
  setup() {
    return {}
  },
  components: {
    List,
  },
}
</script>

<style scoped>
h1 {
  color: #0158b6;
  font-size: 36px;
  line-height: inherit;
}
</style>