<template>
  <div class="grid content-center p-3 w-full">
    <div class="flex justify-center w-full mb-5">
      <div
        class="flex justify-center w-full mb-5"
        :class="white ? 'white-lds-ring ' : 'lds-ring '"
      >
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
    <p class="flex justify-center w-full" :class="white ? 'text-white' : ''">
      {{ $t("packages").loading }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    white: Boolean,
  },
};
</script>

<style scoped>
p {
  color: #0051d4;
}
.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #0051d4;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #0051d4 transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.white-lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid white;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: white transparent transparent transparent;
}
.white-lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.white-lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.white-lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes white-lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>