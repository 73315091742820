<template>
  <div class="policy">
    <div class="container">
      <div class="info">
        <h1 class="text-center py-4 font-bold">{{ $t('sla.header') }}</h1>
        <p>{{ $t('sla.detail') }}</p>
        <b class="py-3" style="font-size: 24px;">{{ $t('sla.service') }}</b>
        <b>{{ $t('sla.sell_and_account.header') }}</b>
        <ul>
          <li v-for="(detail, index) in $t('sla.sell_and_account.detail')" :key="index">
            {{ detail }}
          </li>
        </ul>
        <b class="pt-3">{{ $t('sla.backup.header') }}</b>
        <ul>
          <li v-for="(detail, index) in $t('sla.backup.detail')" :key="index">
            {{ detail }}
          </li>
        </ul>
        <b class="pt-3">{{ $t('sla.log.header') }}</b>
        <ul>
          <li v-for="(detail, index) in $t('sla.log.detail')" :key="index">
            {{ detail }}
          </li>
        </ul>
        <b class="pt-3">{{ $t('sla.contact.header') }}</b>
        <ul>
          <li v-for="(detail, index) in $t('sla.contact.detail')" :key="index">
            {{ detail.detail }}
            <ul>
              <li v-for="(sub, index) in detail.sub_detail" :key="index">{{ sub }}</li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Footer from '../../components/footer.vue'
export default {
  components: {
    Footer,
  },
}
</script>

<style scoped>
h1 {
  font-size: 28px;
  color: #000;
}

b {
  display: block;
  margin-bottom: 1rem;
  color: #000;
}

p {
  margin-bottom: 1rem;
  font-weight: 300;
  color: #000;
}

* >>> a {
  color: #007bff;
}

ul {
  list-style-type: disc;
  margin-bottom: 1rem;
  margin-left: 1rem;
  color: #000;
  font-weight: 300;
}

li {
  margin-left: 1rem;
}

.policy {
  text-align: left;
  background-color: white;
}

.info {
  padding: 0 40px 50px 40px;
}
</style>
