<template>
  <b-row align-h="center">
    <b-col
      cols="10"
      class="d-flex justify-content-center flex-column mt-5 xl:px-60 lg:px-48 md:px-36"
    >
      <h1 class="py-3">{{ this.$t('price_table').order }}</h1>
      <!-- 
      <b-table
        :fields="fields"
        :items="items"
        head-row-variant="light"
        thead-tr-class="headerStyle"
        thead-class="headerStyle"
        class="bordered"
        hover
        bordered
      >
      </b-table> -->
      <div class="price-calc-table">
        <div class="d-flex flex-column">
          <div class="header">
            <p>{{ this.$t('price_table').sticker_price }}</p>
          </div>
          <div class="d-flex data">
            <div
              class="w-50"
              style="
                padding: 10px 0;
                border-right: 1px solid;
                border-color: rgba(229, 231, 235, var(--tw-border-opacity));
              "
            >
              <div class="d-flex align-items-center justify-content-between px-2">
                <div class="w-full">
                  <p>{{ qrAmount.toLocaleString() }}</p>
                </div>
                <div class="d-flex flex-column">
                  <b-button @click="qrAmount += 100">+</b-button>
                  <b-button :disabled="qrAmount <= 100" @click="qrAmount -= 100">-</b-button>
                </div>
              </div>
            </div>
            <div
              class="w-50 d-flex flex-column justify-content-center"
              style="
                padding: 10px 0;
                border-left: 1px solid;
                border-color: rgba(229, 231, 235, var(--tw-border-opacity));
              "
            >
              <p>{{ this.$t('price_table').price }}</p>
              <p>{{ (qrAmount * 5).toLocaleString() }}</p>
            </div>
          </div>
        </div>
      </div>
      <p class="text-left text-dark">*{{ $t('price_table').set }}</p>
      <p class="text-left text-dark">{{ $t('price_table').shipping_exclude }}</p>
    </b-col>
  </b-row>
</template>

<script>
export default {
  data() {
    return {
      fields: [
        {
          key: 'min',
          label: this.$t('price_table').min,
          thStyle: {
            background: '#015fc3',
            color: 'white',
            borderTopLeftRadius: '10px',
          },
        },
        {
          key: 'price',
          label: this.$t('price_table').per,
          thStyle: {
            background: '#015fc3',
            color: 'white',
            borderTopRightRadius: '10px',
          },
        },
      ],
      items: [
        {
          min: '100 - 400 ' + this.$t('price_table').sticker,
          price: '5 ' + this.$t('price_table').baht,
        },
        {
          min: '500 - 700 ' + this.$t('price_table').sticker,
          price: '4.50 ' + this.$t('price_table').baht,
        },
        {
          min: '800++ ' + this.$t('price_table').sticker,
          price: '4 ' + this.$t('price_table').baht,
        },
      ],
      qrAmount: 100,
    }
  },
  watch: {
    '$i18n.locale': function () {
      this.fields[0].label = this.$t('price_table').min
      this.fields[1].label = this.$t('price_table').per
      this.items[0].min = '100 - 400 ' + this.$t('price_table').sticker
      this.items[0].price = '5 ' + this.$t('price_table').baht
      this.items[1].min = '500 - 700 ' + this.$t('price_table').sticker
      this.items[1].price = '4.50 ' + this.$t('price_table').baht
      this.items[2].min = '800++ ' + this.$t('price_table').sticker
      this.items[2].price = '4 ' + this.$t('price_table').baht
    },
  },
}
</script>

<style scoped>
h1 {
  font-size: 24px;
  color: #015fc3;
  font-weight: 700;
}
.headerStyle {
  background: #015fc3 !important;
  color: white !important;
}
.price_table p {
  color: #888;
  max-width: 1000px !important;
  margin-left: 30px;
}

table {
  border-collapse: separate !important;
  /* max-width: 1000px !important; */
  border-spacing: 0;

  /* margin: 5px 30px; */
  padding-right: 0;
  padding-left: 0;
}
.bordered {
  border: solid #ccc 1px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  -webkit-box-shadow: 0 1px 1px #ccc;
  -moz-box-shadow: 0 1px 1px #ccc;
  box-shadow: 0 1px 1px #ccc;
}
.bordered tr:hover {
  background: #ececec;
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
.bordered td,
.bordered th {
  border-left: 1px solid #ccc;
  border-top: 1px solid #ccc;
  padding: 10px;
  text-align: left;
}
.bordered th {
  background-color: #ececec;
  background-image: -webkit-gradient(linear, left to bottom, left bottom, from(#f8f8f8), to(#ececec));
  background-image: -webkit-linear-gradient(to bottom, #f8f8f8, #ececec);
  background-image: -moz-linear-gradient(to bottom, #f8f8f8, #ececec);
  background-image: linear-gradient(to bottom, #f8f8f8, #ececec);
  -webkit-box-shadow: 0 1px 0 rgba(255, 255, 255, 0.8) inset;
  -moz-box-shadow: 0 1px 0 rgba(255, 255, 255, 0.8) inset;
  box-shadow: 0 1px 0 rgba(255, 255, 255, 0.8) inset;
  border-top: none;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
}
.bordered td:first-child,
.bordered th:first-child {
  border-left: none;
}
.bordered th:first-child {
  -moz-border-radius: 10px 0 0 0;
  -webkit-border-radius: 10px 0 0 0;
  border-radius: 10px 0 0 0;
}
.bordered th:last-child {
  -moz-border-radius: 0 10px 0 0;
  -webkit-border-radius: 0 10px 0 0;
  border-radius: 0 10px 0 0;
}
.bordered th:only-child {
  -moz-border-radius: 10px 10px 0 0;
  -webkit-border-radius: 10px 10px 0 0;
  border-radius: 10px 10px 0 0;
}
.bordered tr:last-child td:first-child {
  -moz-border-radius: 0 0 0 10px;
  -webkit-border-radius: 0 0 0 10px;
  border-radius: 0 0 0 10px;
}
.bordered tr:last-child td:last-child {
  -moz-border-radius: 0 0 10px 0;
  -webkit-border-radius: 0 0 10px 0;
  border-radius: 0 0 10px 0;
}

.price-calc-table {
  color: black;
}

.price-calc-table .header {
  padding: 15px;
  text-align: left;
  color: #fff;
  background: #015fc3;
  border-radius: 10px 10px 0 0;
  font-weight: 700;
}
.price-calc-table .data {
  border-left: 2px solid;
  border-bottom: 2px solid;
  border-right: 2px solid;
  border-radius: 0 0 10px 10px;
  border-color: rgba(229, 231, 235, var(--tw-border-opacity));
}

.price-calc-table button {
  padding: 0px 10px;
  background: #015fc3;
  border-color: #015fc3;
  margin: 5px 0 5px 5px;
}
.price-calc-table button:disabled {
  background: #7c7c7c;
  border-color: #7c7c7c;
}
</style>
