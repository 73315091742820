<template>
  <div class="container">
    <h1 class="font-bold head-sec">{{ $t('home').customer }}</h1>

    <!-- <carousel
      :autoplay="false"
      :loop="true"
      :autoplayTimeout="4000"
      :perPageCustom="[
        [576, 3],
        [768, 4],
        [992, 5],
        [1200, 7],
      ]"
      class="w-100"
      style="margin-bottom: 70px"
    >
      <slide
        ><div class="img-style mx-auto">
          <img
            class="h-100 w-100 object-contain"
            src="@/assets/material/customer_img/go.png"
            alt=""
          /></div
      ></slide>
      <slide
        ><div class="img-style mx-auto">
          <img
            class="h-100 w-100 object-contain"
            src="@/assets/material/customer_img/jt.png"
            alt=""
          /></div
      ></slide>
      <slide
        ><div class="img-style mx-auto">
          <img
            class="h-100 w-100 object-contain"
            src="@/assets/material/customer_img/jagota.png"
            alt=""
          /></div
      ></slide>
      <slide
        ><div class="img-style mx-auto">
          <img
            class="h-100 w-100 object-contain"
            src="@/assets/material/customer_img/parame.png"
            alt=""
          /></div
      ></slide>
      <slide
        ><div class="img-style mx-auto">
          <img
            class="h-100 w-100 object-contain"
            src="@/assets/material/customer_img/Thaiyarnyon.png"
            alt=""
          /></div
      ></slide>
      <slide
        ><div class="img-style mx-auto">
          <img
            class="h-100 w-100 object-contain"
            src="@/assets/material/customer_img/vejthani.png"
            alt=""
          /></div
      ></slide>

      <slide
        ><div class="img-style mx-auto">
          <img
            class="h-100 w-100 object-contain"
            src="@/assets/material/customer_img/river_kwai.png"
            alt=""
          /></div
      ></slide>
      <slide
        ><div class="img-style mx-auto">
          <img
            class="h-100 w-100 object-contain"
            src="@/assets/material/customer_img/CDC.png"
            alt=""
          /></div
      ></slide>
      <slide
        ><div class="img-style mx-auto">
          <img
            class="h-100 w-100 object-contain"
            src="@/assets/material/customer_img/KE.png"
            alt=""
          /></div
      ></slide>
    </carousel> -->

    <agile :options="agileOptions" class="head-sec">
      <div class="slide">
        <div class="img-style">
          <img
            class="h-100 w-100 object-contain"
            src="@/assets/material/customer_img/go.png"
            alt="logo"
          />
        </div>
      </div>
      <div class="slide">
        <div class="img-style">
          <img
            class="h-100 w-100 object-contain"
            src="@/assets/material/customer_img/jt.png"
            alt="logo"
          />
        </div>
      </div>
      <div class="slide">
        <div class="img-style">
          <img
            class="h-100 w-100 object-contain"
            src="@/assets/material/customer_img/jagota.png"
            alt="logo"
          />
        </div>
      </div>
      <div class="slide">
        <div class="img-style">
          <img
            class="h-100 w-100 object-contain"
            src="@/assets/material/customer_img/parame.png"
            alt="logo"
          />
        </div>
      </div>
      <div class="slide">
        <div class="img-style">
          <img
            class="h-100 w-100 object-contain"
            src="@/assets/material/customer_img/Thaiyarnyon.png"
            alt="logo"
          />
        </div>
      </div>
      <div class="slide">
        <div class="img-style">
          <img
            class="h-100 w-100 object-contain"
            src="@/assets/material/customer_img/vejthani.png"
            alt="logo"
          />
        </div>
      </div>
      <div class="slide">
        <div class="img-style">
          <img
            class="h-100 w-100 object-contain"
            src="@/assets/material/customer_img/river_kwai.png"
            alt="logo"
          />
        </div>
      </div>
      <div class="slide">
        <div class="img-style">
          <img
            class="h-100 w-100 object-contain"
            src="@/assets/material/customer_img/CDC.png"
            alt="logo"
          />
        </div>
      </div>
      <div class="slide">
        <div class="img-style">
          <img
            class="h-100 w-100 object-contain"
            src="@/assets/material/customer_img/KE.png"
            alt="logo"
          />
        </div>
      </div>

      <div class="slide">
        <div class="img-style">
          <img
            class="h-100 w-100 object-contain"
            src="@/assets/material/customer_img/Learn.png"
            alt="logo"
          />
        </div>
      </div>
      <div class="slide">
        <div class="img-style">
          <img
            class="h-100 w-100 object-contain"
            src="@/assets/material/customer_img/NinjaVan.png"
            alt="logo"
          />
        </div>
      </div>
      <div class="slide">
        <div class="img-style">
          <img
            class="h-100 w-100 object-contain"
            src="@/assets/material/customer_img/Sumitomo.png"
            alt="logo"
          />
        </div>
      </div>
      <div class="slide">
        <div class="img-style">
          <img
            class="h-100 w-100 object-contain"
            src="@/assets/material/customer_img/BLS.png"
            alt="logo"
          />
        </div>
      </div>
      <div class="slide">
        <div class="img-style">
          <img
            class="h-100 w-100 object-contain"
            src="@/assets/material/customer_img/BCAP.png"
            alt="logo"
          />
        </div>
      </div>
      <div class="slide">
        <div class="img-style">
          <img
            class="h-100 w-100 object-contain"
            src="@/assets/material/customer_img/APC.png"
            alt="logo"
          />
        </div>
      </div>
    </agile>
  </div>
</template>

<script>
// import { Carousel, Slide } from 'vue-carousel'
export default {
  components: {
    // Carousel,
    // Slide,
  },
  data() {
    return {
      agileOptions: {
        autoplay: true,
        slidesToShow: 2,
        navButtons: false,
        pauseOnHover: false,
        dots: true,
        responsive: [
          {
            breakpoint: 576,
            settings: {
              slidesToShow: 3,
            },
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 4,
            },
          },
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 5,
            },
          },
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 7,
            },
          },
        ],
      },
    }
  },
}
</script>

<style lang="css" scoped>
h1 {
  color: #0158b6;
  font-size: 36px;
  line-height: inherit;
}
.img-style {
  height: 100px;
}

.img-container {
  max-height: 100px;
  max-width: 200px;
  margin: 15px 0px;
}

.customer-img {
  height: 100%;
  object-fit: contain;
  margin: 0 auto;
}

.head-sec ::v-deep.agile__dot button {
  cursor: pointer;
  display: block;
  font-size: 0;
  line-height: 0;
  background: #c4c4c4;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  margin: 0px 5px;
}
.head-sec ::v-deep.agile__actions {
  margin-top: 30px;
}

.head-sec ::v-deep.agile__dot--current button {
  background: #0158b6;
}
</style>
