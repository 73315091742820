<template>
  <b-container >
    <b-row id="header">
      <div class="text-center" >
        <h1 class="head-sec font-bold" >
          {{ $t('promotion').package_promotion }}
        </h1>
      </div>
      <b-container>
        <b-row>
          <b-col></b-col>
          <b-col cols="10">
            <b-row class="d-flex justify-content-center">
              <!-- free -->
              <b-col
                xl="3"
                lg="6"
                md="6"
                sm="12"
                xs="12"
                class="mb-lg-3 mb-md-3 mb-sm-3 mb-3 d-flex"
              >
                <b-card class="cardStyle flex-col h-full w-full">
                  <b-col cols="10">
                    <div class="badge-free">
                      <h1 class="text-light my-1 p-2 package-name">
                        {{ $t('promotion').free_trial }}
                      </h1>
                    </div>
                  </b-col>

                  <b-col cols="12" class="text-center mt-3">
                    <h1 class="invisible">
                      {{ $t('promotion').start }}
                    </h1>
                    <h1 style="color: #ee6162" class="monthly-price">
                      {{ $t('promotion').free }}
                    </h1>

                    <div class="mx-3 my-1">
                      <p class="grey mb-2">
                        {{ $t('promotion.free_duration') }}
                      </p>
                      <hr style="border: 2px solid #ee6162" />
                    </div>
                    <b-col cols="12" class="mt-3 mx-3 text-left">
                      <div class="d-flex">
                        <b-icon
                          icon="check-circle"
                          style="color: #ee6162"
                          font-scale="1"
                          class="mt-1"
                        ></b-icon>
                        <p class="px-2">{{ $t('promotion').unlimit_user }}</p>
                      </div>
                      <!-- <div class="d-flex">
                        <b-icon
                          icon="check-circle"
                          style="color: #ee6162"
                          font-scale="1"
                          class="mt-1"
                        ></b-icon>
                        <p class="px-2">
                          {{ $t('promotion').free_stricker_40 }}
                        </p>
                      </div> -->
                    </b-col>
                    <b-col cols="12" class="text-center mt-3 invisible">
                      <div class="mx-3 my-3">
                        <p style="color: #10b7dc">
                          {{ $t('promotion').next_year }}
                        </p>
                        <p class="grey">{{ $t('promotion').pay_system }}</p>
                        <!-- <p v-if="$i18n.locale ==='en'" class="grey">{{$t('promotion').pay_system_2}}</p> -->
                      </div>
                    </b-col>

                    <b-col cols="12" class="text-center invisible">
                      <p class="px-2 cols-12 mt-3 mb-2">
                        {{ $t('promotion').amount_asset }}
                      </p>

                      <div class="border-s cols-12 d-flex justify-content-center mx-4 p-1">
                        <b-icon
                          icon="box"
                          style="color: #10b7dc"
                          font-scale="1"
                          class="my-1 mx-2"
                        ></b-icon>
                        <p>{{ sLimit }}</p>
                      </div>
                    </b-col>
                  </b-col>

                  <template #footer>
                    <button
                      class="w-full p-2 button-free"
                      :class="{ 'button-free-thai': !isEn }"
                      @click="choosePackages(0)"
                    >
                      <p>{{ $t('promotion').trial }}</p>
                    </button>
                  </template>
                </b-card>
              </b-col>
              <!-- s pack -->
              <b-col
                xl="3"
                lg="6"
                md="6"
                sm="12"
                xs="12"
                class="mb-lg-3 mb-md-3 mb-sm-3 mb-3 d-flex"
              >
                <b-card class="cardStyle w-full">
                  <b-col cols="10">
                    <div class="badge-s package-name">
                      <h1 class="text-light my-1 p-2">
                        S <span class="package-word">Package</span>
                      </h1>
                    </div>
                  </b-col>

                  <b-col cols="12" class="text-center mt-3">
                    <div style="color: #10b7dc">
                      <h1>
                        {{ $t('promotion').start }}
                      </h1>
                      <h1 class="monthly-price">
                        748 /
                        {{ this.$t('promotion').month }}
                      </h1>
                    </div>
                    <div class="mx-3 my-1">
                      <p class="grey mb-2">{{ $t('promotion.s_duration') }}</p>
                      <hr style="border: 2px solid #10b7dc" />
                    </div>
                    <div class="mx-3 my-3">
                      <p style="color: #10b7dc">
                        {{ $t('promotion').next_year }}
                      </p>
                      <p class="grey">{{ $t('promotion').pay_system }}</p>
                      <!-- <p v-if="$i18n.locale ==='en'" class="grey">{{$t('promotion').pay_system_2}}</p> -->
                    </div>
                  </b-col>
                  <b-col cols="12" class="text-center">
                    <p class="px-2 cols-12 mt-3 mb-2">
                      {{ $t('promotion').amount_asset }}
                    </p>

                    <div class="border-s cols-12 d-flex justify-content-center mx-4 p-1">
                      <b-icon
                        icon="box"
                        style="color: #10b7dc"
                        font-scale="1"
                        class="my-1 mx-2"
                      ></b-icon>
                      <p>{{ sLimit }}</p>
                    </div>
                  </b-col>
                  <b-col cols="12" class="mt-3 mx-3 text-left">
                    <div class="d-flex">
                      <b-icon
                        icon="check-circle"
                        style="color: #10b7dc"
                        font-scale="1"
                        class="mt-1"
                      ></b-icon>
                      <p class="px-2">{{ $t('promotion').unlimit_user }}</p>
                    </div>
                    <!-- <div class="d-flex">
                      <b-icon
                        icon="check-circle"
                        style="color: #10b7dc"
                        font-scale="1"
                        class="mt-1"
                      ></b-icon>
                      <p class="px-2">
                        {{ $t('promotion').free_stricker_300 }}
                      </p>
                    </div>
                    <div class="d-flex">
                      <b-icon
                        icon="check-circle"
                        style="color: #10b7dc"
                        font-scale="1"
                        class="mt-1 invisible"
                      ></b-icon>
                      <p class="px-2">
                        {{ $t('promotion').date }}
                      </p>
                    </div> -->
                  </b-col>
                  <template #footer>
                    <button class="button-s p-2 w-full" @click="choosePackages(8980)">
                      <p>{{ $t('promotion').select }}</p>
                    </button>
                  </template>
                </b-card>
              </b-col>
              <!-- m pack -->
              <b-col
                xl="3"
                lg="6"
                md="6"
                sm="12"
                xs="12"
                class="mb-lg-3 mb-md-3 mb-sm-3 mb-3 d-flex"
              >
                <b-card class="cardStyle w-full">
                  <b-col cols="10">
                    <div class="badge-m">
                      <h1 class="text-light my-1 p-2 package-name">
                        M <span class="package-word">Package</span>
                      </h1>
                    </div>
                  </b-col>

                  <b-col cols="12" class="text-center mt-3">
                    <div style="color: #2469ed">
                      <h1>
                        {{ $t('promotion').start }}
                      </h1>
                      <h1 class="monthly-price">1,490 /{{ this.$t('promotion').month }}</h1>
                    </div>
                    <div class="mx-3 my-1">
                      <p class="grey mb-2">{{ $t('promotion.m_duration') }}</p>
                      <hr style="border: 2px solid #2469ed" />
                    </div>
                    <div class="mx-3 my-3">
                      <p style="color: #2469ed">
                        {{ $t('promotion').next_year }}
                      </p>
                      <p class="grey">{{ $t('promotion').pay_system }}</p>
                      <!-- <p v-if="$i18n.locale ==='en'" class="grey">{{$t('promotion').pay_system_2}}</p> -->
                    </div>
                  </b-col>
                  <b-col cols="12" class="text-center">
                    <p class="px-2 cols-12 mt-3 mb-2">
                      {{ $t('promotion').amount_asset }}
                    </p>

                    <div class="border-m cols-12 d-flex justify-content-center mx-4 p-1">
                      <b-icon
                        icon="box"
                        style="color: #2469ed"
                        font-scale="1"
                        class="my-1 mx-2"
                      ></b-icon>
                      <p>{{ mLimit }}</p>
                    </div>
                  </b-col>
                  <b-col cols="12" class="mt-3 mx-3 text-left">
                    <div class="d-flex">
                      <b-icon
                        icon="check-circle"
                        style="color: #2469ed"
                        font-scale="1"
                        class="mt-1"
                      ></b-icon>
                      <p class="px-2">{{ $t('promotion').unlimit_user }}</p>
                    </div>
                    <!-- <div class="d-flex">
                      <b-icon
                        icon="check-circle"
                        style="color: #2469ed"
                        font-scale="1"
                        class="mt-1"
                      ></b-icon>
                      <p class="px-2">
                        {{ $t('promotion').free_stricker_300 }}
                      </p>
                    </div>
                    <div class="d-flex">
                      <b-icon
                        icon="check-circle"
                        style="color: #10b7dc"
                        font-scale="1"
                        class="mt-1 invisible"
                      ></b-icon>
                      <p class="px-2">
                        {{ $t('promotion').date }}
                      </p>
                    </div> -->
                  </b-col>
                  <template #footer>
                    <button class="button-m p-2 w-full" @click="choosePackages(17880)">
                      <p>{{ $t('promotion').select }}</p>
                    </button>
                  </template>
                </b-card>
              </b-col>
              <!-- l pack -->
              <b-col
                xl="3"
                lg="6"
                md="6"
                sm="12"
                xs="12"
                class="mb-lg-3 mb-md-3 mb-sm-3 mb-3 d-flex"
              >
                <b-card class="cardStyle w-full">
                  <b-col cols="10">
                    <div class="badge-l">
                      <h1 class="text-light my-1 p-2">
                        L <span class="package-word">Package</span>
                      </h1>
                    </div>
                  </b-col>

                  <b-col cols="12" class="text-center mt-3">
                    <div style="color: #652cdd">
                      <h1>
                        {{ $t('promotion').start }}
                      </h1>
                      <h1 class="monthly-price">1,898 /{{ this.$t('promotion').month }}</h1>
                    </div>
                    <div class="mx-3 my-1">
                      <p class="grey mb-2">{{ $t('promotion.l_duration') }}</p>
                      <hr style="border: 2px solid #652cdd" />
                    </div>
                    <div class="mx-3 my-3">
                      <p style="color: #652cdd">
                        {{ $t('promotion').next_year }}
                      </p>
                      <p class="grey">{{ $t('promotion').pay_system }}</p>
                      <!-- <p v-if="$i18n.locale ==='en'" class="grey">{{$t('promotion').pay_system_2}}</p> -->
                    </div>
                  </b-col>
                  <b-col cols="12" class="text-center">
                    <p class="px-2 cols-12 mt-3 mb-2">
                      {{ $t('promotion').amount_asset }}
                    </p>

                    <div class="border-l cols-12 d-flex justify-content-center mx-4 p-1">
                      <b-icon
                        icon="box"
                        style="color: #652cdd"
                        font-scale="1"
                        class="my-1 mx-2"
                      ></b-icon>
                      <p>{{ lLimit }}</p>
                    </div>
                  </b-col>
                  <b-col cols="12" class="mt-3 mx-3 text-left">
                    <div class="d-flex">
                      <b-icon
                        icon="check-circle"
                        style="color: #652cdd"
                        font-scale="1"
                        class="mt-1"
                      ></b-icon>
                      <p class="px-2">{{ $t('promotion').unlimit_user }}</p>
                    </div>
                    <!-- <div class="d-flex">
                      <b-icon
                        icon="check-circle"
                        style="color: #652cdd"
                        font-scale="1"
                        class="mt-1"
                      ></b-icon>
                      <p class="px-2">
                        {{ $t('promotion').free_stricker_300 }}
                      </p>
                    </div>
                    <div class="d-flex">
                      <b-icon
                        icon="check-circle"
                        style="color: #10b7dc"
                        font-scale="1"
                        class="mt-1 invisible"
                      ></b-icon>
                      <p class="px-2">
                        {{ $t('promotion').date }}
                      </p>
                    </div> -->
                  </b-col>
                  <template #footer>
                    <button class="button-l p-2 w-full" @click="choosePackages(22780)">
                      <p>{{ $t('promotion').select }}</p>
                    </button>
                  </template>
                </b-card>
              </b-col>
            </b-row>
          </b-col>
          <b-col></b-col>
        </b-row>
        <b-row class="text-center mt-5" align-h="center">
          <b-col cols="10" align-h="center">
            <b-row class="text-center mt-5 mb-4" align-self="center" align-v="center">
              <span class="flex justify-center items-center w-full">
                <img width="40" class="mr-2" :src="require(`@/assets/material/pc.svg`)" alt=""/>
                <h1 class="head-sec font-bold p-0">
                  {{ $t('promotion').scale }}
                </h1>
              </span>
            </b-row>
            <vue-slider
              absorb
              :data="packages"
              v-model="prices"
              class="pointer my-5"
              tooltip="always"
              ref="slider"
              id="slider"
              hide-label
            />
            <Button
              :text="$t('promotion').get_price"
              btnColor="blue-gradient-btn"
              @click.native="choosePackages(prices)"
              class="my-4 px-5"
            />
          </b-col>
        </b-row>
      </b-container>
    </b-row>
  </b-container>
</template>

<script>
import { mapMutations } from 'vuex'
import Button from '../general/general_button.vue'

export default {
  data() {
    return {
      prices: 0,
      packages: {
        0: 'Free trial',
        8980: '200 Assets',
        11480: '700 Assets',
        13980: '1,200 Assets',
        16480: '1,700 Assets',
        17880: '2,200 Assets',
        20130: '2,700 Assets',
        22380: '3,200 Assets',
        22780: '3,700 Assets',
        24780: '4,200 Assets',
        26780: '4,700 Assets',
        27980: '5,000 Assets',
        Custom: '5,000+ Assets',
      },
    }
  },
  mounted() {
    // this.$nextTick(() => {
    //   if (window.location.pathname.includes('home/package')) {
    //     const element = document.getElementById('header')
    //     window.scrollTo(200, element.offsetTop, { behavior: 'smooth' })
    //   }
    // })
  },
  components: {
    Button,
  },
  methods: {
    ...mapMutations({
      selectPackage: 'selected_packageMutations',
    }),

    choosePackages(price) {
      this.selectPackage(price)
      this.$router.push('/packages')
    },
  },
  computed: {
    sPackage() {
      return `${this.$t('promotion').start} 1,242 /${this.$t('promotion').month} `
    },
    mPackage() {
      return `${this.$t('promotion').start} 1,983 /${this.$t('promotion').month} `
    },
    lPackage() {
      return `${this.$t('promotion').start} 2,392 /${this.$t('promotion').month} `
    },
    sLimit() {
      return `200 - 1,700 ${this.$t('promotion').piece} `
    },
    mLimit() {
      return `1,701 - 3,200 ${this.$t('promotion').piece} `
    },
    lLimit() {
      return `3,201 - 5,000 ${this.$t('promotion').piece} `
    },
    isEn() {
      let isen = false
      this.$i18n.locale === 'en' ? (isen = true) : (isen = false)
      return isen
    },
  },
}
</script>

<style scoped>
.card-footer {
  border-top: 0px solid rgba(0, 0, 0, 0.125);
  background-color: rgba(0, 0, 0, 0);
}
.scale {
  color: #015fc3;
  font-size: 16px;
}
.grey {
  color: #777;
  font-size: 12px;
}

.package-name {
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.package-word {
  font-size: 16px;
}
.head-sec {
  color: #0158b6;
  font-size: 36px;
  line-height: inherit;
}

.monthly-price {
  font-size: 22px;
  font-weight: 600;
}

.button-free {
  background: linear-gradient(270deg, #fa858b 0.12%, #ee5f5f 96.54%);
  border-radius: 8px;
  color: white;
  margin-bottom: 5%;
}

.button-s {
  margin-top: 20px;
  margin-bottom: 5%;
  background: linear-gradient(270deg, #56d1f8 0%, #00bee8 100%);
  border-radius: 8px;
  color: white;
}
.border-s {
  border: 1px solid #0ac0ea;
  box-sizing: border-box;
  border-radius: 7px;
}

.border-m {
  border: 1px solid #266cee;
  box-sizing: border-box;
  border-radius: 7px;
}
.button-m {
  margin-top: 20px;
  margin-bottom: 5%;
  background: linear-gradient(90deg, #2367ed 0%, #63b5ff 106.01%);
  border-radius: 8px;
  color: white;
}

.button-l {
  margin-top: 20px;
  margin-bottom: 5%;
  background: linear-gradient(90deg, #652cdd 5.38%, #c391ef 100%);
  border-radius: 8px;
  color: white;
}
.border-l {
  border: 1px solid #6c34de;
  box-sizing: border-box;
  border-radius: 7px;
}
.badge-free {
  background: linear-gradient(269.79deg, #fc8c92 0.23%, #ed5d5d 99.89%);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0px 10px 10px 10px;
  color: white;
  font-size: 21px !important;
  position: relative;
  top: -5px;
  left: -15%;
}
.badge-s {
  background: linear-gradient(270deg, #56d1f8 0%, #00bee8 100%);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0px 10px 10px 10px;
  color: white;
  font-size: 24px !important;
  position: relative;
  top: -5px;
  left: -15%;
}
.badge-m {
  background: linear-gradient(90deg, #2367ed 0%, #63b5ff 106.01%);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0px 10px 10px 10px;
  color: white;
  font-size: 24px !important;
  position: relative;
  top: -5px;
  left: -15%;
}
.badge-l {
  background: linear-gradient(90deg, #652cdd 5.38%, #c391ef 100%);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0px 10px 10px 10px;
  color: white;
  font-size: 24px !important;
  position: relative;
  top: -5px;
  left: -15%;
}
@media only screen and (min-width: 1280px) and (max-width: 1535px) {
  .card-body {
    flex: 1 1 auto;
    padding: 1rem 1rem;
  }
  .button-free-thai {
    background: linear-gradient(270deg, #fa858b 0.12%, #ee5f5f 96.54%);
    border-radius: 8px;
    color: white;
  }
  .button-free {
    background: linear-gradient(270deg, #fa858b 0.12%, #ee5f5f 96.54%);
    border-radius: 8px;
    color: white;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1279px) {
  .button-free {
    background: linear-gradient(270deg, #fa858b 0.12%, #ee5f5f 96.54%);
    border-radius: 8px;
    color: white;
  }
  /* .button-free-thai {
  } */
  .card-body {
    flex: 1 1 auto;
    padding: 1rem 1rem;
    height: 626px;
  }
  .badge-free {
    background: linear-gradient(269.79deg, #fc8c92 0.23%, #ed5d5d 99.89%);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 0px 10px 10px 10px;
    color: white;
    font-size: 21px !important;
    position: relative;
    top: -5px;
    left: -20%;
  }
  .badge-s {
    background: linear-gradient(270deg, #56d1f8 0%, #00bee8 100%);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 0px 10px 10px 10px;
    color: white;
    font-size: 24px !important;
    position: relative;
    top: -5px;
    left: -20%;
  }
  .badge-m {
    background: linear-gradient(90deg, #2367ed 0%, #63b5ff 106.01%);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 0px 10px 10px 10px;
    color: white;
    font-size: 24px !important;
    position: relative;
    top: -5px;
    left: -20%;
  }
  .badge-l {
    background: linear-gradient(90deg, #652cdd 5.38%, #c391ef 100%);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 0px 10px 10px 10px;
    color: white;
    font-size: 24px !important;
    position: relative;
    top: -5px;
    left: -20%;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1199px) {
  .button-free {
    background: linear-gradient(270deg, #fa858b 0.12%, #ee5f5f 96.54%);
    border-radius: 8px;
    color: white;
  }
  .badge-free {
    background: linear-gradient(269.79deg, #fc8c92 0.23%, #ed5d5d 99.89%);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 0px 10px 10px 10px;
    color: white;
    font-size: 21px !important;
    position: relative;
    top: -5px;
    left: -10%;
  }
  .badge-s {
    background: linear-gradient(270deg, #56d1f8 0%, #00bee8 100%);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 0px 10px 10px 10px;
    color: white;
    font-size: 24px !important;
    position: relative;
    top: -5px;
    left: -10%;
  }
  .badge-m {
    background: linear-gradient(90deg, #2367ed 0%, #63b5ff 106.01%);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 0px 10px 10px 10px;
    color: white;
    font-size: 24px !important;
    position: relative;
    top: -5px;
    left: -10%;
  }

  .badge-l {
    background: linear-gradient(90deg, #652cdd 5.38%, #c391ef 100%);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 0px 10px 10px 10px;
    color: white;
    font-size: 24px !important;
    position: relative;
    top: -5px;
    left: -10%;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1023px) {
  .badge-free {
    background: linear-gradient(269.79deg, #fc8c92 0.23%, #ed5d5d 99.89%);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 0px 10px 10px 10px;
    color: white;
    font-size: 21px !important;
    position: relative;
    top: -5px;
    left: -13%;
  }
  .badge-s {
    background: linear-gradient(270deg, #56d1f8 0%, #00bee8 100%);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 0px 10px 10px 10px;
    color: white;
    font-size: 24px !important;
    position: relative;
    top: -5px;
    left: -13%;
  }
  .badge-m {
    background: linear-gradient(90deg, #2367ed 0%, #63b5ff 106.01%);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 0px 10px 10px 10px;
    color: white;
    font-size: 24px !important;
    position: relative;
    top: -5px;
    left: -13%;
  }

  .badge-l {
    background: linear-gradient(90deg, #652cdd 5.38%, #c391ef 100%);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 0px 10px 10px 10px;
    color: white;
    font-size: 24px !important;
    position: relative;
    top: -5px;
    left: -13%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .badge-free {
    background: linear-gradient(269.79deg, #fc8c92 0.23%, #ed5d5d 99.89%);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 0px 10px 10px 10px;
    color: white;
    font-size: 21px !important;
    position: relative;
    top: -5px;
    left: -13%;
  }
  .badge-s {
    background: linear-gradient(270deg, #56d1f8 0%, #00bee8 100%);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 0px 10px 10px 10px;
    color: white;
    font-size: 24px !important;
    position: relative;
    top: -5px;
    left: -13%;
  }
  .badge-m {
    background: linear-gradient(90deg, #2367ed 0%, #63b5ff 106.01%);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 0px 10px 10px 10px;
    color: white;
    font-size: 24px !important;
    position: relative;
    top: -5px;
    left: -13%;
  }

  .badge-l {
    background: linear-gradient(90deg, #652cdd 5.38%, #c391ef 100%);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 0px 10px 10px 10px;
    color: white;
    font-size: 24px !important;
    position: relative;
    top: -5px;
    left: -13%;
  }
}

@media only screen and (min-width: 425px) and (max-width: 767px) {
  .button-free {
    margin-bottom: 5%;
  }
  .badge-free {
    background: linear-gradient(269.79deg, #fc8c92 0.23%, #ed5d5d 99.89%);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 0px 10px 10px 10px;
    color: white;
    font-size: 21px !important;
    position: relative;
    top: -5px;
    left: -11%;
  }
  .badge-s {
    background: linear-gradient(270deg, #56d1f8 0%, #00bee8 100%);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 0px 10px 10px 10px;
    color: white;
    font-size: 24px !important;
    position: relative;
    top: -5px;
    left: -11%;
  }
  .badge-m {
    background: linear-gradient(90deg, #2367ed 0%, #63b5ff 106.01%);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 0px 10px 10px 10px;
    color: white;
    font-size: 24px !important;
    position: relative;
    top: -5px;
    left: -11%;
  }

  .badge-l {
    background: linear-gradient(90deg, #652cdd 5.38%, #c391ef 100%);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 0px 10px 10px 10px;
    color: white;
    font-size: 24px !important;
    position: relative;
    top: -5px;
    left: -11%;
  }
}
@media only screen and (min-width: 320px) and (max-width: 424px) {
  .button-free {
    margin-bottom: 5%;
  }
  .badge-free {
    background: linear-gradient(269.79deg, #fc8c92 0.23%, #ed5d5d 99.89%);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 0px 10px 10px 10px;
    color: white;
    font-size: 21px !important;
    position: relative;
    top: 0px;
    left: -13%;
  }
  .badge-s {
    background: linear-gradient(270deg, #56d1f8 0%, #00bee8 100%);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 0px 10px 10px 10px;
    color: white;
    font-size: 24px !important;
    position: relative;
    top: 0px;
    left: -13%;
  }
  .badge-m {
    background: linear-gradient(90deg, #2367ed 0%, #63b5ff 106.01%);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 0px 10px 10px 10px;
    color: white;
    font-size: 24px !important;
    position: relative;
    top: 0px;
    left: -13%;
  }

  .badge-l {
    background: linear-gradient(90deg, #652cdd 5.38%, #c391ef 100%);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 0px 10px 10px 10px;
    color: white;
    font-size: 24px !important;
    position: relative;
    top: 0px;
    left: -13%;
  }
}
</style>
